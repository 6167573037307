import _ from "lodash";
/**
 * Sanitizes an object's string values.
 * Will also sanitize nested object strings.
 * Doesn't mutate the object.
 * @param {object} obj Object to sanitize.
 * @returns {object} New, sanitized object.
 */
export const sanitizeObjectStrings = (obj) => {
  const objClone = _.cloneDeep(obj);
  sanitizeStringsRecursively(objClone);
  return objClone;
};

const sanitizeStringsRecursively = (obj) => {
  const nested = [];

  Object.entries(obj).forEach((entry) => {
    const [key, value] = entry;
    if (typeof value === "string") assignSanitizedString(obj, key, value);
    if (typeof value === "object") nested.push(value);
  });

  nested.forEach((nestedObj) => sanitizeStringsRecursively(nestedObj));
};

const assignSanitizedString = (obj, key, value) => {
  obj[key] = processString(value);
};

/**
 * Processes a string.
 * Add more processing operations into this function if needed.
 * @param {string} str
 * @returns {string} str
 */
const processString = (str) => {
  return str.trim();
};
