import "tippy.js/dist/tippy.css";
import { renderDate } from "../../formatting/dateFormat";

export const masterColumnSet = [
  {
    name: "product.title",
    label:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "left-end" trigger = "click"><p>Title</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.title",
    title:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Title</p></Tooltip>',
  },
  {
    name: "product.upc",
    label:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "top-start" trigger = "click"><p>UPC</p></Tooltip>',
    data: "product.upc",
    title:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "bottom" trigger = "click"><p>UPC</p></Tooltip>',
  },
  {
    name: "product.asin",
    label:
      '<Tooltip title = "ASIN (Amazon Standard Identification Number)" position = "bottom" trigger = "click"><p>ASIN</p></Tooltip>',
    data: "product.asin",
    title:
      '<Tooltip title = "ASIN (Amazon Standard Identification Number)" position = "bottom" trigger = "click"><p>ASIN</p></Tooltip>',
  },
  {
    name: "product.cogs",
    label:
      '<Tooltip title = "COGS (Cost of Goods Sold) Price Your Online Marketplace paid for our goods, inputed by a analyst" position = "bottom" trigger = "click"><p>COGS</p></Tooltip>',
    data: "product.cogs",
    title:
      '<Tooltip title = "COGS (Cost of Goods Sold) Price Your Online Marketplace paid for our goods, inputed by a analyst" position = "bottom" trigger = "click"><p>COGS</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
  },
  {
    data: "product.cogs_update_timestamp",
    name: '<Tooltip title = "Shows the timestamp of the last time COGS was updated for a row." position = "bottom" trigger = "click"><p>COGS Timestamp</p></Tooltip>',
  },
  {
    name: "product.type",
    label:
      '<Tooltip title = "Representation if the product was repriced or prospected using our Amazon pricing calculator" position = "bottom" trigger = "click"><p>Type</p></Tooltip>',
    data: "product.type",
    title:
      '<Tooltip title = "Representation if the product was repriced or prospected using our Amazon pricing calculator" position = "bottom" trigger = "click"><p>Type</p></Tooltip>',
  },
  {
    name: "product.margin",
    label:
      '<Tooltip title = "Profit margin percentage" position = "bottom" trigger = "click"><p>Margin</p></Tooltip>',
    data: "product.margin",
    title:
      '<Tooltip title = "Profit margin percentage" position = "bottom" trigger = "click"><p>Margin</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `${data.toFixed(1)}%`;
      } else {
        return data;
      }
    },
  },
  {
    name: "product.profit",
    label:
      '<Tooltip title = "Profit that we get if we were to sell the product (Selling Price - COGS - Amazon fees - Your Online Marketplace Warehouse & Handling fees)" position = "bottom" trigger = "click"><p>Profit</p></Tooltip>',
    data: "product.profit",
    title:
      '<Tooltip title = "Profit that we get if we were to sell the product (Selling Price - COGS - Amazon fees - Your Online Marketplace Warehouse & Handling fees)" position = "bottom" trigger = "click"><p>Profit</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
  },
  {
    name: "product.proceeds",
    label:
      '<Tooltip title = "Selling Price - Amazon fees directly attributable to the sale and fulfillment of an FBA order (inc. referral, fulfillment and closing fees)" position = "bottom" trigger = "click"><p>Proceeds</p></Tooltip>',
    data: "product.proceeds",
    title:
      '<Tooltip title = "Selling Price - Amazon fees directly attributable to the sale and fulfillment of an FBA order (inc. referral, fulfillment and closing fees)" position = "bottom" trigger = "click"><p>Proceeds</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
  },
  {
    name: "product.roi",
    label:
      '<Tooltip title = "ROI (Return on Investment) The gain or loss on an investment relative to the amount of money invested (COGS = Warehousing Fee)" position = "bottom" trigger = "click"><p>ROI</p></Tooltip>',
    data: "product.roi",
    title:
      '<Tooltip title = "ROI (Return on Investment) The gain or loss on an investment relative to the amount of money invested (COGS = Warehousing Fee)" position = "bottom" trigger = "click"><p>ROI</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `${data.toFixed(1)}%`;
      } else {
        return data;
      }
    },
  },
  {
    name: "product.margin_0_pricing",
    label:
      '<Tooltip title = "Selling Price required to achieve a 0% margin" position = "bottom" trigger = "click"><p>List 0</p></Tooltip>',
    data: "product.margin_0_pricing",
    title:
      '<Tooltip title = "Selling Price required to achieve a 0% margin" position = "bottom" trigger = "click"><p>List 0</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
  },
  {
    name: "product.margin_15_pricing",
    label:
      '<Tooltip title = "Selling Price required to achieve a 15% margin" position = "bottom" trigger = "click"><p>List 15</p></Tooltip>',
    data: "product.margin_15_pricing",
    title:
      '<Tooltip title = "Selling Price required to achieve a 15% margin" position = "bottom" trigger = "click"><p>List 15</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
  },
  {
    name: "product.margin_30_pricing",
    label:
      '<Tooltip title = "Selling Price required to achieve a 30% margin" position = "bottom" trigger = "click"><p>List 30</p></Tooltip>',
    data: "product.margin_30_pricing",
    title:
      '<Tooltip title = "Selling Price required to achieve a 30% margin" position = "bottom" trigger = "click"><p>List 30</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
  },
  {
    name: "product.latest_timestamp",
    label:
      '<Tooltip title = "When a product was last prospected or priced" position = "bottom" trigger = "click"><p>Calculator TimeStamp</p></Tooltip>',
    data: "product.latest_timestamp",
    title:
      '<Tooltip title = "When a product was last prospected or priced" position = "bottom" trigger = "click"><p>Calculator TimeStamp</p></Tooltip>',
    render: renderDate,
  },
];
