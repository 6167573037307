import "tippy.js/dist/tippy.css";
import { renderDate } from "../../formatting/dateFormat";

export const masterColumnSet = [
  {
    label:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "bottom" trigger = "click"><p>UPC</p></Tooltip>',
    name: "upc",
    data: "upc",
    title:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "bottom" trigger = "click"><p>UPC</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "YOM’s internal Order Number" position = "bottom" trigger = "click"><p>YOM Order Number</p></Tooltip>',
    name: "order_number",
    data: "order_number",
    title:
      '<Tooltip title = "YOM’s internal Order Number" position = "bottom" trigger = "click"><p>YOM Order Number</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Title</p></Tooltip>',
    name: "title",
    attr: {
      disabled: true,
    },
    data: "title",
    title:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Title</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Price" position = "bottom" trigger = "click"><p>Price</p></Tooltip>',
    name: "price",
    data: "price",
    title:
      '<Tooltip title = "Price" position = "bottom" trigger = "click"><p>Price</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "‘Open’ Quantity (yet to be received)" position = "bottom" trigger = "click"><p>Qty</p></Tooltip>',
    name: "qty",
    data: "qty",
    title:
      '<Tooltip title = "‘Open’ Quantity (yet to be received)" position = "bottom" trigger = "click"><p>Qty</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Total Quantity Ordered" position = "bottom" trigger = "click"><p>OrdQty</p></Tooltip>',
    name: "ordqty",
    data: "ordqty",
    title:
      '<Tooltip title = "Total Quantity Ordered" position = "bottom" trigger = "click"><p>OrdQty</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Supplier" position = "bottom" trigger = "click"><p>Supplier</p></Tooltip>',
    name: "supplier",
    data: "supplier",
    title:
      '<Tooltip title = "Supplier" position = "bottom" trigger = "click"><p>Supplier</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Supplier Order Number" position = "bottom" trigger = "click"><p>Supplier Order Number</p></Tooltip>',
    name: "supplier_order_number",
    data: "supplier_order_number",
    title:
      '<Tooltip title = "Supplier Order Number" position = "bottom" trigger = "click"><p>Supplier Order Number</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Notes" position = "bottom" trigger = "click"><p>Notes</p></Tooltip>',
    name: "notes",
    data: "notes",
    title:
      '<Tooltip title = "Notes" position = "bottom" trigger = "click"><p>Notes</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "OrderBy" position = "bottom" trigger = "click"><p>OrderBy</p></Tooltip>',
    name: "orderby",
    data: "orderby",
    title:
      '<Tooltip title = "OrderBy" position = "bottom" trigger = "click"><p>OrderBy</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "ActiveTracking" position = "bottom" trigger = "click"><p>ActiveTracking</p></Tooltip>',
    name: "activetracking",
    data: "activetracking",
    title:
      '<Tooltip title = "ActiveTracking" position = "bottom" trigger = "click"><p>ActiveTracking</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "GottenTracking" position = "bottom" trigger = "click"><p>GottenTracking</p></Tooltip>',
    name: "gottentracking",
    data: "gottentracking",
    title:
      '<Tooltip title = "GottenTracking" position = "bottom" trigger = "click"><p>GottenTracking</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "AddDate" position = "bottom" trigger = "click"><p>AddDate</p></Tooltip>',
    name: "adddate",
    attr: {
      disabled: true,
    },
    data: "adddate",
    title:
      '<Tooltip title = "AddDate" position = "bottom" trigger = "click"><p>AddDate</p></Tooltip>',
    render: renderDate,
  },
  {
    label:
      '<Tooltip title = "Received" position = "bottom" trigger = "click"><p>Received</p></Tooltip>',
    name: "received",
    data: "received",
    title:
      '<Tooltip title = "Received" position = "bottom" trigger = "click"><p>Received</p></Tooltip>',
    render: renderDate,
  },
];
