import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { sanitizeObjectStrings } from "../../../utilities/sanitizeObjectStrings";
import * as Yup from "yup";
import { toStandardCase } from "../../../utilities/caseConverters";
import { Form, Button, Dropdown } from "semantic-ui-react";

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

const RetailSupplierForm = ({ handleSubmit, bulkAdd, formDisabled }) => {
  const [displaySupplierTextInput, setDisplaySupplierTextInput] =
    useState(false);

  const validationSchema = Yup.object({
    UPC: Yup.string().required("Please enter a UPC."),
    supplier: Yup.string().required("Please select a supplier."),
    supplierOther: Yup.string().when("supplier", {
      is: "Other",
      then: Yup.string().required("Please enter a supplier."),
      otherwise: Yup.string(),
    }),
    quantity: Yup.number()
      .moreThan(-1, "Cannot be negative value.")
      .required("Please enter a quantity."),
    damageQuantity: Yup.number()
      .moreThan(-1, "Cannot be negative value.")
      .required("Please enter a damage quantity."),
  });

  const dropdownOptions = [
    {
      key: "Walmart",
      text: "Walmart",
      value: "Walmart",
    },
    {
      key: "Best Buy",
      text: "Best Buy",
      value: "Best Buy",
    },
    {
      key: "Target",
      text: "Target",
      value: "Target",
    },
    {
      key: "Amazon",
      text: "Amazon",
      value: "Amazon",
    },
    {
      key: "Other",
      text: "Other",
      value: "Other",
    },
  ];

  const handleSupplierValue = (values) => {
    if (values.supplier === "Other") {
      values.supplier = toStandardCase(values.supplierOther);
    }

    return values;
  };

  return (
    <Formik
      initialValues={{
        UPC: "",
        supplier: "",
        quantity: "",
        damageQuantity: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        values = sanitizeObjectStrings(values);
        values = handleSupplierValue(values);

        await handleSubmit(values, actions);
        actions.setFieldValue("quantity", "", false);
        actions.setFieldValue("damageQuantity", "", false);
      }}
    >
      {(formikProps) => (
        <Form widths="equal" onSubmit={formikProps.handleSubmit}>
          {/* Inputs */}
          <p>
            A scan may contain exclusively damaged or good quantity and still
            succeed
          </p>

          <Form.Group>
            <Form.Field>
              <ErrorMessage name="UPC" component={RedErrorText} />
              <label className="ui label">UPC</label>
              <input
                name="UPC"
                onChange={formikProps.handleChange}
                value={formikProps.values["UPC"]}
                disabled={formDisabled}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="supplier" component={RedErrorText} />
              <label className="ui label">Supplier</label>
              <Dropdown
                disabled={formDisabled}
                name="supplier"
                placeholder="Supplier"
                onChange={(e, data) => {
                  // displaying or hiding text input as needed
                  if (data.value === "Other") setDisplaySupplierTextInput(true);
                  else setDisplaySupplierTextInput(false);

                  formikProps.setFieldTouched("supplier", true, false);
                  formikProps.setFieldValue("supplier", data.value);
                }}
                value={formikProps.values["supplier"]}
                fluid
                selection
                options={dropdownOptions}
              />

              {/* Text Input for other suppliers */}
              <ErrorMessage name="supplierOther" component={RedErrorText} />

              {displaySupplierTextInput && (
                <input
                  style={{ marginTop: "8px" }}
                  name="supplierOther"
                  autoFocus={true}
                  placeholder="Enter other supplier"
                  onChange={formikProps.handleChange}
                  value={formikProps.values["supplierOther"]}
                  disabled={formDisabled}
                />
              )}
              {/* End Text Input for other suppliers */}
            </Form.Field>

            <Form.Field>
              <ErrorMessage name="quantity" component={RedErrorText} />
              <label className="ui label">Good Quantity</label>
              <input
                name="quantity"
                title="Inventory that has passed quality control and is saleable."
                onChange={formikProps.handleChange}
                value={formikProps.values["quantity"]}
                disabled={formDisabled}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="damageQuantity" component={RedErrorText} />
              <label className="ui label">Damage Quantity</label>
              <input
                type="number"
                title="Inventory that did not pass quality control, and must be returned or repaired."
                name="damageQuantity"
                onChange={formikProps.handleChange}
                value={formikProps.values["damageQuantity"]}
                disabled={formDisabled}
              />
            </Form.Field>
          </Form.Group>
          {/* End Inputs */}

          {/* Buttons */}
          <Form.Group inline>
            <Button type="submit" inverted color="blue" disabled={formDisabled}>
              Push to Queue
            </Button>
            <Button
              disabled={formDisabled}
              type="button"
              onClick={() => {
                formikProps.validateForm().then(async (errors) => {
                  let supplier = formikProps.values["supplier"];
                  let usingOtherSupplier = false;

                  // Wiping error object for supplier
                  // depending on which version we are validating
                  if (supplier === "Other") {
                    usingOtherSupplier = true;
                    formikProps.setFieldTouched("supplierOther");
                    errors["supplier"] = null;
                  } else {
                    formikProps.setFieldTouched("supplier");
                    errors["supplierOther"] = null;
                  }

                  // Dealing with generic validation logic
                  formikProps.setFieldTouched("quantity");
                  formikProps.setFieldTouched("damageQuantity");
                  formikProps.setFieldTouched("UPC", false);

                  // If we fail any required validation
                  if (
                    errors["quantity"] ||
                    errors["damageQuantity"] ||
                    errors["supplier"] ||
                    errors["supplierOther"]
                  )
                    return;

                  let values = sanitizeObjectStrings(formikProps.values);

                  if (usingOtherSupplier)
                    values.supplier = toStandardCase(values.supplierOther);

                  await bulkAdd(values);
                });
              }}
            >
              Bulk Push
            </Button>
          </Form.Group>
          {/* End Buttons */}
        </Form>
      )}
    </Formik>
  );
};

export default RetailSupplierForm;
