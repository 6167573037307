export const attachDuplicateCheckbox = (editor, datatable) => {
  const duplicateFamilyField = {
    dataProp: "duplicate_families",
    label: "",
    name: "duplicate_families",
    type: "checkbox",
    ipOpts: [
      {
        className: ".dt-align-vertical",
        label: "Duplicate family relationships",
        value: 1,
      },
    ],
    def: 1,
    unselectedValue: 0,
    separator: ",",
  };

  editor.one("closed", () => {
    // Removes the custom fields when the form closes
    editor.clear(["duplicate_families", "id_to_duplicate"]);
  });

  editor.add(duplicateFamilyField, null).add({
    type: "hidden",
    name: "id_to_duplicate",
    def: datatable.row({ selected: true }).data().product.yom_sku,
  });
};
