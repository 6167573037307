import React, { useEffect, useState } from "react";
import {
  Grid,
  Header,
  Menu,
  Modal,
  Popup,
  Button,
  Icon,
  Segment,
  Dimmer,
  Loader,
  Input,
} from "semantic-ui-react";
import axios from "../../../../../../apis/dvdlocator";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../../../redux/actions/notificationActions";

const FamilyPanel = ({
  family,
  open,
  close,
  updateFamilyName,
  deleteFamilyFromState,
}) => {
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);
  const [editingName, setEditingName] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [familyName, setFamilyName] = useState(family.familyName);
  const [submitting, setSubmitting] = useState(false);
  const [editError, setEditError] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getMembers = async () => {
      try {
        const res = await axios.get(`/families/${family.familyId}/members`);

        setMembers(res.data.members);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    getMembers();
  }, [family]);

  const editFamilyName = async () => {
    setSubmitting(true);
    try {
      await axios.put(`/families/${family.familyId}`, { name: familyName });

      updateFamilyName(family.familyId, familyName);

      dispatch(
        addNotification({
          text: "Saved new family name!",
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    } catch (err) {
      if (err.response) {
        if (err.response.status === 409) {
          return setEditError("This family name already exists.");
        }
      }
      dispatch(
        addNotification({
          text: `Error saving family name.`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setSubmitting(false);
      setEditingName(false);
    }
  };

  const deleteFamily = async () => {
    setSubmitting(true);

    try {
      await axios.delete(`/families/${family.familyId}`);

      deleteFamilyFromState(family.familyId);

      dispatch(
        addNotification({
          text: "Deleted family!",
          icon: <Icon color="green" name="checkmark box" />,
        })
      );

      close();
    } catch (err) {
      console.log(err);

      dispatch(
        addNotification({
          text: `Error deleting family.`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setSubmitting(false);
      setDeleting(false);
    }
  };

  return (
    <Modal open={open} onClose={close}>
      {deleting && (
        <Modal open={deleting} size="tiny">
          <Modal.Header>Delete Family</Modal.Header>

          <Modal.Content>
            <h3 style={{ color: "red" }}>
              Are you sure you want to delete this family?
            </h3>
            <b>{`(Only removes family associations - doesn't delete product/listing rows)`}</b>
            <p style={{ marginTop: "16px" }}>Family: {family.familyName}</p>
            <p>Members: {members.length}</p>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={() => setDeleting(false)}>Cancel</Button>
              <Button
                disabled={submitting}
                color="red"
                onClick={() => {
                  deleteFamily(family.familyId);
                }}
              >
                Delete
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      )}
      <Modal.Header>
        <Grid columns={2}>
          <Grid.Column floated="left" verticalAlign="middle">
            {editingName ? (
              <>
                {editError && (
                  <span style={{ color: "red", marginBottom: "8px" }}>
                    {editError}
                  </span>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <Input
                    size="mini"
                    focus
                    disabled={submitting}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") editFamilyName();
                    }}
                    onChange={(e) => setFamilyName(e.target.value)}
                    value={familyName}
                  />
                  <Icon
                    name="check"
                    color="green"
                    disabled={submitting}
                    onClick={editFamilyName}
                    style={{ cursor: "pointer" }}
                    title="Save"
                  />
                  <Icon
                    name="times"
                    color="red"
                    disabled={submitting}
                    onClick={() => setEditingName(false)}
                    style={{ cursor: "pointer" }}
                    title="Cancel"
                  />
                </div>
              </>
            ) : (
              <Header floated="left">{family.familyName}</Header>
            )}
          </Grid.Column>
          <Grid.Column floated="right">
            <Popup
              open={popupOpen}
              basic
              trigger={
                <Button
                  icon="ellipsis horizontal"
                  circular
                  floated="right"
                  onClick={() => setPopupOpen((prev) => !prev)}
                />
              }
              on="click"
            >
              <Menu vertical fluid>
                <Menu.Item
                  name="inbox"
                  onClick={() => {
                    setEditingName(true);
                    setPopupOpen(false);
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#dcdcdc")
                  } // Change to darker color on hover
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#f8f8f8")
                  } // Reset to original color on hover out
                  className="no-pointer"
                >
                  <Icon name="edit outline" />
                  Edit Name
                </Menu.Item>

                <Menu.Item
                  name="inbox"
                  onClick={() => {
                    setDeleting(true);
                    setPopupOpen(false);
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#dcdcdc")
                  } // Change to darker color on hover
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#f8f8f8")
                  } // Reset to original color on hover out
                  className="no-pointer"
                >
                  <Icon name="trash alternate outline" />
                  Delete Family
                </Menu.Item>
              </Menu>
            </Popup>
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        {loading ? (
          <Segment padded="very">
            <Dimmer active inverted>
              <Loader active inline="centered" content="Loading" />
            </Dimmer>
          </Segment>
        ) : (
          <Header.Subheader>{members.length} members</Header.Subheader>
        )}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button primary onClick={close}>
            Done
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default FamilyPanel;
