/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:b61e72ec-2a95-4dff-b7cb-d1e3a7bc93f7",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_PjydoC4EE",
    "aws_user_pools_web_client_id": "4iosv8gqk01i4ovvhq1obr0pap",
    "oauth": {}
};


export default awsmobile;
