import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

export default class JSignOut extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }
  
  async signOut() {
    try {
        await Auth.signOut();
        window.location.reload();
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  render() {
    return (
      <div onClick={this.signOut} className="ui vertical animated button">
        <div className="visible content">Sign Out</div>
        <div className="hidden content">
          <i className="sign out alternate icon"></i>
        </div>
      </div>
    );
  }
}