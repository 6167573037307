import BigNumber from "bignumber.js";

export function formatToPoundsOunces(data) {
  if (!data) return null;

  const ounces = new BigNumber(data);
  const pounds = ounces.dividedToIntegerBy(16);
  const remainingOunces = ounces.modulo(16).dp(1, BigNumber.ROUND_HALF_UP);

  if (pounds.isZero()) {
    return `${remainingOunces.toString()}oz`;
  } else if (remainingOunces.isZero()) {
    return `${pounds.toString()}lbs`;
  } else {
    return `${pounds.toString()}lbs ${remainingOunces.toString()}oz`;
  }
}
