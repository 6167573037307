// https://github.com/atlassian/react-beautiful-dnd
import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Icon } from "semantic-ui-react";

class ColumnReorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  // keep state synced with parent state via props.items
  static getDerivedStateFromProps(props, state) {
    return { items: props.items };
  }

  // a little function to help us with reordering the result
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    // update parent state
    this.props.reorderItems(items);
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={this.props.getListStyle(
                snapshot.isDraggingOver,
                this.state.items.length
              )}
            >
              {this.state.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...this.props.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        ),
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                      <Icon
                        name="cancel"
                        color="red"
                        style={{ cursor: "pointer" }}
                        title="Remove from column set"
                        onClick={() => this.props.removeColumn(item.id)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default ColumnReorder;
