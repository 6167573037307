import React from "react";

import { masterColumnSet } from "./apColumns";

import DataTablesEditor from "../DatatablesEditor/DataTablesEditor";

import { baseURL } from "../../../apis/dvdlocator";

class AmazonPricing extends React.Component {
  constructor(props) {
    super(props);
    this.searchDelay = null;
    this.state = {
      searchValue: this.props.match.params.search
        ? this.props.match.params.search
        : "",
      columns: masterColumnSet,
      colSets: [],

      fields: masterColumnSet,

      options: {
        dom: "Blrtip", // https://datatables.net/reference/option/dom
        processing: true,
        serverSide: true,
        select: true,
        paging: true,
        paginate: true,
        pageLength: 10,
        lengthChange: true,
        scrollX: true,
        scrollY: window.innerHeight - 200,
        scrollCollapse: true,
        autoWidth: true,

        // 	stateSave: true,
        colReorder: true,
        deferRender: true,
        order: [[1, "asc"]],
      },
    };

    this.dataTablesRef = React.createRef();
  }

  componentDidMount() {
    // run initial search if one given
    if (this.state.searchValue) {
      this.dataTablesRef.current.search(this.state.searchValue);
    }
  }

  // search field onChange event handler
  onChangeSearch = (e) => {
    const { value } = e.target;
    const searchValue = value;
    this.setState({ searchValue: searchValue });
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
      this.dataTablesRef.current.search("");
    }, 1000);
  };

  formatCurrency = (data) => {
    if (data !== null) {
      return `$${(data / 100).toFixed(2)}`;
    } else {
      return data;
    }
  };

  formatPercent = (data) => {
    if (data !== null) {
      return `${data.toFixed(1)}%`;
    } else {
      return data;
    }
  };

  sortArray = (list) => {
    list.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.added_timestamp) - new Date(a.added_timestamp);
    });
  };

  customButtons = () => {
    return [
      {
        text: "<span title='Refresh Table'>Refresh</span>",
        action: (e, dt, node, config) => {
          dt.ajax.reload();
        },
      },
    ];
  };

  render() {
    const { fields, columns, options, searchValue } = this.state;

    return (
      <div>
        <div className="ui input">
          <input
            value={searchValue}
            onChange={this.onChangeSearch}
            autoComplete={"off"}
            type="text"
            placeholder="Search ..."
          />
        </div>
        <DataTablesEditor
          key={this.state.render}
          ref={this.dataTablesRef}
          ajax={{
            url: `${baseURL}/datatables/amazon-pricing`,
            contentType: "application/json",
            type: "POST",
            data: (d) => {
              d.smartSearch = this.state.searchValue;
              return JSON.stringify(d);
            },
          }}
          editorAjax={{
            url: `${baseURL}/datatables/amazon-pricing`,
            contentType: "application/json",
            type: "POST",
            data: function (d) {
              return JSON.stringify(d);
            },
          }}
          columns={columns}
          options={options}
          fields={fields}
          createButton={false}
          editButton={true}
          removeButton={true}
          colVis={false}
          customButtons={this.customButtons}
        />
      </div>
    );
  }
}

export default AmazonPricing;
