import React from 'react';
import ReactDOM from 'react-dom';

import { Modal } from 'semantic-ui-react';

// use a portal to place something in a new element with classname: #modal
class ReactModal extends React.Component {
  state = { open: this.props.isActive };
  
  render() {
    return ReactDOM.createPortal(
      <Modal
        onClose={() => this.props.setOpen(false)}
        onOpen={() => this.props.setOpen(true)}
        open={this.props.isActive}
        style={{textAlign: "center"}}
      >
        {this.props.title ? <Modal.Header>{this.props.title}</Modal.Header> : ""}
        <Modal.Content image>
          {this.props.content}
        </Modal.Content>
        {this.props.actions ? <Modal.Actions>{this.props.actions}</Modal.Actions> : ""}
      </Modal>,
      document.querySelector('#modal')
    )
  }
}

export default ReactModal;