import React, { useEffect, useState } from "react";
import {
  Modal,
  Dropdown,
  Button,
  Form,
  Table,
  Segment,
  Container,
} from "semantic-ui-react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import dvdlocator from "../../../../../apis/dvdlocator";
import LoadingBox from "../../../../reports/LoadingBox";
import { renderDate } from "../../../../formatting/dateFormat";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

const ShelvingList = ({ closeModal }) => {
  const [displayState, setDisplayState] = useState("form");
  const [shelvingListResponse, setShelvingListResponse] = useState({
    scans: [],
  });

  switch (displayState) {
    case "form":
      return (
        <Modal
          defaultOpen
          style={{ minHeight: "300px", minWidth: "600px" }}
          onClose={closeModal}
        >
          <SelectUserForm
            closeModal={closeModal}
            setDisplayState={setDisplayState}
            setShelvingListResponse={setShelvingListResponse}
          />
        </Modal>
      );

    case "loading":
      return (
        <Modal defaultOpen style={{ minWidth: "600px" }} onClose={closeModal}>
          <LoadingBox />
        </Modal>
      );
    case "list":
      return (
        <Modal
          defaultOpen
          style={{ minHeight: "300px", minWidth: "600px" }}
          onClose={closeModal}
        >
          <ShelvingListDisplay
            closeModal={closeModal}
            shelvingListResponse={shelvingListResponse}
            setDisplayState={setDisplayState}
          />
        </Modal>
      );
    default:
      return (
        <Modal
          defaultOpen
          style={{ minHeight: "300px", minWidth: "600px" }}
          onClose={closeModal}
        >
          <h2>There was probably an error.</h2>
        </Modal>
      );
  }
};

const ShelvingListDisplay = ({ closeModal, shelvingListResponse }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const sortAlphaNum = (a, b) =>
    a.shelf.localeCompare(b.shelf, "en", { numeric: true });

  const sortScansByShelf = (scans) => {
    if (!scans) return [];
    return scans.sort(sortAlphaNum);
  };

  const formatRowString = (row) => {
    return `\n${row.UPC}\t${row.shelf}\t${row.quantity}\t${row.title.slice(
      0,
      50
    )}`;
  };

  const separateNullShelfRows = (scans) => {
    if (!scans) return { nullShelves: [], validShelves: [] };

    const nullShelfRows = [];
    const validShelfRows = [];
    scans.forEach((row) =>
      row.shelf ? validShelfRows.push(row) : nullShelfRows.push(row)
    );

    return { nullShelves: nullShelfRows, validShelves: validShelfRows };
  };

  const handleCopy = () => {
    const copyableString = constructRenderString();
    navigator.clipboard.writeText(copyableString);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const printTable = () => {
    const doc = new jsPDF();
    doc.setFontSize(11);
    doc.text(`Intake performed by: ${shelvingListResponse.userId}`, 15, 10);
    doc.text(
      `Date and Time: ${renderDate(shelvingListResponse.timestamp / 1000)}`,
      15,
      20
    );
    doc.text(
      `Unique Skus / Total QTY: ${shelvingListResponse.uniqueUpcs} / ${shelvingListResponse.totalItems}`,
      15,
      30
    );
    doc.text(
      `Shelved by:   ____________________     Completion date:   ____________________`,
      15,
      40
    );
    autoTable(doc, {
      html: "#shelving-list-table",
      startY: 50,
      headStyles: {
        fillColor: "#f9fafb",
        textColor: "#242424",
      },
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const { nullShelves, validShelves } = separateNullShelfRows(
    shelvingListResponse?.scans
  );

  const sortedValidShelves = sortScansByShelf(validShelves);
  const rowsSortedByShelf = sortedValidShelves.concat(nullShelves);

  const constructRenderString = () => {
    let str = "";
    str = str.concat(`Intake performed by:\t${shelvingListResponse.userId}`);
    str = str.concat(
      `\nDate and Time:\t${renderDate(shelvingListResponse.timestamp / 1000)}`
    );
    str = str.concat(
      `\nUnique Skus / Total QTY:\t${shelvingListResponse.uniqueUpcs} / ${shelvingListResponse.totalItems}`
    );
    str = str.concat(`\n\nShelved by:\t \tCompletion date: \t\n\n\n`);
    str = str.concat("UPC\tShelf\tQTY\tTitle");

    rowsSortedByShelf.forEach(
      (row) => (str = str.concat(formatRowString(row)))
    );

    return str;
  };

  // Helper function, makes and returns a link
  // element from a title string that includes one
  const getLinkElementFromTitle = (title) => {
    const div = document.createElement("div");
    div.innerHTML = title;
    return div.querySelector("a");
  };

  // Handles displaying a title for a product
  // Contains logic to parse link elements
  const displayTitleCell = (title) => {
    if (!title.includes("href="))
      return <Table.Cell>{title.slice(0, 50)}</Table.Cell>;

    const link = getLinkElementFromTitle(title);
    return (
      <Table.Cell>
        <a href={link.href}>{link.text.slice(0, 50)}</a>
      </Table.Cell>
    );
  };

  return (
    <Segment padded="very" style={{ minHeight: "750px" }}>
      <Form>
        <h2>Shelving List</h2>
        {/* General Info Header */}
        <Segment>
          <p>Intake performed by: {shelvingListResponse.userId}</p>
          <p>
            Date and Time: {renderDate(shelvingListResponse.timestamp / 1000)}
          </p>
          <p>
            Unique Skus / Total QTY: {shelvingListResponse.uniqueUpcs} /{" "}
            {shelvingListResponse.totalItems}
          </p>
        </Segment>
        {/* Rendered List */}
        <Container style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <Table id="shelving-list-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>UPC</Table.HeaderCell>
                <Table.HeaderCell>Shelf</Table.HeaderCell>
                <Table.HeaderCell>QTY</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rowsSortedByShelf.map((row) => (
                <Table.Row key={row.log_id}>
                  <Table.Cell>{row.UPC}</Table.Cell>
                  <Table.Cell>{row.shelf}</Table.Cell>
                  <Table.Cell>{row.quantity}</Table.Cell>
                  {displayTitleCell(row.title)}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>

        {/* Button Container */}
        <Segment
          padded
          basic
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button onClick={closeModal}>Close</Button>
          </div>

          <div>
            <Button onClick={handleCopy} color={copySuccess ? "green" : null}>
              {copySuccess ? "Copied" : "Copy"}
            </Button>
            <Button style={{ marginLeft: "24px" }} onClick={printTable}>
              Print
            </Button>
          </div>
        </Segment>
      </Form>
    </Segment>
  );
};

const SelectUserForm = ({
  closeModal,
  setShelvingListResponse,
  setDisplayState,
}) => {
  const [formData] = useState({ userId: "" });
  const [usernameList, setUsernameList] = useState([]);
  const validationSchema = Yup.object({
    userId: Yup.string().required(),
  });

  useEffect(() => {
    dvdlocator.get("/nosql/get-usernames").then((response) => {
      const usernameOptions = response.data.map((username) => ({
        key: username,
        value: username,
        text: username,
      }));
      setUsernameList(usernameOptions);
    });
  }, []);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={async (values, actions) => {
        console.log(values);
        const userId = values["userId"];
        setDisplayState("loading");

        dvdlocator
          .post("/nosql/session-log/shelving-list", { userId })
          .then((res) => {
            setShelvingListResponse(res.data);
            setDisplayState("list");
          })
          .catch((err) => alert(`${err}\n${err.data && err.data}`));
      }}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <h3 style={{ padding: "24px", textAlign: "center" }}>
            Select a user to generate a shelving list
          </h3>
          <div
            style={{
              display: "flex",
              flexFlow: "column nowrap",
              alignItems: "center",
              marginTop: "48px",
            }}
          >
            <ErrorMessage name="userId" component={RedErrorText} />

            <Dropdown
              name="userId"
              style={{ maxWidth: "400px" }}
              placeholder="Select User"
              value={formikProps.values["userId"]}
              onChange={(e, data) => {
                formikProps.setFieldTouched("userId", true, false);
                formikProps.setFieldValue("userId", data.value);
              }}
              fluid
              search
              selection
              options={usernameList}
            />
          </div>
          {/* Button Container */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "48px",
            }}
          >
            <Button secondary onClick={closeModal} type="button">
              Cancel
            </Button>
            <Button type="submit" color="blue">
              Generate Shelving List
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ShelvingList;
