import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { getUserToken } from "../redux/actions";
import JSignOut from "./auth/JSignOut";
import yomlogo from "../assets/icons/yom_logo.png";
import SettingsPanel from "./settings/SettingsPanel";

import "./Header.css";
import FeedbackForm from "./FeedbackForm";
import { Icon } from "semantic-ui-react";

class Header extends React.Component {
  state = {
    active: null,
    testEnv: false,
    navColor: "#1b1c1d",
    showModal: false,
    newVersionEffect: false,
  };

  hostColors = {
    "react.dvdlocator.com": "#1b1c1d",
    "preprod.dvdlocator.com": "#660066",
    "test.dvdlocator.com": "#db2828",
    localhost: "#6699FF",
  };

  async checkAppVersion() {
    const storedVersion = localStorage.getItem("REACT_APP_VERSION");
    const currentVersion = process.env.REACT_APP_VERSION;

    // Set new version in local storage
    if (!storedVersion || storedVersion !== currentVersion) {
      localStorage.setItem("REACT_APP_VERSION", currentVersion);
      this.setState({ newVersionEffect: true });

      // Turning back to false after 10 seconds
      await new Promise((resolve) => setTimeout(resolve, 10000));
      this.setState({ newVersionEffect: false });
    }
  }

  componentDidMount() {
    this.props.getUserToken();
    this.checkAppVersion();

    const hostname = window.location.hostname;

    const navColor = this.hostColors[hostname];

    if (hostname !== "react.dvdlocator.com") {
      this.setState({ testEnv: true, navColor: navColor });
    } else this.setState({ navColor: navColor });
  }

  attachTestHeaderClass() {
    if (!this.state.testEnv) return "";
    return "test-header";
  }

  renderItem = (text, path, classes = "") => {
    if (this.state.active !== text) {
      return (
        <Link
          to={path}
          onClick={() => this.setState({ active: text })}
          className={`item ${classes}`}
        >
          {text}
        </Link>
      );
    }

    return (
      <Link to={path} className={`active item ${classes}`}>
        {text}
      </Link>
    );
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    return (
      <div
        className={`ui inverted menu fixed inv-font`}
        style={{
          backgroundColor: this.state.navColor,
        }}
      >
        <FeedbackForm
          isOpen={this.state.showModal}
          toggleModal={this.toggleModal}
        ></FeedbackForm>

        <div className="left menu">
          <Link to="/">
            <div className="item">
              <img src={yomlogo} alt="Logo" height={35} width={35} />
            </div>
          </Link>
        </div>
        <div className="ui simple dropdown link item">
          <span className={`text ${this.attachTestHeaderClass()}`}>
            Datatables
          </span>
          <i className="dropdown icon"></i>
          <div className="ui menu">
            {this.renderItem("Pricetool", "/datatables/pricetool")}
            {this.renderItem("Amazon Pricing", "/datatables/amazon-pricing")}
            {this.renderItem("Amazon Orders", "/datatables/amazon-orders")}
            {this.renderItem("Rebates", "/datatables/rebates")}
            {this.renderItem("House", "/datatables/house")}
            {this.renderItem("House Received", "/datatables/house-received")}
            {this.renderItem("Damage", "/datatables/damage")}
            {this.renderItem("Damage Resolved", "/datatables/damage-resolved")}
          </div>
        </div>
        <div className="ui simple dropdown link item">
          <span className={`text ${this.attachTestHeaderClass()}`}>
            Inventory
          </span>
          <i className="dropdown icon"></i>
          <div className="ui menu">
            <div className="header">Inventory Tools</div>
            {this.renderItem("Add", "/inventory")}
            {this.renderItem("Sub", "/inventory/sub")}
            {this.renderItem("Mark", "/inventory/mark")}
            {this.renderItem("Damage", "/inventory/damage")}
            {this.renderItem("House", "/inventory/house")}
            {this.renderItem("Resolve", "/inventory/resolve")}
            {this.renderItem("Update ONO", "/inventory/update-ono")}
          </div>
        </div>

        {this.renderItem(
          "Column Sets",
          "/columnsets",
          this.attachTestHeaderClass()
        )}
        <div className="ui simple dropdown link item">
          <span className={`text ${this.attachTestHeaderClass()}`}>
            Reports
          </span>
          <i className="dropdown icon"></i>
          <div className="ui menu">
            {this.renderItem("Daily Inventory", "/reports/inventory")}
            {this.renderItem("Daily Totals", "/reports/totals")}
            {this.renderItem("Weekly Inventory", "/reports/weekly-inventory")}
            {this.renderItem("On-hand Inventory", "/reports/on-hand-inventory")}
            {this.renderItem(
              "Inventory Payables",
              "/reports/anticipated-billing"
            )}
            {this.renderItem("Overnight Tasks", "/reports/overnight-tasks")}
            <div className="ui left pointing dropdown link item">
              <i className="dropdown icon"></i>
              Shipments
              <div className="menu">
                {this.renderItem(
                  "Daily FBA Shipments Upload",
                  "/reports/shipments/daily-fba-upload"
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ui simple dropdown link item">
          <span className={`text ${this.attachTestHeaderClass()}`}>
            Documentation
          </span>
          <i className="dropdown icon"></i>
          <div className="ui menu">
            <a
              className="item"
              rel="noopener noreferrer"
              href="https://cdn.dvdlocator.com/pdf/AWS_Architecture_Diagram.pdf"
              target="_blank"
            >
              AWS Architecture
            </a>
            <a
              className="item"
              rel="noopener noreferrer"
              href="https://docs.google.com/document/d/17nnw5t_4sZxYJQFn-44QTq4MM3z_v5e5CUuK6YHO1PE"
              target="_blank"
            >
              Release Notes
            </a>
            <div className="ui left pointing dropdown link item">
              <i className="dropdown icon"></i>
              User Manuals
              <div className="menu">
                <a
                  className="item"
                  rel="noopener noreferrer"
                  href="https://docs.google.com/document/d/1lR1lEq69mvgx-BI_q8h8GuMOv4BezDlgkvFscbpTN-k/edit?usp=sharing"
                  target="_blank"
                >
                  Warehouse
                </a>
                <a
                  className="item"
                  rel="noopener noreferrer"
                  href="https://docs.google.com/document/d/1JM6eEvuFkrEBPhLZZ2TyrrvE_hfk3K-vDW02FTPr930/edit?usp=sharing"
                  target="_blank"
                >
                  Purchasing
                </a>
                <a
                  className="item"
                  rel="noopener noreferrer"
                  href="https://docs.google.com/document/d/1TXMab2y82PCICWc20UvJzndjb1blSGBVaFy-OQg__ac/edit?usp=sharing"
                  target="_blank"
                >
                  Pricing
                </a>
                <a
                  className="item"
                  rel="noopener noreferrer"
                  href="https://docs.google.com/document/d/1q3c8rB56BRxTofncbER2tO9EahxF0qRWLY7JKx4lyAc/edit?usp=sharing"
                  target="_blank"
                >
                  Other / Misc
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="ui simple dropdown link item">
          <span className={`text ${this.attachTestHeaderClass()}`}>
            Feedback
          </span>
          <i className="dropdown icon"></i>
          <div className="ui menu">
            <span className="item" onClick={this.toggleModal}>
              Send Feedback
            </span>
          </div>
        </div>
        <div className="right menu">
          {this.state.newVersionEffect && (
            <div className="item no-before new-version-pointer">
              <span>
                new version <Icon size="small" name="arrow circle right" />
              </span>
            </div>
          )}
          <div className="item no-before">
            <span
              id={
                this.state.newVersionEffect ? "new-version-header-effect" : ""
              }
            >
              v: {process.env.REACT_APP_VERSION}
            </span>
          </div>
          <div className="item">
            <SettingsPanel />
          </div>
          <div className="item">
            <JSignOut />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { getUserToken })(Header);
