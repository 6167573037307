// Header text to display and data keys for the in process queue
// for various inventory tools
export const processQueueFields = {
  Add: [
    { text: "UPC", dataKey: "UPC" },
    { text: "title", dataKey: "title" },
    { text: "supplier", dataKey: "supplier" },
    { text: "goodQuantity", dataKey: "quantity" },
    { text: "damageQuantity", dataKey: "damageQuantity" },
  ],
  Sub: [
    { text: "UPC", dataKey: "UPC" },
    { text: "title", dataKey: "title" },
    { text: "quantity", dataKey: "quantity" },
  ],
  Damage: [
    { text: "UPC", dataKey: "UPC" },
    { text: "title", dataKey: "title" },
    { text: "damageShelf", dataKey: "damageShelf" },
    { text: "damageReason", dataKey: "damageReason" },
    { text: "damageNote", dataKey: "damageNote" },
  ],
  Resolve: [
    { text: "UPC", dataKey: "UPC" },
    { text: "title", dataKey: "title" },
    { text: "category", dataKey: "category" },
  ],
  House: [
    { text: "UPC", dataKey: "UPC" },
    { text: "title", dataKey: "title" },
    { text: "orderNumber", dataKey: "orderNumber" },
    { text: "supplier", dataKey: "supplier" },
    { text: "supplierOrderNumber", dataKey: "supplierOrderNumber" },
    { text: "orderedBy", dataKey: "orderedBy" },
    { text: "price", dataKey: "price" },
    { text: "amount", dataKey: "amount" },
  ],
  Mark: [
    { text: "UPC", dataKey: "UPC" },
    { text: "title", dataKey: "title" },
    { text: "markingColumn", dataKey: "markingColumn" },
    { text: "marker", dataKey: "marker" },
  ],
  "Update ONO": [
    { text: "UPC", dataKey: "UPC" },
    { text: "title", dataKey: "title" },
    { text: "yomOrderNumber", dataKey: "yomOrderNumber" },
    { text: "quantity", dataKey: "quantity" },
  ],
};
