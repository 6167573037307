import React from "react";
import dvdlocator from "../../../apis/dvdlocator";
import { Formik, ErrorMessage } from "formik";
import { Form, Button, Dropdown } from "semantic-ui-react";
import * as Yup from "yup";
import { getTitle } from "../titleHandling";
import { sanitizeObjectStrings } from "../../utilities/sanitizeObjectStrings";

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

const UpdateONOInputs = ({ addProduct }) => {
  const dropdownOptions = [
    {
      key: "Alliance",
      text: "Alliance",
      value: "Alliance",
    },
    {
      key: "Dealsareus",
      text: "Dealsareus",
      value: "Dealsareus",
    },
    {
      key: "Ingram",
      text: "Ingram",
      value: "Ingram",
    },
    {
      key: "Discount Entertainment",
      text: "Discount Entertainment",
      value: "Discount Entertainment",
    },
    {
      key: "Mila Video",
      text: "Mila Video",
      value: "Mila Video",
    },
  ];
  const bulkAdd = async (values, actions) => {
    const records = values.bulkAddCsv.split(/\r?\n/);

    const data = {
      UPC: [],
      details: [],
    };
    for (const record of records) {
      const [upc, qty, yomOrderNumber] = record.split(",");
      data.UPC.push(upc.trim());
      data.details.push({
        ...values,
        UPC: upc.trim(),
        quantity: qty.trim(),
        yomOrderNumber: yomOrderNumber.trim(),
      });
    }
    await handleSubmit(data, actions);
  };

  const isQuantityPositive = (csvString) => {
    const records = csvString.split(/\r?\n/);

    for (const record of records) {
      const [, qty] = record.split(",");
      if (qty < 0) return false;
    }
    return true;
  };

  const validationSchema = Yup.object({
    supplier: Yup.string().required("Please select a supplier."),
    bulkAddCsv: Yup.string()
      .test(
        "negativeIntCheck",
        "Cannot enter negative quantity.",
        (value, meta) => {
          if (!value) return true;
          return isQuantityPositive(value);
        }
      )
      .required("Please enter comma-delimited input."),
  });

  const handleSubmit = async (values, actions) => {
    await dvdlocator
      .post("/product-titles?match=soft", values)
      .then((response) => response.data)
      .then((data) => {
        //for each UPC look up in the data
        for (let i = 0; i < values.UPC.length; i++) {
          const record = {
            UPC: values.UPC[i],
            quantity: values.details[i].quantity,
            yomOrderNumber: values.details[i].yomOrderNumber,
          };

          record.title = "NEEDS TITLE NEW";

          if (values.UPC[i] in data) {
            const title = data[values.UPC[i]];
            if (title) record.title = getTitle(title);
          }

          submitData(record, actions);
        }
      });
  };

  const submitData = (values, actions) => {
    addProduct(values);

    actions.setFieldValue("UPC", "", false);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        supplier: "",
        bulkAddCsv: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        values = sanitizeObjectStrings(values);
        await bulkAdd(values, actions);
      }}
    >
      {(formikProps) => (
        <Form widths="equal" onSubmit={formikProps.handleSubmit}>
          {/* Inputs */}
          <Form.Group>
            <Form.Field>
              <ErrorMessage name="supplier" component={RedErrorText} />
              <label className="ui label">Supplier</label>
              <Dropdown
                name="supplier"
                placeholder="Supplier"
                onChange={(e, data) => {
                  // setting formik state
                  formikProps.setFieldValue("supplier", data.value);
                  formikProps.setFieldTouched("supplier", true, false);
                }}
                value={formikProps.values["supplier"]}
                fluid
                selection
                options={dropdownOptions}
              />
            </Form.Field>

            <Form.Field>
              <ErrorMessage name="bulkAddCsv" component={RedErrorText} />
              <label className="ui label">Bulk Push (UPC,QTY,YOMOrder#)</label>
              <textarea
                name="bulkAddCsv"
                disabled={formikProps.values["supplier"].length === 0}
                onChange={formikProps.handleChange}
                value={formikProps.values["bulkAddCsv"]}
              />
            </Form.Field>
          </Form.Group>
          {/* End Inputs */}

          {/* Buttons */}
          <Form.Group inline>
            <Button type="submit" inverted color="blue">
              Push to Queue
            </Button>
          </Form.Group>
          {/* End Buttons */}
        </Form>
      )}
    </Formik>
  );
};

export default UpdateONOInputs;
