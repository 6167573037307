import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Segment, Table, Button } from "semantic-ui-react";
import { fetchInventoryLog } from "../../../redux/actions";
import LoadingBox from "../LoadingBox";
import DatePicker from "react-datepicker";
import "./DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";

class SessionLogReport extends React.Component {
  state = {
    loading: false,
    selectedDate: null,
    openDatePicker: false,
    copySuccess: false,
  };

  fetchLog() {
    this.setState({ loading: true });
    this.props
      .fetchInventoryLog()
      .catch((err) => alert(err))
      .finally(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.fetchLog();
  }

  /**
   * Populates fields that may not exist consistently
   * across all session log objects with empty strings.
   */
  populateNewEmptyFields(log) {
    return {
      ...log,
      supplier: log.supplier || "",
      ONO: log.ONO === undefined || log.ONO === null ? "" : log.ONO,
      added: log.added === undefined || log.added === null ? "" : log.added,
    };
  }

  stringifyLogValues(log) {
    log = this.populateNewEmptyFields(log);
    const date = new Date(log.date);
    log.date = date;
    log.submitted = log.date;
    const logString = `${log.date.toLocaleString().replace(",", "")}\t${
      log.userId
    }\t${log.action}\t${log.UPC}\t${log.count}\t${log.added}\t${log.ONO}\t${
      log.supplier
    }\t${log.status}\t${log.notes ? log.notes : log.title}\n`;
    return logString;
  }

  generateCopyableString() {
    const reportDate = this.state.selectedDate
      ? new Date(this.state.selectedDate)
      : new Date();

    let str = `Date Displayed:\t${reportDate.toLocaleDateString()}\n`;
    str = str.concat(
      `Time Report Generated:\t${new Date().toLocaleString()}\n\n`
    );
    str = str.concat(
      "Date\tUser ID\tAction\tUPC\tCount\tAdded\tONO\tSupplier\tStatus\tNotes\n"
    );
    this.sortLog().forEach((log) => {
      const logString = this.stringifyLogValues(log);
      str = str.concat(logString);
    });
    return str;
  }

  copyToClipboard() {
    const textToCopy = this.generateCopyableString();
    navigator.clipboard.writeText(textToCopy);
    this.setState({ copySuccess: true });
    setTimeout(() => this.setState({ copySuccess: false }), 1000);
  }

  downloadCSV() {
    const dateString = this.state.selectedDate
      ? new Date(this.state.selectedDate).toLocaleString()
      : new Date().toLocaleString();
    const csvString = this.generateCopyableString().replace(/\t/g, ",");

    // Create a blob
    var blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", `${dateString}.csv`);
    pom.click();
  }

  sortLog() {
    if (!this.props.inventoryLog) return;

    const inventoryRecords = this.props.inventoryLog.map((item) => item);
    inventoryRecords.sort((a, b) => b.date - a.date);
    return inventoryRecords;
  }

  getInventoryReportForDate(date) {
    this.setState({ loading: true, selectedDate: date });

    const dateString = date.toDateString("us-en");
    this.props
      .fetchInventoryLog(dateString)
      .catch((err) => alert(err))
      .finally(() => this.setState({ loading: false }));
  }

  renderProducts() {
    const sortedInventoryRecords = this.sortLog();
    return _.map(sortedInventoryRecords, (ret, index) => {
      ret.submitted = new Date(ret.date);
      return (
        <Table.Row key={ret.log_id}>
          <Table.Cell>{ret.submitted.toLocaleString()}</Table.Cell>
          <Table.Cell>{ret.userId}</Table.Cell>
          <Table.Cell>{ret.action}</Table.Cell>
          <Table.Cell>{ret.UPC}</Table.Cell>
          <Table.Cell>{ret.count}</Table.Cell>
          <Table.Cell>{ret.added}</Table.Cell>
          <Table.Cell>{ret.ONO}</Table.Cell>
          <Table.Cell>{ret.supplier}</Table.Cell>
          <Table.Cell>{ret.status}</Table.Cell>
          <Table.Cell>
            <span
              dangerouslySetInnerHTML={{
                __html: ret.notes ? ret.notes : ret.title,
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  changeDate(value) {
    this.setState({ date: value });
  }

  render() {
    return (
      <Segment className="grid-box">
        <div
          style={{ padding: "24px 0", display: "flex", alignItems: "center" }}
        >
          <h2 style={{ marginBottom: 0 }}>Daily Inventory</h2>

          <Button
            style={{ margin: "0 36px 0 24px" }}
            type="button"
            color="blue"
            icon="sync"
            title="Refresh data."
            disabled={
              this.state.selectedDate &&
              this.state.selectedDate.toDateString() !==
                new Date().toDateString()
            }
            onClick={() => this.fetchLog()}
          />
          <div style={{ display: "flex", flexFlow: "column nowrap" }}>
            <DatePicker
              onInputClick={() => this.setState({ openDatePicker: true })}
              customInput={
                <Button inverted secondary>
                  {this.state.selectedDate
                    ? this.state.selectedDate.toDateString("us-en")
                    : "Select Day"}
                </Button>
              }
              onClickOutside={() => this.setState({ openDatePicker: false })}
              open={this.state.openDatePicker}
              selected={this.state.selectedDate}
              onChange={(date) => this.getInventoryReportForDate(date)}
            />
          </div>
          <Button
            onClick={() => {
              this.copyToClipboard();
            }}
            style={{ margin: "0 36px" }}
            inverted
            secondary={!this.state.copySuccess}
            color={this.state.copySuccess ? "green" : "grey"}
          >
            {this.state.copySuccess ? "Copied" : "Copy to Clipboard"}
          </Button>
          <Button inverted secondary onClick={() => this.downloadCSV()}>
            Export CSV
          </Button>
        </div>

        {this.state.loading ? (
          <LoadingBox />
        ) : (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>User ID</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                <Table.HeaderCell>UPC</Table.HeaderCell>
                <Table.HeaderCell title="How many pieces of any kind, from any inventory tool, were in the scan">
                  Count
                </Table.HeaderCell>
                <Table.HeaderCell title="How much this UPC’s inventory increased by">
                  Added
                </Table.HeaderCell>
                <Table.HeaderCell title="How much this product’s ONO reduced by">
                  ONO
                </Table.HeaderCell>
                <Table.HeaderCell>Supplier</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Notes</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderProducts()}</Table.Body>
          </Table>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return { inventoryLog: state.reports.inventoryLog };
};

export default connect(mapStateToProps, { fetchInventoryLog })(
  SessionLogReport
);
