/* Contains various override functions for inline editing */
import $ from "jquery";
// Keys should be column header text
// value should be custom edit functionality for that column
const OVERRIDE_FUNCTIONS = {
  "Media Types": ($td, row, editor) => mediaTypes($td, row, editor),
};

/**
 * Handles override logic for certain columns
 */
export function overrideInlineInput(headerText, $td, row, editor) {
  const func = OVERRIDE_FUNCTIONS[headerText];

  if (!func) return;

  func($td, row, editor);
}

/* Custom Override Functions */

function mediaTypes($td, row, editor) {
  // Hiding existing input
  const $input = $td.find("input");
  if ($input) $input.css("display", "none");

  const $fieldDiv = $td.find(".DTE_Inline_Field");

  // Create the dropdown element
  const select = document.createElement("select");
  select.setAttribute("multiple", "");
  select.style.width = "80%";

  $fieldDiv.append(select);

  // Define the options
  const options = [];

  editor
    .field("media_types[].id")
    .input()
    .children()
    .each(function () {
      // 'this' refers to the current option element in the iteration
      const $option = $(this);

      // Extract the value and text from the option element
      const value = $option.val();
      const text = $option.text();

      // Add an object representing the option to the options array
      options.push({ value: value, label: text });
    });

  // Populate the select element with options
  options.forEach((option) => {
    const optionElement = document.createElement("option");
    optionElement.value = option.value;
    optionElement.textContent = option.label;
    select.appendChild(optionElement);
  });

  // Modify onkeydown for the select
  select.onkeydown = function (event) {
    event.preventDefault();
    if (event.key === "Enter") {
      const selectedOptions = Array.from(select.selectedOptions).map(
        (option) => option.value
      );
      console.log(selectedOptions);
      removeSelectAndCleanup();

      editor.edit(row, false).set("media_types[].id", selectedOptions).submit();
    }
  };

  function onClickOutside(event) {
    // Check if the click was outside the select element
    if (!select.contains(event.target)) {
      removeSelectAndCleanup(); // Function to remove select and cleanup event listeners
    }
  }

  function removeSelectAndCleanup() {
    if (!$td.find("select")) return;

    // Remove select element from DOM
    $td.children("select").remove();

    // Cleanup: Remove this click event listener to prevent memory leaks
    window.removeEventListener("click", onClickOutside);
  }

  // Add click event listener to window
  window.addEventListener("click", onClickOutside, true);
}
