import React from "react";
import dvdlocator from "../../../apis/dvdlocator";
import { Formik, ErrorMessage } from "formik";
import { Form, Button } from "semantic-ui-react";
import * as Yup from "yup";
import { sanitizeObjectStrings } from "../../utilities/sanitizeObjectStrings";
import { getTitle } from "../titleHandling";
import { useDispatch, useSelector } from "react-redux";
import { setSubRefreshQuantity } from "../../../redux/actions/subActions";

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

const SubInputs = ({ addProduct }) => {
  const refreshQty = useSelector(
    (state) => state.inventoryForm.subRefreshQuantity
  );
  const dispatch = useDispatch();

  const handleClearQty = (mutableFormikValues) => {
    if (!refreshQty) return;

    mutableFormikValues.quantity = "";
    dispatch(setSubRefreshQuantity(false));
  };

  const bulkAdd = async (values) => {
    const input = prompt("Input comma/newline separated UPCs: ");
    if (!input) return;
    const upcs = input
      .replace(/\r?\n/g, ",")
      .replace(/\r/, ",")
      .split(",")
      .map((upc) => upc.trim());
    if (upcs.length < 1) return;

    values = { ...values, UPC: upcs };
    await handleSubmit(values);
  };

  const validationSchema = Yup.object({
    UPC: Yup.string().required("Please enter a UPC."),
    quantity: Yup.number().moreThan(-1, "Cannot be negative value."),
  });

  const handleSubmit = async (values, actions) => {
    let upcTitle = "NEEDS TITLE NEW";
    let upcList = Array.isArray(values.UPC)
      ? [...values.UPC]
      : Array(values.UPC);

    await dvdlocator
      .post("/product-titles", values)
      .then((response) => response.data)
      .then((data) => {
        for (let upc = 0; upc < upcList.length; upc++) {
          const record = {
            UPC: upcList[upc],
            quantity: values.quantity,
          };
          if (upcList[upc] in data) {
            const title = data[upcList[upc]];
            if (title) {
              record.title = getTitle(title);
            } else record.title = upcTitle;
          } else record.title = upcTitle;

          submitData(record, actions);
        }
      });
  };

  const submitData = (values, actions) => {
    if (values.quantity === "") {
      values.quantity = 1;
    }
    addProduct(values);
    // Exiting early for bulk adds
    if (!actions) return;
    actions.setFieldValue("UPC", "", false);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ UPC: "", quantity: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        values = sanitizeObjectStrings(values);

        await handleSubmit(values, actions);

        actions.setFieldValue("UPC", "", false);
      }}
    >
      {(formikProps) => (
        <Form widths="equal" onSubmit={formikProps.handleSubmit}>
          {handleClearQty(formikProps.values)}
          {/* Inputs */}
          <Form.Group>
            <Form.Field>
              <ErrorMessage name="UPC" component={RedErrorText} />
              <label className="ui label">UPC</label>
              <input
                name="UPC"
                onChange={formikProps.handleChange}
                value={formikProps.values["UPC"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="quantity" component={RedErrorText} />
              <label className="ui label">Quantity</label>
              <input
                name="quantity"
                onChange={formikProps.handleChange}
                value={formikProps.values["quantity"]}
              />
            </Form.Field>
          </Form.Group>
          {/* End Inputs */}

          {/* Buttons */}
          <Form.Group inline>
            <Button type="submit" inverted color="blue">
              Push to Queue
            </Button>
            <Button
              type="button"
              onClick={() => {
                formikProps.validateForm().then(async (errors) => {
                  formikProps.setFieldTouched("quantity");
                  formikProps.setFieldTouched("UPC", false);
                  if (errors["quantity"]) return;
                  const values = sanitizeObjectStrings(formikProps.values);
                  await bulkAdd(values);
                });
              }}
            >
              Bulk Push
            </Button>
          </Form.Group>
          {/* End Buttons */}
        </Form>
      )}
    </Formik>
  );
};

export default SubInputs;
