import React, { useState, useRef } from "react";
import { ErrorMessage } from "formik";
import { Form, Dropdown } from "semantic-ui-react";
import dvdlocator from "../../apis/dvdlocator";

export const FileUploader = ({ handleFile }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState("");
  const [parsedData, setParsedData] = useState(null);
  const [supplierName, setSupplierName] = useState(null);

  const RedErrorText = ({ children }) => (
    <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
  );

  const dropdownOptions = [
    {
      key: "Discount Entertainment",
      text: "Discount Entertainment",
      value: "Discount Entertainment",
    },
    {
      key: "Mila Video",
      text: "Mila Video",
      value: "Mila Video",
    },
  ];
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      setSelectedFile(fileUploaded);
      setErrorMessage("");

      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        setParsedData(parseCSV(content, 5000));
        console.log(supplierName);
      };

      reader.onerror = (event) => {
        setErrorMessage("Error reading the file.");
      };

      reader.readAsText(fileUploaded);
    } else {
      setSelectedFile(null);
      setErrorMessage("Please select a file.");
    }
    handleFile(fileUploaded);
  };

  const parseCSV = (csvContent, batchSize) => {
    const rows = csvContent.split("\n");
    const csvData = [];
    // Start the loop from 1 to skip the headers row
    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(",");
      const rowData = {};
      rowData.SKU = row[0];
      rowData.QTY = row[2];
      rowData.PRICE = row[3];
      csvData.push(rowData);
    }

    // Break down csvData into batches
    const batches = [];
    for (let i = 0; i < csvData.length; i += batchSize) {
      const batch = csvData.slice(i, i + batchSize);
      batches.push(batch);
    }

    return batches;
  };

  const setSupplierValue = (e, data) => {
    setSupplierName(data.value);
  };

  // download output file
  const downloadFile = (finalResult) => {
    const blob = new Blob([JSON.stringify(finalResult)], {
      type: "text/csv;charset=utf-8",
    });
    // Create a link element
    const link = document.createElement("a");
    // Set the href attribute to a Blob URL representing the data
    link.href = URL.createObjectURL(blob);
    // get current date for file name
    const date = getCurrentDate();
    // Specify the filename for the download
    link.download = `Output_${date}.csv`;
    // Append the link to the document
    document.body.appendChild(link);
    // Trigger a click on the link to initiate the download
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
  };

  // Function to submit POST requests in batch with sequential processing
  const submitBatchRequestsSequentially = async (parsedData, supplier) => {
    let finalResult = {};
    let updated = 0;
    let errors = 0;
    let skuErrList = [];
    try {
      for (const batch of parsedData) {
        const result = await dvdlocator.post("/csv-data-parse", {
          supplierName: [supplier],
          data: batch,
        });
        updated += result.data.success;
        errors += result.data.errors;
        result.data.errList.forEach((row) => {
          skuErrList.push(row);
        });
      }
      finalResult = { updated: updated, errors: errors };

      window.alert(
        "File submissions success. Output file will download automatically data"
      );
      downloadFile(finalResult);

      return finalResult;
    } catch (error) {
      console.error("Error during batch processing:", error);
      throw error;
    }
  };

  const handleUpload = () => {
    const supplier = supplierName;
    if (supplier && selectedFile) {
      submitBatchRequestsSequentially(parsedData, supplier);
    } else {
      window.alert(
        "Please select a supplier AND choose a file before uploading."
      );
    }
  };

  return (
    <>
      <Form widths="equal">
        {/* Inputs */}
        <Form.Group>
          <Form.Field>
            <ErrorMessage name="supplier" component={RedErrorText} />
            <label className="ui label">Supplier</label>
            <Dropdown
              name="supplier"
              placeholder="Supplier"
              onChange={setSupplierValue}
              value={supplierName}
              fluid
              selection
              options={dropdownOptions}
            />
          </Form.Field>
          <Form.Field>
            <label className="ui label">CSV File</label>
            <button className="button-upload" onClick={handleClick}>
              Choose File
            </button>
            <input
              type="file"
              onChange={handleChange}
              ref={hiddenFileInput}
              // Make the file input element invisible
            />
            <button
              className="button-upload  upload-button"
              onClick={handleUpload}
            >
              Upload File
            </button>
          </Form.Field>
          {/* End Inputs */}
        </Form.Group>
        {/* End Buttons */}
      </Form>
    </>
  );
};
