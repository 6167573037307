import React from "react";
import ReactDOM from "react-dom";
import BuyboxInfoLegend from "../../Pricetool/tooltips/BuyboxInfo";
import BuyboxMarkupLegend from "../../Pricetool/tooltips/BuyboxMarkup";

export const attachCustomDatatableTooltips = () => {
  const buyBoxInfoContainer = document.querySelector("#buybox-info-container");
  const existingInfoTooltip = document.querySelector(
    "#buybox-info-legend-tooltip"
  );

  if (buyBoxInfoContainer && !existingInfoTooltip)
    ReactDOM.render(<BuyboxInfoLegend />, buyBoxInfoContainer);

  const buyBoxMarkupContainer = document.querySelector(
    "#buybox-markup-container"
  );
  const existingMarkupTooltip = document.querySelector(
    "#buybox-markup-legend-tooltip"
  );

  if (buyBoxMarkupContainer && !existingMarkupTooltip)
    ReactDOM.render(<BuyboxMarkupLegend />, buyBoxMarkupContainer);
};
