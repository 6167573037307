import React, { useState } from "react";
import dvdlocator from "../../../apis/dvdlocator";
import { Formik, ErrorMessage } from "formik";
import { Form, Button, Dropdown } from "semantic-ui-react";
import * as Yup from "yup";
import { sanitizeObjectStrings } from "../../utilities/sanitizeObjectStrings";
import _ from "lodash";
import ProgressBarModal from "../../progress/ProgressBarModal";

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

const MarkInputs = ({ addProduct, addProducts }) => {
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressData, setProgressData] = useState({
    total: 1,
    current: 0,
  });

  const dropdownOptions = [
    {
      key: "part_number",
      text: "Part Number",
      value: "part_number",
    },
    {
      key: "an",
      text: "Analyst Notes",
      value: "an",
    },
    {
      key: "amz_queue",
      text: "Amazon Queue",
      value: "amz_queue",
    },
    {
      key: "house",
      text: "House",
      value: "house",
    },
    {
      key: "orders",
      text: "Orders",
      value: "orders",
    },
    {
      key: "listory",
      text: "Listory",
      value: "listory",
    },
  ];

  const bulkAdd = async (values) => {
    const input = prompt("Input comma/newline separated UPCs: ");
    if (!input) return;
    const upcs = input
      .replace(/\r?\n/g, ",")
      .replace(/\r/, ",")
      .split(",")
      .map((upc) => upc.trim());
    if (upcs.length < 1) return;

    values = { ...values, UPC: upcs };
    await handleSubmit(values);
  };

  const validationSchema = Yup.object({
    UPC: Yup.string().required("Please enter a UPC."),
    marker: Yup.string().required("Please enter a mark."),
    markingColumn: Yup.string().required("Please enter a column to mark."),
  });

  const handleSubmit = (values, actions) => {
    const isBulkSubmit = Array.isArray(values.UPC);

    if (isBulkSubmit) return handleBulkSubmit(values, values.UPC);

    dvdlocator
      .post("/product-titles", { UPC: values.UPC })
      .then((res) => {
        const upcTitle = res.data;

        submitData(
          { ...values, title: upcTitle[values.UPC] || "NEEDS TITLE NEW" },
          actions
        );
      })
      .catch((err) => console.log(err));
  };

  const handleBulkSubmit = (values, upcs) => {
    const batchSize = 200;
    const batches = _.chunk(upcs, batchSize);

    setProgressData({ total: upcs.length, current: 0 });
    setShowProgressBar(true);

    // Processing UPCs in batches
    for (const batch of batches) {
      dvdlocator
        .post("/product-titles", { UPC: batch })
        .then((res) => {
          const upcTitles = res.data;

          const products = batch.map((upc) => ({
            ...values,
            UPC: upc,
            title: upcTitles[upc] || "NEEDS TITLE NEW",
          }));

          addProducts(products);

          setProgressData((prev) => ({
            ...prev,
            current: prev.current + batch.length,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  const submitData = (values, actions) => {
    addProduct(values);

    actions.setFieldValue("UPC", "", false);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ UPC: "", marker: "", markingColumn: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        values = sanitizeObjectStrings(values);

        await handleSubmit(values, actions);

        actions.setFieldValue("UPC", "", false);
      }}
    >
      {(formikProps) => (
        <Form widths="equal" onSubmit={formikProps.handleSubmit}>
          <ProgressBarModal
            title="Processing large list.."
            label="UPCs processed"
            total={progressData.total}
            value={progressData.current}
            progressType="ratio"
            open={showProgressBar}
            setOpen={(val) => setShowProgressBar(val)}
          />
          {/* Inputs */}
          <Form.Group>
            <Form.Field>
              <ErrorMessage name="UPC" component={RedErrorText} />
              <label className="ui label">UPC</label>
              <input
                name="UPC"
                onChange={formikProps.handleChange}
                value={formikProps.values["UPC"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="markingColumn" component={RedErrorText} />
              <label className="ui label">Enter Marking Column</label>
              <Dropdown
                name="markingColumn"
                placeholder="Column"
                onChange={(e, data) => {
                  formikProps.setFieldTouched("markingColumn", true, false);
                  formikProps.setFieldValue("markingColumn", data.value);
                }}
                value={formikProps.values["markingColumn"]}
                fluid
                selection
                disabled={formikProps.isSubmitting}
                options={dropdownOptions}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="marker" component={RedErrorText} />
              <label className="ui label">Marker</label>
              <input
                name="marker"
                onChange={formikProps.handleChange}
                value={formikProps.values["marker"]}
              />
            </Form.Field>
          </Form.Group>
          {/* End Inputs */}

          {/* Buttons */}
          <Form.Group inline>
            <Button type="submit" inverted color="blue">
              Push to Queue
            </Button>
            <Button
              type="button"
              onClick={() => {
                formikProps.validateForm().then(async (errors) => {
                  formikProps.setFieldTouched("quantity");
                  formikProps.setFieldTouched("UPC", false);
                  if (errors["quantity"]) return;
                  const values = sanitizeObjectStrings(formikProps.values);
                  await bulkAdd(values);
                });
              }}
            >
              Bulk Push
            </Button>
          </Form.Group>
          {/* End Buttons */}
        </Form>
      )}
    </Formik>
  );
};

export default MarkInputs;
