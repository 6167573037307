import React from "react";
import dvdlocator from "../../../apis/dvdlocator";
import { Formik, ErrorMessage } from "formik";
import { Form, Button } from "semantic-ui-react";
import * as Yup from "yup";
import { sanitizeObjectStrings } from "../../utilities/sanitizeObjectStrings";
import { getTitle } from "../titleHandling";

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

const DamageInputs = ({ addProduct }) => {
  const bulkAdd = async (values) => {
    const input = prompt("Input comma/newline separated UPCs: ");
    if (!input) return;
    const upcs = input
      .replace(/\r?\n/g, ",")
      .replace(/\r/, ",")
      .split(",")
      .map((upc) => upc.trim());
    if (upcs.length < 1) return;

    values = { ...values, UPC: upcs };
    await handleSubmit(values);
  };

  const validationSchema = Yup.object({
    UPC: Yup.string().required("Please enter a UPC."),
    damageShelf: Yup.string().required("Please enter a location."),
    damageReason: Yup.string()
      .trim()
      .oneOf(["1", "2", "3"], "Must be 1, 2, or 3.")
      .required("Please enter a damage reason."),
    damageNote: Yup.string(),
  });

  const handleSubmit = async (values, actions) => {
    var upcTitle = "NEEDS TITLE NEW";

    let upcList = Array.isArray(values.UPC)
      ? [...values.UPC]
      : Array(values.UPC);

    await dvdlocator
      .post("/product-titles", values)
      .then((response) => response.data)
      .then((data) => {
        for (let upc = 0; upc < upcList.length; upc++) {
          const record = {
            UPC: upcList[upc],
            damageNote: values.damageNote,
            damageReason: values.damageReason,
            damageShelf: values.damageShelf,
          };

          if (upcList[upc] in data) {
            const title = data[upcList[upc]];
            if (title) {
              record.title = getTitle(title);
            } else record.title = upcTitle;
          } else record.title = upcTitle;

          submitData(record, actions);
        }
      });
  };
  const submitData = (values, actions) => {
    addProduct(values);
    // Exiting early for bulk adds
    if (!actions) return;
    actions.setFieldValue("UPC", "", false);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        UPC: "",
        damageShelf: "",
        damageReason: "",
        damageNote: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        values = sanitizeObjectStrings(values);

        await handleSubmit(values, actions);
      }}
    >
      {(formikProps) => (
        <Form widths="equal" onSubmit={formikProps.handleSubmit}>
          {/* Inputs */}
          <Form.Group>
            <Form.Field>
              <ErrorMessage name="UPC" component={RedErrorText} />
              <label className="ui label">UPC</label>
              <input
                name="UPC"
                onChange={formikProps.handleChange}
                value={formikProps.values["UPC"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="damageShelf" component={RedErrorText} />
              <label className="ui label">Damage Shelf</label>
              <input
                name="damageShelf"
                onChange={formikProps.handleChange}
                value={formikProps.values["damageShelf"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="damageReason" component={RedErrorText} />
              <label className="ui label">Damage Reason</label>
              <input
                name="damageReason"
                onChange={formikProps.handleChange}
                value={formikProps.values["damageReason"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="damageNote" component={RedErrorText} />
              <label className="ui label">Damage Note</label>
              <input
                name="damageNote"
                onChange={formikProps.handleChange}
                value={formikProps.values["damageNote"]}
              />
            </Form.Field>
          </Form.Group>
          {/* End Inputs */}

          {/* Buttons */}
          <Form.Group inline>
            <Button type="submit" inverted color="blue">
              Push to Queue
            </Button>
            <Button
              type="button"
              onClick={() => {
                formikProps.validateForm().then(async (errors) => {
                  formikProps.setFieldTouched("damageShelf");
                  formikProps.setFieldTouched("damageReason");
                  formikProps.setFieldTouched("UPC", false);
                  if (errors["damageShelf"] || errors["damageReason"]) return;
                  const values = sanitizeObjectStrings(formikProps.values);
                  await bulkAdd(values);
                });
              }}
            >
              Bulk Push
            </Button>
          </Form.Group>
          {/* End Buttons */}
        </Form>
      )}
    </Formik>
  );
};

export default DamageInputs;
