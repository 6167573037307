import React, { useEffect, useState } from "react";
import dvdlocator from "../../../../apis/dvdlocator";
import { Button, Modal, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { SET_SUPPLIER_TYPE } from "../../../../redux/actions/types";
import RetailSupplierForm from "./RetailForm";
import DistributorSupplierForm from "./DistributorForm";
import { getTitle } from "../../titleHandling";

const AddInputs = ({ addProduct }) => {
  const [supplierType, setSupplierType] = useState("Distributor");
  const [formDisabled, setFormDisabled] = useState(true);
  const [displayBlock, setDisplayBlock] = useState(false);
  const productQueue = useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SET_SUPPLIER_TYPE, payload: "" });
  }, [dispatch]);

  const bulkAdd = async (values) => {
    const input = prompt("Input comma/newline separated UPCs: ");
    if (!input) return;
    const upcs = input
      .replace(/\r?\n/g, ",")
      .replace(/\r/, ",")
      .split(",")
      .map((upc) => upc.trim());
    if (upcs.length < 1) return;
    values = { ...values, UPC: upcs };
    await handleSubmit(values);
  };

  const handleSubmit = async (values, actions) => {
    let upcTitle = "NEEDS TITLE NEW";
    let upcList = Array.isArray(values.UPC)
      ? [...values.UPC]
      : Array(values.UPC);

    await dvdlocator
      .post("/product-titles", values)
      .then((response) => response.data)
      .then((data) => {
        //for each UPC look up in the data
        for (let upc = 0; upc < upcList.length; upc++) {
          const record = {
            UPC: upcList[upc],
            quantity: values.quantity,
            supplier: values.supplier,
            damageQuantity: values.damageQuantity,
          };

          if (upcList[upc] in data) {
            const title = data[upcList[upc]];
            if (title) {
              record.title = getTitle(title);
            } else record.title = upcTitle;
          } else record.title = upcTitle;

          submitData(record, actions);
        }
      });
  };

  const submitData = (values, actions) => {
    addProduct({ ...values, supplierType });

    // Exiting early for bulk adds
    if (!actions) return;
    actions.setFieldValue("UPC", "", false);
    actions.setSubmitting(false);
  };

  /**
   * Checks length of valid entries in array.
   * Filters out null/undefined values.
   */
  const getRealQueueLength = () => {
    return productQueue.filter((item) => item).length;
  };

  const isDistributerFormActive =
    !formDisabled && supplierType === "Distributor";
  const isRetailFormActive = !formDisabled && supplierType === "Retail";

  return (
    <>
      <Modal open={displayBlock} onClose={() => setDisplayBlock(false)}>
        <Segment padded="very">
          <div
            style={{
              display: "flex",
              flexFlow: "column nowrap",
              alignItems: "center",
            }}
          >
            <h3 style={{ marginBottom: "24px" }}>
              Please submit your existing queue before continuing.
            </h3>
            <Button onClick={() => setDisplayBlock(false)} primary>
              Ok
            </Button>
          </div>
        </Segment>
      </Modal>
      {/* Supplier Type Selection */}
      <div style={{ marginBottom: "32px" }}>
        <h4>Is this a Distributor or Retail supplier?</h4>
        <Button
          style={{ marginRight: "12px" }}
          color={isRetailFormActive ? "blue" : null}
          inverted={isRetailFormActive}
          onClick={(e) => {
            if (getRealQueueLength() > 0) return setDisplayBlock(true);

            e.target.blur();
            setFormDisabled(false);
            setSupplierType("Retail");
            dispatch({ type: SET_SUPPLIER_TYPE, payload: "Retail" });
          }}
        >
          Retail
        </Button>
        <Button
          className="no-hover"
          color={isDistributerFormActive ? "blue" : null}
          inverted={isDistributerFormActive}
          onClick={(e) => {
            if (getRealQueueLength() > 0) return setDisplayBlock(true);

            e.target.blur();
            setFormDisabled(false);
            setSupplierType("Distributor");
            dispatch({ type: SET_SUPPLIER_TYPE, payload: "Distributor" });
          }}
        >
          Distributor
        </Button>
      </div>
      {/* End Supplier Type Selection */}

      {/* Supplier Specific Forms To Render */}
      {supplierType === "Distributor" && (
        <DistributorSupplierForm
          handleSubmit={handleSubmit}
          bulkAdd={bulkAdd}
          formDisabled={formDisabled}
        />
      )}
      {supplierType === "Retail" && (
        <RetailSupplierForm
          handleSubmit={handleSubmit}
          bulkAdd={bulkAdd}
          formDisabled={formDisabled}
        />
      )}
      {/* End Supplier Specific Forms To Render */}
    </>
  );
};

export default AddInputs;
