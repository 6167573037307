import dvdlocator from "../../../../apis/dvdlocator";
import _ from "lodash";
import { getStoreLinks } from "./storeLinks";
import { removeHtmlFromString } from "../../../utilities/removeHtmlFromString";
import BigNumber from "bignumber.js";

export const averageCogs = () => {
  dvdlocator.post("/avg-cogs").catch((err) => console.log(err));
};

export const openLinks = (datatable, store) => {
  const data = datatable.rows({ selected: true }).data();

  let url = getStoreLinks(store, data[0], data[0]?.product.upc);

  if (!data.length) {
    return window.open(url, "_blank");
  }

  _.each(data, (row) => {
    url = getStoreLinks(store, row, row?.product.upc);
    if (!Array.isArray(url)) return window.open(url, "_blank");

    _.each(url, (u) => {
      window.open(u, "_blank");
    });
  });
};

/**
 * Calculates the fees and pricing for a product at specific margins.
 * Note - all values are in cents.
 * @param {object} datatable The datatable API instance.
 * @returns Returns a fees object.
 */
export const amazonCalculator = async (datatable) => {
  const fillMode = document
    .querySelector("#selectamzcalcfillmode")
    .value.toLowerCase();

  let productData = datatable.row({ selected: true }).data();

  if (!productData.product.asin)
    return alert("Can't calculate Amazon fees for product with no ASIN.");

  // Ensuring we have a Cost of Good value
  let cogs = productData.product.cogs;

  const providedCogs = window.prompt(
    `Requesting user input ${!cogs ? "[Required]" : ""}
                                              \nTITLE: ${removeHtmlFromString(
                                                productData["product"]["title"]
                                              )} 
                                              \nASIN: ${
                                                productData.product.asin
                                              }.
                                              \n\nPlease input a value for: 'product.cogs'`,
    cogs ? new BigNumber(cogs).dividedBy(100).toFixed(2) : ""
  );

  if (!providedCogs) return;
  cogs = new BigNumber(providedCogs).multipliedBy(100).toFixed(0);

  // Get Amazon fee estimates
  let estimateResponse = [];
  try {
    estimateResponse = await dvdlocator.post("/amazon-fees", {
      asins: [productData.product.asin],
      yomSkus: [productData.product.yom_sku],
    });
  } catch (err) {
    console.log(err);
    alert(`Failed to get amazon fees. Please try again.`);
    return;
  }

  // Get pricing data and update columns
  const fees = estimateResponse.data.fees[0];
  try {
    const response = await dvdlocator.post("/amazon-price-calculator", {
      requests: [
        {
          variableFee: new BigNumber(fees.variableFee).toFixed(0),
          fbaFee: new BigNumber(fees.fbaFee).toFixed(0),
          yomSku: productData.product.yom_sku,
          type: fillMode,
          price: productData.product.listing_price,
          cogs,
        },
      ],
    });

    if (!fees.fbaFee)
      alert("WARNING: Returned FBA Fee was $0.00\n\nPotential Amazon Error");

    datatable.ajax.reload();

    return {
      ...response.data[0],
      weight: productData.product.packaged_weight_ounces,
      componentQty: productData.product.component_count,
      mediaTypes: productData.product_media_types_view.media_types,
      title: productData.product.title,
      asin: productData.product.asin,
      log_type: "ap",
    };
  } catch (err) {
    console.log(err);
    alert(
      "Amazon pricing update failed. This probably wasn't your fault. Try again or contact dev team."
    );
    return;
  }
};
