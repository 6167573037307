import React from "react";
import yomPhoto from "../assets/icons/yom_logo_large.png";

const Dashboard = () => {
  return (
    <img
      src={yomPhoto}
      alt="Family friendly fun"
      style={{ height: "90vh", display: "block", margin: "auto" }}
    />
  );
};

export default Dashboard;
