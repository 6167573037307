import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import inventoryReducer from "./inventoryReducer";
import inventoryFormReducer from "./inventoryFormReducer";
import reportReducer from "./reportReducer";
import productReducer from "./productReducer";
import authReducer from "./authReducer";
import columnSetReducer from "./columnSetReducer";
import notificationReducer from "./notificationReducer";

export default combineReducers({
  inventory: inventoryReducer,
  inventoryForm: inventoryFormReducer,
  reports: reportReducer,
  products: productReducer,
  form: formReducer,
  auth: authReducer,
  columnSets: columnSetReducer,
  notifications: notificationReducer,
});
