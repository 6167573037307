import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  Button,
  Form,
  Dropdown,
  Menu,
  Grid,
  Label,
  Icon,
} from "semantic-ui-react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import dvdlocator from "../apis/dvdlocator";
import { Auth } from "aws-amplify";
import MessageModal from "./MessageModal";

async function checkUser() {
  let user = await Auth.currentAuthenticatedUser();
  return user.attributes;
}
const FeedbackForm = ({ isOpen, toggleModal }) => {
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [fileSizeErr, showFileSize] = useState(false);

  const [inputs, setInputs] = useState({
    sections: [],
    files: [],
    filesSize: 0,
  });

  const datatablesOptions = [
    { key: "pricetool", text: "Pricetool", value: "Pricetool table" },
    {
      key: "amazonPricing",
      text: "Amazon Pricing",
      value: "Amazon Pricing",
    },
    {
      key: "amazonOrders",
      text: "Amazon Orders",
      value: "Amazon Orders",
    },
    { key: "rebates", text: "Rebates", value: "Rebates Table" },
    { key: "house", text: "House", value: "House" },
    {
      key: "houseReceived",
      text: "House Received",
      value: "House Received",
    },
    { key: "damage", text: "Damage", value: "Damage Table" },
    {
      key: "damageResolved",
      text: "Damage Resolved",
      value: "Damage Resolved Table",
    },

    { key: "columnSets", text: "Column Sets", value: "Column Sets" },
  ];

  const toolsOptions = [
    { key: "add", text: "Add", value: "Add Tool" },
    { key: "sub", text: "Sub", value: "Sub Tool" },
    { key: "mark", text: "Mark", value: "Mark Tool" },
    { key: "damageTool", text: "Damage", value: "Damage Tool" },
    { key: "houseTool", text: "House", value: "House Tool" },
    { key: "resolveTool", text: "Resolve", value: "Resolve Tool" },
    { key: "updateOnoTool", text: "Update ONO", value: "Update ONO Tool" },
  ];

  const reportsOptions = [
    {
      key: "dailyInventory",
      text: "Daily Inventory",
      value: "Daily Inventory Report",
    },
    {
      key: "dailyTotals",
      text: "Daily Totals",
      value: "Daily Totals Report",
    },
    {
      key: "weeklyInventory",
      text: "Weekly Inventory",
      value: "Weekly Inventory Report",
    },
    {
      key: "onHandInventory",
      text: "On Hand Inventory",
      value: "On Hand Inventory Report",
    },
    {
      key: "inventoryPayables",
      text: "Inventory Payables",
      value: "Inventory Payables Report",
    },
  ];
  useEffect(() => {
    const getUserAttributes = async () => {
      const info = await checkUser();
      setInputs((prevValues) => ({
        sections: [],
        email: info.email,
        name: info.name,
        files: [],
        filesSize: 0,
      }));
    };
    getUserAttributes();
  }, [isOpen]);

  const sendEmail = (payload) => {
    dvdlocator
      .post("/send-feedback", payload)
      .then((res) => {
        console.log(res.data.message);
        setMsg("Your feedback was sent successfully");
      })
      .catch((err) => {
        console.log(err);
        setMsg(
          "There was some issue sending the feedback. Please try again after some time"
        );
      });
    toggleModal();
    setOpen(true);
    setInputs({ sections: [] });
  };

  const handleSubmit = (values) => {
    let payload = {
      ...inputs,
      ...values,
    };
    payload.sections = inputs.sections.join(", ");
    sendEmail(payload);
  };

  const handleDropdown = (event, data) => {
    setInputs((prevValues) => ({
      ...prevValues,
      sections: [...inputs.sections, data.value[data.value.length - 1]],
    }));
  };

  const setFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size + inputs.filesSize > 8 * Math.pow(10, 6)) {
        showFileSize(true);
      } else {
        showFileSize(false);
      }
      getBase64(file);
      event.target.value = null;
    }
  };

  const removeFile = (file) => {
    setInputs((prevValues) => ({
      ...prevValues,
      files: inputs.files.filter((f) => f.name !== file.name),
      filesSize: inputs.filesSize - file.size,
    }));
    if (inputs.filesSize - file.size < 8 * Math.pow(10, 6)) {
      showFileSize(false);
    }
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let value = reader?.result.split(",")[1];
      setInputs((prevValues) => ({
        ...prevValues,
        files: [
          ...inputs.files,
          { name: file.name, size: file.size, content: value },
        ],
      }));
    };
  };

  const feedbackFormSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  return (
    <>
      <MessageModal open={open} msg={msg} setOpen={setOpen} />
      <Modal open={isOpen} onClose={toggleModal}>
        <ModalHeader>Send Feedback</ModalHeader>
        <Modal.Content>
          <Modal.Description>
            <Formik
              initialValues={{
                title: "",
                description: "",
              }}
              validationSchema={feedbackFormSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Form.Field>
                    <label htmlFor="name">Name</label>
                    <Field name="name" value={inputs?.name} disabled />
                    <ErrorMessage name="name">
                      {(msg) => <div className="text-error">{msg}</div>}
                    </ErrorMessage>
                  </Form.Field>
                  <Form.Field>
                    <label>
                      What section of the Pricetool do you have feedback on?
                    </label>
                    <Menu fluid>
                      <Dropdown
                        placeholder="Datatables"
                        fluid
                        multiple
                        selection
                        search
                        options={datatablesOptions}
                        onChange={handleDropdown}
                      />
                      <Dropdown
                        placeholder="Inventory Tools"
                        fluid
                        multiple
                        selection
                        search
                        options={toolsOptions}
                        onChange={handleDropdown}
                      />
                      <Dropdown
                        placeholder="Reports"
                        fluid
                        multiple
                        selection
                        search
                        options={reportsOptions}
                        onChange={handleDropdown}
                      />
                    </Menu>
                  </Form.Field>

                  <Form.Field>
                    <label htmlFor="title">Title</label>
                    <Field name="title" />
                    <ErrorMessage name="title">
                      {(msg) => <div className="text-error">{msg}</div>}
                    </ErrorMessage>
                  </Form.Field>
                  <Form.Field>
                    <label htmlFor="description">Description</label>
                    <Field
                      name="description"
                      as="textarea"
                      placeholder="Tell us more about the issue..."
                    />
                    <ErrorMessage name="description">
                      {(msg) => <div className="text-error">{msg}</div>}
                    </ErrorMessage>
                  </Form.Field>
                  <Form.Field>
                    <div className="ui input">
                      <label
                        htmlFor="file-upload"
                        className="custom-file-upload"
                      >
                        Attach files
                      </label>
                      <input
                        type="file"
                        id="file-upload"
                        multiple
                        onChange={setFile}
                      ></input>
                    </div>
                    {fileSizeErr && (
                      <div>
                        <p className="text-red">
                          Total file size exceeded, please review attached files
                          and try again.
                        </p>
                      </div>
                    )}
                    <div className="mt-2">
                      {inputs.files.map((file, index) => (
                        <Label key={String(index)}>
                          {file.name.slice(0, 15)}
                          <Icon
                            name="delete"
                            onClick={() => removeFile(file)}
                          />
                        </Label>
                      ))}
                    </div>
                  </Form.Field>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        <Button secondary type="button" onClick={toggleModal}>
                          Cancel
                        </Button>
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          floated="right"
                          disabled={fileSizeErr}
                          primary
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default FeedbackForm;
