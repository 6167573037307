import React from "react";
import { Segment, List, Header } from "semantic-ui-react";

const ActiveFilters = ({ filters }) => {
  return (
    <Segment>
      <Header color="red">Active Filters</Header>
      <List>
        {Object.entries(filters).map((entry) => {
          console.log(entry);
          return (
            <List.Item css={{ color: "red" }} key={entry[0]}>
              {entry[0]}:{String(entry[1])}
            </List.Item>
          );
        })}
      </List>
    </Segment>
  );
};

export default ActiveFilters;
