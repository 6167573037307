import React, { useEffect, useState } from "react";
import {
  Segment,
  Table,
  TableHeader,
  TableRow,
  TableBody,
  TableCell,
  TableHeaderCell,
  Button,
} from "semantic-ui-react";
import dvdlocator from "../../../apis/dvdlocator";
import LoadingBox from "../LoadingBox";

const BillingDateTable = ({ report }) => {
  const {
    todayRetail,
    todayDistributor,
    zeroToSixtyRetail,
    zeroToSixtyDistributor,
    laterThanSixtyRetail,
    laterThanSixtyDistributor,
  } = { ...report };

  let zeroToSixtyTotalProducts =
    zeroToSixtyRetail.total_products + zeroToSixtyDistributor.total_products;
  let zeroToSixtyUniqueProducts =
    zeroToSixtyRetail.unique_products + zeroToSixtyDistributor.unique_products;

  let laterThanSixtyTotalProducts =
    laterThanSixtyRetail.total_products +
    laterThanSixtyDistributor.total_products;
  let laterThanSixtyUniqueProducts =
    laterThanSixtyRetail.unique_products +
    laterThanSixtyDistributor.unique_products;

  const formatTooltip = (displayNum) => {
    if (!displayNum) return null;
    const [total, uniqueUpcs] = displayNum.split("-");
    return `${total} Total Units\n${uniqueUpcs} Unique UPCs`;
  };

  const commaSeparatedFormat = Intl.NumberFormat("en-US");

  const getDisplayNum = (totalCount, uniqueCount) =>
    `${commaSeparatedFormat.format(totalCount)}-${commaSeparatedFormat.format(
      uniqueCount
    )}`;

  const todayDisplayNum = getDisplayNum(
    report.todayRetail.total_products + report.todayDistributor.total_products,
    report.todayRetail.unique_products + report.todayDistributor.unique_products
  );

  const zeroToSixtyDisplayNum = getDisplayNum(
    zeroToSixtyTotalProducts,
    zeroToSixtyUniqueProducts
  );

  const laterThanSixtyDisplayNum = getDisplayNum(
    laterThanSixtyTotalProducts,
    laterThanSixtyUniqueProducts
  );

  const allTimeDisplayNum = getDisplayNum(
    zeroToSixtyTotalProducts + laterThanSixtyTotalProducts,
    zeroToSixtyUniqueProducts + laterThanSixtyUniqueProducts
  );

  const onOrderTooltip =
    "ONO (On Order) Inventory that has been ordered through supplier, and has not yet been delivered to our warehouse";

  const totalToolTip =
    "The total dollar amount we expect to pay for inventory ordered";
  const outstandingToolTip =
    "The total outstanding dollar amount for product ordered, but not received";
  const paidToolTip =
    "The dollar amount already paid for inventory ordered, but not received";

  return (
    <Table padded>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Date Ordered</TableHeaderCell>
          <TableHeaderCell>Total Units/Unique UPCs</TableHeaderCell>
          <TableHeaderCell title={paidToolTip}>Paid</TableHeaderCell>
          <TableHeaderCell title={outstandingToolTip}>
            Outstanding
          </TableHeaderCell>
          <TableHeaderCell title={totalToolTip}>Total</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>
            <span title="ONO (On Order) Inventory that has been ordered through supplier for the current business day, and has not yet been delivered to our warehouse.">
              ONO Today
            </span>
          </TableCell>
          <TableCell>
            <span title={formatTooltip(todayDisplayNum)}>
              {todayDisplayNum}
            </span>
          </TableCell>
          <TableCell>
            <span title="">
              ${commaSeparatedFormat.format(todayRetail.total_price)}
            </span>
          </TableCell>
          <TableCell>
            <span>
              ${commaSeparatedFormat.format(todayDistributor.total_price)}
            </span>
          </TableCell>
          <TableCell>
            <span>
              $
              {commaSeparatedFormat.format(
                todayRetail.total_price + todayDistributor.total_price
              )}
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span title="ONO (On Order) Inventory that has been ordered through supplier within the last 60 days, and has not yet been delivered to our warehouse.">
              ONO 0-60 Days
            </span>
          </TableCell>
          <TableCell>
            <span title={formatTooltip(zeroToSixtyDisplayNum)}>
              {zeroToSixtyDisplayNum}
            </span>
          </TableCell>
          <TableCell>
            <span title="">
              ${commaSeparatedFormat.format(zeroToSixtyRetail.total_price)}
            </span>
          </TableCell>
          <TableCell>
            <span title="">
              ${commaSeparatedFormat.format(zeroToSixtyDistributor.total_price)}
            </span>
          </TableCell>
          <TableCell>
            <span title="">
              $
              {commaSeparatedFormat.format(
                zeroToSixtyRetail.total_price +
                  zeroToSixtyDistributor.total_price
              )}
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span title="ONO (On Order) Inventory that has been ordered through supplier more than 60 days ago, and has not yet been delivered to our warehouse.">
              ONO 60+ Days{" "}
            </span>
          </TableCell>
          <TableCell>
            <span title={formatTooltip(laterThanSixtyDisplayNum)}>
              {laterThanSixtyDisplayNum}
            </span>
          </TableCell>
          <TableCell>
            <span title="">
              ${commaSeparatedFormat.format(laterThanSixtyRetail.total_price)}
            </span>
          </TableCell>
          <TableCell>
            <span title="">
              $
              {commaSeparatedFormat.format(
                laterThanSixtyDistributor.total_price
              )}
            </span>
          </TableCell>
          <TableCell>
            <span title="">
              $
              {commaSeparatedFormat.format(
                laterThanSixtyRetail.total_price +
                  laterThanSixtyDistributor.total_price
              )}
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span title={onOrderTooltip}>ONO Total Open Orders</span>
          </TableCell>
          <TableCell>
            <span title={formatTooltip(allTimeDisplayNum)}>
              {allTimeDisplayNum}
            </span>
          </TableCell>
          <TableCell>
            <span title="">
              $
              {commaSeparatedFormat.format(
                zeroToSixtyRetail.total_price + laterThanSixtyRetail.total_price
              )}
            </span>
          </TableCell>
          <TableCell>
            <span title="">
              $
              {commaSeparatedFormat.format(
                zeroToSixtyDistributor.total_price +
                  laterThanSixtyDistributor.total_price
              )}
            </span>
          </TableCell>
          <TableCell>
            <span title="">
              $
              {commaSeparatedFormat.format(
                zeroToSixtyRetail.total_price +
                  laterThanSixtyRetail.total_price +
                  zeroToSixtyDistributor.total_price +
                  laterThanSixtyDistributor.total_price
              )}
            </span>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const AnticipatedBillingReport = () => {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({
    todayRetail: {},
    todayDistributor: {},
    zeroToSixtyRetail: {},
    zeroToSixtyDistributor: {},
    laterThanSixtyRetail: {},
    laterThanSixtyDistributor: {},
  });

  const getAnticipatedBilling = () => {
    setLoading(true);
    dvdlocator
      .get("inventory/house/anticipated-billing")
      .then((res) => {
        setReport(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAnticipatedBilling();
  }, []);

  return (
    <Segment className="grid-box">
      <div style={{ padding: "24px 0", display: "flex", alignItems: "center" }}>
        <h2 style={{ margin: "0 24px 0 0" }}>Inventory Payables</h2>

        <Button
          type="button"
          color="blue"
          icon="sync"
          title="Refresh data."
          onClick={() => getAnticipatedBilling()}
        />
      </div>

      {loading ? <LoadingBox /> : <BillingDateTable report={report} />}
    </Segment>
  );
};

export default AnticipatedBillingReport;
