import React, { useState } from "react";
import { Button, Form, Icon, Message, Segment } from "semantic-ui-react";
import { ErrorMessage, Formik } from "formik";
import axios from "../../../../apis/dvdlocator";
import * as Yup from "yup";

const DailyFbaCsvForm = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null); // 'success', 'error', or null
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (values, actions) => {
    // Disable the button and reset status
    setLoading(true);
    setStatus(null);

    try {
      await axios.post("/shipments/daily-fba-report", {
        fbaShipmentCsv: values.csvString,
      });

      setStatus("success");
    } catch (err) {
      setStatus("error");
      setErrorMessage(JSON.stringify(err));
    } finally {
      setLoading(false);
    }
  };

  const isQuantityPositive = (csvString) => {
    if (!csvString) return false;

    const records = csvString.split(/\r?\n/);

    for (const record of records) {
      const [, qty] = record.split(/[,\t]/);
      if (qty < 0) return false;
    }
    return true;
  };

  const isValidFormat = (string) => {
    if (!string) return false;

    // Split into lines
    const lines = string.split(/\r?\n/);

    // Check each line
    for (const line of lines) {
      // Split line by comma or tab
      const values = line.split(/[,\t]/);

      // Check if there are at least 2 values
      if (values.length < 2) {
        return false;
      }

      // Check second value is a number
      const qty = values[1].trim();
      if (isNaN(Number(qty))) {
        return false;
      }

      // Check if values don't contain the delimiters
      // (except for leading/trailing whitespace)
      for (const value of values) {
        if (/,|\t/.test(value.trim())) {
          return false;
        }
      }
    }

    return true;
  };

  const validationSchema = Yup.object({
    csvString: Yup.string()
      .test(
        "negativeIntCheck",
        "Cannot enter negative quantities.",
        (value, meta) => {
          if (!value) return true;
          return isQuantityPositive(value);
        }
      )
      .test(
        "validFormat",
        "Please use a valid CSV or tab-delimited format. Each row should contain a sku and numeric quantity.",
        (value, meta) => {
          return isValidFormat(value);
        }
      )
      .required("Please enter input."),
  });

  return (
    <Segment className="grid-box">
      <div
        style={{
          width: "800px",
          display: "flex",
          gap: "20px",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <h2 style={{ marginBottom: 0 }}>
          Daily FBA Shipments Upload{" "}
          <Icon
            name="info circle"
            size="tiny"
            className="light-hover"
            color="grey"
            title={`Upload/paste in FBA Shipment quantities for the day in CSV or tab-delimited format.\n\nExample input:\n\nB000062XG0,10\nB0007VY4H2,8\n000799464120,7\n000799464625,1\n000799469101,20\n\nOR\n\nB000062XG0\t10\nB0007VY4H2\t8\n000799464120\t7\n000799464625\t1\n000799469101\t20`}
          />
        </h2>
      </div>
      <Formik
        initialValues={{ csvString: "" }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <label className="ui label">FBA Shipment CSV (UPC,QTY)</label>
            <ErrorMessage name="csvString">
              {(msg) => <div style={{ color: "red" }}>{msg}</div>}
            </ErrorMessage>
            <Form.TextArea
              rows={20}
              placeholder="Paste CSV string here..."
              name="csvString"
              value={values.csvString}
              onChange={(e) => {
                const cleanedValue = e.target.value
                  .replace(/\n\n+/g, "\n")
                  .replace(/\n+$/, "");
                setFieldValue("csvString", cleanedValue);
              }}
              error={touched.csvString && !!errors.csvString}
            />
            <Button
              primary
              type="submit"
              loading={loading}
              disabled={loading || status === "success"}
            >
              Submit
            </Button>
            {status && (
              <Message
                positive={status === "success"}
                negative={status === "error"}
                content={
                  status === "success" ? "Submission successful!" : errorMessage
                }
              />
            )}
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

export default DailyFbaCsvForm;
