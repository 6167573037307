import React, { useRef } from "react";

import { Header, List, Popup, Image } from "semantic-ui-react";

const BuyboxMarkupLegend = () => {
  const ref = useRef(null);

  const listItemStyle = {
    fontSize: "0.9rem", // Adjust the font size as needed
    display: "flex",
    alignItems: "center",
  };
  const iconStyle = {
    marginRight: "8px",
    maxWidth: "20px",
  };

  return (
    <Popup
      closeOnPortalMouseLeave
      style={{ width: "400px" }}
      flowing
      ref={ref}
      trigger={<p>Buybox Markup</p>}
    >
      <Header as="h2" id="buybox-markup-legend-tooltip">
        BuyBox Markup Legend
      </Header>

      <Header as="h3">Statuses:</Header>
      <List bulleted relaxed>
        <List.Item style={listItemStyle}>
          <Image src="https://i.ibb.co/Nsdhbrz/1.png" style={iconStyle} />
          <div>
            <b>Missing</b> - Quantity of required pieces that weren't valid for
            calculation
          </div>
        </List.Item>
        <List.Item style={listItemStyle}>
          <Image
            src="https://i.ibb.co/m9Dj2FY/Green-Arrow.png"
            style={iconStyle}
          />
          <div>
            <b>Premium</b> - Percentage the item is marked up compared to
            equivalents/children
          </div>
        </List.Item>
        <List.Item style={listItemStyle}>
          <Image
            src="https://i.ibb.co/pXbvMqQ/Red-Arrow.png"
            style={iconStyle}
          />
          <div>
            <b>Discount</b> - Percentage the item is discounted compared to
            equivalents/children
          </div>
        </List.Item>
        <List.Item style={listItemStyle}>
          <b style={{ fontSize: 30, paddingRight: "6px", fontWeight: "bold" }}>
            &#8734;
          </b>
          <div>
            <b>Ineligible with options</b> - Item is ineligible for calculation
            but has eligible equivalents/children
          </div>
        </List.Item>
        <List.Item style={listItemStyle}>
          <span
            style={{
              color: "purple",
              fontSize: 12,
              paddingRight: "6px",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              display: "inline-block",
            }}
          >
            N/A
          </span>
          <div>
            <b>Not applicable</b> - Item is ineligible for calculation and has
            no eligible equivalents/children
          </div>
        </List.Item>

        <List.Item style={listItemStyle}>
          <b>PAR</b> - Equal to the cost-basis for calculation
        </List.Item>

        <List.Item style={listItemStyle}>
          <b>Misthread</b> - Different values calculated for two or more
          families
        </List.Item>
      </List>
    </Popup>
  );
};

export default BuyboxMarkupLegend;
