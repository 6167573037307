import React from "react";
import dvdlocator from "../../../apis/dvdlocator";
import { Formik, ErrorMessage } from "formik";
import { Form, Dropdown, Button } from "semantic-ui-react";
import * as Yup from "yup";
import { sanitizeObjectStrings } from "../../utilities/sanitizeObjectStrings";
import { getTitle } from "../titleHandling";

const dropdownOptions = [
  {
    key: "Return",
    text: "Return",
    value: "Return",
  },
  {
    key: "Repaired",
    text: "Repaired",
    value: "Repaired",
  },
];

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

const ResolveInputs = ({ addProduct }) => {
  const bulkAdd = async (category) => {
    if (!category) return alert("Error: No resolution category selected.");
    const input = prompt("Input comma/newline separated UPCs: ");
    if (!input) return;
    const upcs = input.replace(/\r?\n/g, ",").replace(/\r/, ",").split(",");
    if (upcs.length < 1) return;

    const values = { category, UPC: upcs.map((upc) => upc.trim()) };
    await handleSubmit(values);
  };

  const validationSchema = Yup.object({
    UPC: Yup.string().required("Please enter a UPC."),
    category: Yup.string().required("Please select the resolution category."),
  });

  const handleSubmit = async (values, actions) => {
    var upcTitle = "NEEDS TITLE NEW";
    let upcList = Array.isArray(values.UPC)
      ? [...values.UPC]
      : Array(values.UPC);

    await dvdlocator
      .post("/product-titles", values)
      .then((response) => response.data)
      .then((data) => {
        for (let upc = 0; upc < upcList.length; upc++) {
          const record = {
            UPC: upcList[upc],
            title: upcTitle,
            category: values.category,
          };

          if (upcList[upc] in data) {
            const title = data[upcList[upc]];
            if (title) {
              record.title = getTitle(title);
            } else record.title = upcTitle;
          } else record.title = upcTitle;

          submitData(record, actions);
        }
      });
  };

  const submitData = (values, actions) => {
    values.UPC = values.UPC.trim();
    addProduct(values);

    // Exit early for bulk add
    if (!actions) return;
    actions.setFieldValue("UPC", "", false);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ UPC: "", category: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        values = sanitizeObjectStrings(values);
        await handleSubmit(values, actions);
      }}
    >
      {(formikProps) => (
        <Form widths="equal" onSubmit={formikProps.handleSubmit}>
          {/* Inputs */}
          <Form.Group>
            <Form.Field>
              <ErrorMessage name="UPC" component={RedErrorText} />
              <label className="ui label">UPC</label>
              <input
                name="UPC"
                onChange={formikProps.handleChange}
                value={formikProps.values["UPC"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="category" component={RedErrorText} />
              <label className="ui label">Resolution Category</label>
              <Dropdown
                name="category"
                placeholder="Category"
                onChange={(e, data) => {
                  formikProps.setFieldTouched("category", true, false);
                  formikProps.setFieldValue("category", data.value);
                }}
                value={formikProps.values["category"]}
                fluid
                selection
                disabled={formikProps.isSubmitting}
                options={dropdownOptions}
              />
            </Form.Field>
          </Form.Group>
          {/* End Inputs */}

          {/* Buttons */}
          <Form.Group inline>
            <Button type="submit" inverted color="blue">
              Push to Queue
            </Button>
            <Button
              type="button"
              onClick={async () =>
                await bulkAdd(formikProps.values["category"])
              }
            >
              Bulk Push
            </Button>
          </Form.Group>
          {/* End Buttons */}
        </Form>
      )}
    </Formik>
  );
};

export default ResolveInputs;
