import React from "react";
import { Formik, ErrorMessage } from "formik";
import { sanitizeObjectStrings } from "../../../utilities/sanitizeObjectStrings";

import { Form, Button, Dropdown } from "semantic-ui-react";
import * as Yup from "yup";

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

const DistributorSupplierForm = ({ handleSubmit, bulkAdd, formDisabled }) => {
  const validationSchema = Yup.object({
    UPC: Yup.string().required("Please enter a UPC."),
    quantity: Yup.number()
      .moreThan(-1, "Cannot be negative value.")
      .required("Please enter a quantity."),
    supplier: Yup.string().required("Please select a supplier."),
  });

  const dropdownOptions = [
    {
      key: "Alliance",
      text: "Alliance",
      value: "Alliance",
    },
    {
      key: "Dealsareus",
      text: "Dealsareus",
      value: "Dealsareus",
    },
    {
      key: "Ingram",
      text: "Ingram",
      value: "Ingram",
    },
    {
      key: "Discount Entertainment",
      text: "Discount Entertainment",
      value: "Discount Entertainment",
    },
    {
      key: "Mila Video",
      text: "Mila Video",
      value: "Mila Video",
    },
  ];

  return (
    <Formik
      initialValues={{
        UPC: "",
        supplier: "",
        quantity: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        values = sanitizeObjectStrings(values);
        await handleSubmit(values, actions);
        actions.setFieldValue("quantity", "", false);
      }}
    >
      {(formikProps) => (
        <Form widths="equal" onSubmit={formikProps.handleSubmit}>
          {/* Inputs */}
          <Form.Group>
            <Form.Field>
              <ErrorMessage name="UPC" component={RedErrorText} />
              <label className="ui label">UPC</label>
              <input
                name="UPC"
                onChange={formikProps.handleChange}
                value={formikProps.values["UPC"]}
                disabled={formDisabled}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="supplier" component={RedErrorText} />
              <label className="ui label">Supplier</label>

              <Dropdown
                name="supplier"
                disabled={formDisabled}
                placeholder="Supplier"
                onChange={(e, data) => {
                  // setting formik state
                  formikProps.setFieldValue("supplier", data.value);
                  formikProps.setFieldTouched("supplier", true, false);
                }}
                value={formikProps.values["supplier"]}
                fluid
                selection
                options={dropdownOptions}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="quantity" component={RedErrorText} />
              <label className="ui label">Good Quantity</label>
              <input
                name="quantity"
                title="Inventory that has passed quality control and is saleable."
                onChange={formikProps.handleChange}
                value={formikProps.values["quantity"]}
                disabled={formDisabled}
              />
            </Form.Field>
          </Form.Group>
          {/* End Inputs */}

          {/* Buttons */}
          <Form.Group inline>
            <Button type="submit" inverted color="blue" disabled={formDisabled}>
              Push to Queue
            </Button>
            <Button
              disabled={formDisabled}
              type="button"
              onClick={() => {
                formikProps.validateForm().then(async (errors) => {
                  formikProps.setFieldTouched("supplier");
                  formikProps.setFieldTouched("quantity");
                  formikProps.setFieldTouched("UPC", false);
                  if (errors["supplier"] || errors["quantity"]) return;

                  const values = sanitizeObjectStrings(formikProps.values);
                  await bulkAdd(values);
                });
              }}
            >
              Bulk Push
            </Button>
          </Form.Group>
          {/* End Buttons */}
        </Form>
      )}
    </Formik>
  );
};

export default DistributorSupplierForm;
