export function setTitle(records, record, data) {
  let upc = "";
  const reqUpc = records[record].UPC;

  if (reqUpc in data) {
    //get upc to rename it as original upc
    upc = data[reqUpc].oldKey ? data[reqUpc].oldKey : reqUpc;
    //get title to set
    const title = data[reqUpc].title ? data[reqUpc].title : data[reqUpc];
    if (title) {
      records[record].title = getTitle(title);
    } else records[record].title = "";
  } else records[record].title = "NEEDS TITLE NEW";

  records[record].UPC = upc;
  return records;
}

export function getTitle(title) {
  const conditions = ["img", "href"];
  if (conditions.some((cond) => title.includes(cond))) {
    return title;
  }
  return title.substring(0, 45);
}
