import { Auth } from "aws-amplify";
import _ from "lodash";
import { resolveInventory, damageInventory } from "./damageActions";
import dvdlocator from "../../apis/dvdlocator";
import {
  FETCH_SESSION_LOG,
  ADD_INVENTORY,
  SUB_INVENTORY,
  MARK_INVENTORY,
  HOUSE_INVENTORY,
  SIGN_OUT,
  ADD_PRODUCT,
  ADD_PRODUCTS,
  DELETE_PRODUCT,
  GET_USER_TOKEN,
  FETCH_COLUMN_SETS,
  FETCH_TOTALS,
  FETCH_WEEKLY_INVENTORY,
  ONO_INVENTORY,
  SUPPLIER_INVENTORY,
} from "./types";
import { setSubRefreshQuantity } from "./subActions";

export { resolveInventory, damageInventory };

export const addProduct = (formValues) => {
  return {
    type: ADD_PRODUCT,
    payload: formValues,
  };
};

export const addProducts = (products) => {
  return {
    type: ADD_PRODUCTS,
    payload: products,
  };
};

export const deleteProduct = (id) => {
  return {
    type: DELETE_PRODUCT,
    payload: id,
  };
};

export const fetchInventoryLog = (date) => async (dispatch) => {
  let uri = "/nosql/session-log";
  if (date) uri = uri + `?date=${date}`;
  const response = await dvdlocator.get(uri);

  return dispatch({
    type: FETCH_SESSION_LOG,
    payload: response.data,
  });
};

export const fetchWeeklyInventoryLog = () => async (dispatch) => {
  const response = await dvdlocator.get("/nosql/weekly-session-log");

  return dispatch({
    type: FETCH_WEEKLY_INVENTORY,
    payload: response.data,
  });
};

export const fetchTotals = () => async (dispatch) => {
  const response = await dvdlocator.get("/totals");

  return dispatch({
    type: FETCH_TOTALS,
    payload: response.data,
  });
};

export const updateONO = () => async (dispatch, getState) => {
  const products = _.without(getState().products, undefined);
  console.log(products);

  const requestBody = { orders: products };

  const response = await dvdlocator.post("/inventory/update-ono", requestBody);

  const body = response.data;
  const { userId } = getState().auth;

  body.forEach((element) => {
    element.userId = userId;
  });

  const sessionLogBody = {
    items: body,
  };

  const newSessionLog = {};
  newSessionLog.items = sessionLogBody.items.filter((row) => row);
  await dvdlocator.put("/nosql/session-log", newSessionLog);

  return dispatch({
    type: ONO_INVENTORY,
    payload: body,
  });
};

export const supplierData = () => async (dispatch, getState) => {
  const products = _.without(getState().products, undefined);
  console.log(products);

  const requestBody = { orders: products };

  const response = await dvdlocator.post(
    "/inventory/supplier-data",
    requestBody
  );

  const body = response.data;
  const { userId } = getState().auth;

  body.forEach((element) => {
    element.userId = userId;
  });

  const sessionLogBody = {
    items: body,
  };

  const newSessionLog = {};
  newSessionLog.items = sessionLogBody.items.filter((row) => row);
  await dvdlocator.put("/nosql/session-log", newSessionLog);

  return dispatch({
    type: SUPPLIER_INVENTORY,
    payload: body,
  });
};

export const addInventory = () => async (dispatch, getState) => {
  const products = _.without(getState().products, undefined);
  console.log(products);

  const requestBody = { products };

  const response = await dvdlocator.post("/inventory/add", requestBody);

  const body = response.data;
  const { userId } = getState().auth;

  body.forEach((element) => {
    element.userId = userId;
  });

  const sessionLogBody = {
    items: body,
  };

  const newSessionLog = {};
  newSessionLog.items = sessionLogBody.items.filter((row) => row);
  await dvdlocator.put("/nosql/session-log", newSessionLog);

  return dispatch({
    type: ADD_INVENTORY,
    payload: body,
  });
};

export const subInventory = () => async (dispatch, getState) => {
  const products = _.without(getState().products, undefined);
  console.log(products);

  const requestBody = { products };

  const response = await dvdlocator.post("/inventory/sub", requestBody);

  dispatch(setSubRefreshQuantity(true));

  const body = response.data;
  const { userId } = getState().auth;

  body.forEach((element) => {
    element.userId = userId;
  });

  const sessionLogBody = {
    items: body,
  };
  const newSessionLog = {};
  newSessionLog.items = sessionLogBody.items.filter((row) => row);

  await dvdlocator.put("/nosql/session-log", newSessionLog);

  return dispatch({
    type: SUB_INVENTORY,
    payload: body,
  });
};

export const markInventory = () => async (dispatch, getState) => {
  // remove undefineds
  const products = _.without(getState().products, undefined);

  // make a list of upcs from state
  const upcs = _.map(products, (product) => {
    return product.UPC;
  });

  const markers = _.map(products, (product) => {
    if (product.marker) {
      return product.marker;
    }
    return "";
  });

  const marking_columns = _.map(products, (product) => {
    return product.markingColumn;
  });

  const requestBody = { upcs, markers, marking_columns };

  const response = await dvdlocator.post("/inventory/mark", requestBody);

  const body = response.data;
  const { userId } = getState().auth;

  body.forEach((element) => {
    element.userId = userId;
  });

  const sessionLogBody = {
    items: body,
  };

  const newSessionLog = {};
  newSessionLog.items = sessionLogBody.items.filter((row) => row);

  await dvdlocator.put("/nosql/session-log", newSessionLog);

  return dispatch({
    type: MARK_INVENTORY,
    payload: body,
  });
};

export const houseInventory = () => async (dispatch, getState) => {
  // remove undefineds
  const products = _.without(getState().products, undefined);

  // make a list of upcs from state and remove undefineds
  const upcs = _.map(products, (product) => {
    return product.UPC;
  });

  const override_order_nums = _.map(products, (product) => {
    if (!product.override) {
      return false;
    }
    return product.override;
  });
  const order_nums = _.map(products, (product) => {
    return product.orderNumber;
  });
  const supplier_order_nums = _.map(products, (product) => {
    return product.supplierOrderNumber;
  });
  const suppliers = _.map(products, (product) => {
    return product.supplier;
  });
  const ordered_by = _.map(products, (product) => {
    return product.orderedBy;
  });
  const prices = _.map(products, (product) => {
    return product.price;
  });
  const quantities = _.map(products, (product) => {
    return product.amount;
  });

  const requestBody = {
    upcs,
    override_order_nums,
    order_nums,
    supplier_order_nums,
    suppliers,
    ordered_by,
    prices,
    quantities,
  };

  const response = await dvdlocator.post("/inventory/house", requestBody);

  const body = response.data;
  const { userId } = getState().auth;

  body.forEach((element) => {
    element.userId = userId;
  });

  const sessionLogBody = {
    items: body,
  };

  const newSessionLog = {};
  newSessionLog.items = sessionLogBody.items.filter((row) => row);
  await dvdlocator.put("/nosql/session-log", newSessionLog);

  return dispatch({
    type: HOUSE_INVENTORY,
    payload: body,
  });
};

// loads their email (without domain) into redux store
export const getUserToken = () => async (dispatch) => {
  const data = await Auth.currentSession();

  dispatch({
    type: GET_USER_TOKEN,
    payload: {
      idToken: data.idToken.jwtToken,
      userId: data.idToken.payload.email.split("@")[0],
    },
  });

  // fetch column sets once userId is assigned
  // dispatch(fetchColumnSets());
};

export const signOut = (formValues) => async (dispatch) => {
  try {
    await Auth.signOut();
    dispatch({
      type: SIGN_OUT,
    });
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

export const fetchColumnSets = (userId) => async (dispatch, getState) => {
  const response = await dvdlocator.get("/nosql/column-sets", {
    params: { username: userId },
  });

  dispatch({
    type: FETCH_COLUMN_SETS,
    payload: response.data.Items[0],
  });
};

export const putColumnSets = (newItem) => async (dispatch, getState) => {
  const { userId } = getState().auth;
  const { columnSets } = getState();

  const requestBody = {
    items: [
      {
        username: userId,
        columns: { ...columnSets[userId], ...newItem },
      },
    ],
  };

  await dvdlocator.put("/nosql/column-sets", requestBody);

  // update redux store
  dispatch({
    type: FETCH_COLUMN_SETS,
    payload: requestBody.items[0],
  });
};
