import {
  ADD_INVENTORY,
  SUB_INVENTORY,
  MARK_INVENTORY,
  DAMAGE_INVENTORY,
  RESOLVED_INVENTORY,
  HOUSE_INVENTORY,
  INIT_INVENTORY_REQUEST,
  SET_SUPPLIER_TYPE,
  ONO_INVENTORY,
  SUPPLIER_INVENTORY,
} from "../actions/types";

const initialState = {
  items: [],
  loading: false,
  supplierType: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUPPLIER_TYPE:
      return { ...state, supplierType: action.payload };
    case INIT_INVENTORY_REQUEST:
      return { ...state, loading: true };
    case ONO_INVENTORY:
      return {
        ...state,
        loading: false,
        items: [...action.payload, ...state.items],
      };
    case SUPPLIER_INVENTORY:
      return {
        ...state,
        loading: false,
        items: [...action.payload, ...state.items],
      };
    case ADD_INVENTORY:
      return {
        ...state,
        loading: false,
        items: [...action.payload, ...state.items],
      };
    case SUB_INVENTORY:
      return {
        ...state,
        loading: false,
        items: [...action.payload, ...state.items],
      };
    case MARK_INVENTORY:
      return {
        ...state,
        loading: false,
        items: [...action.payload, ...state.items],
      };
    case DAMAGE_INVENTORY:
      return {
        ...state,
        loading: false,
        items: [...action.payload, ...state.items],
      };
    case RESOLVED_INVENTORY:
      return {
        ...state,
        loading: false,
        items: [...action.payload, ...state.items],
      };
    case HOUSE_INVENTORY:
      return {
        ...state,
        loading: false,
        items: [...action.payload, ...state.items],
      };
    default:
      return state;
  }
};
