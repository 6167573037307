import React, { useState } from "react";
import {
  Modal,
  Button,
  Checkbox,
  Form,
  Dimmer,
  Loader,
  Segment,
} from "semantic-ui-react";
import axios from "../../../../../../apis/dvdlocator";
import { useFormik } from "formik";

const ClearSupplierDataModal = ({ closeModal }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [resultOpen, setResultOpen] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [resultSuccess, setResultSuccess] = useState(false);
  const formik = useFormik({
    initialValues: {
      suppliers: [],
    },
    validate: (values) => {
      const errors = {};
      if (values.suppliers.length === 0) {
        errors.suppliers = "Please select at least one supplier";
      }
      return errors;
    },
    onSubmit: (values) => {
      setConfirmationOpen(true);
    },
  });

  const handleConfirmation = async () => {
    setProcessing(true);
    try {
      await axios.post(`/supplier-data-clear`, {
        suppliers: formik.values.suppliers,
      });
      setResultMessage("Supplier data cleared successfully!");
      setResultSuccess(true);
    } catch (err) {
      setResultMessage(`Error clearing supplier data: ${err.message}`);
      setResultSuccess(false);
    } finally {
      setProcessing(false);
      setConfirmationOpen(false);
      setResultOpen(true);
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    closeModal();
  };

  const handleResultClose = () => {
    setResultOpen(false);
    closeModal();
  };

  const handleChange = (supplier, checked) => {
    if (checked === false) {
      formik.setFieldValue(
        "suppliers",
        formik.values.suppliers.filter((name) => name !== supplier)
      );
    } else {
      formik.setFieldValue("suppliers", [...formik.values.suppliers, supplier]);
    }
  };

  return (
    <Modal open onClose={closeModal} size="tiny">
      <Modal.Header>Clear Supplier Data</Modal.Header>
      <Modal.Content>
        <p>
          Select one or more suppliers whose prices and inventory you'd like to
          clear.
        </p>

        <Form onSubmit={formik.handleSubmit}>
          <Form.Field>
            <Checkbox
              label="Mila"
              name="suppliers"
              value="mila"
              checked={formik.values.suppliers.includes("mila")}
              onChange={(e, data) => {
                handleChange(data.value, data.checked);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Discount Entertainment"
              name="suppliers"
              value="discount_entertainment"
              checked={formik.values.suppliers.includes(
                "discount_entertainment"
              )}
              onChange={(e, data) => {
                handleChange(data.value, data.checked);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Gruv"
              name="suppliers"
              value="gruv"
              checked={formik.values.suppliers.includes("gruv")}
              onChange={(e, data) => {
                handleChange(data.value, data.checked);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Wildcard1"
              name="suppliers"
              value="wildcard1"
              checked={formik.values.suppliers.includes("wildcard1")}
              onChange={(e, data) => {
                handleChange(data.value, data.checked);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Wildcard2"
              name="suppliers"
              value="wildcard2"
              checked={formik.values.suppliers.includes("wildcard2")}
              onChange={(e, data) => {
                handleChange(data.value, data.checked);
              }}
            />
          </Form.Field>
          {formik.errors.suppliers && (
            <div style={{ color: "red" }}>{formik.errors.suppliers}</div>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          type="submit"
          color="red"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
        >
          Clear
        </Button>
      </Modal.Actions>

      {/* Confirmation Modal */}
      <Modal open={confirmationOpen} size="tiny">
        {processing ? (
          <Segment
            style={{
              minHeight: "125px",
            }}
          >
            <Dimmer active inverted>
              <Loader
                inverted
                content={
                  <>
                    <p>Processing Request</p>
                    <p>This may take a moment</p>
                  </>
                }
              />
            </Dimmer>
          </Segment>
        ) : (
          <>
            <Modal.Header>Confirm Clear Supplier Data</Modal.Header>
            <Modal.Content>
              <p>
                Are you sure you want to clear data for the selected suppliers?
              </p>
              <ul>
                {formik.values.suppliers.map((supplier) => (
                  <li key={supplier}>{supplier}</li>
                ))}
              </ul>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setConfirmationOpen(false)}>Cancel</Button>
              <Button color="red" onClick={handleConfirmation}>
                Confirm
              </Button>
            </Modal.Actions>
          </>
        )}
      </Modal>

      {/* Result Modal */}
      <Modal open={resultOpen} size="tiny">
        <Modal.Header>{resultSuccess ? "Success" : "Error"}</Modal.Header>
        <Modal.Content>
          <p>{resultMessage}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color={resultSuccess ? "green" : "red"}
            onClick={handleResultClose}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </Modal>
  );
};

export default ClearSupplierDataModal;
