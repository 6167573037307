import React, { useState } from "react";
import { Modal, Button, Table, Form, Message } from "semantic-ui-react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../../../../../apis/dvdlocator";

export const AdjustInventory = ({
  datatable,
  open,
  close,
  productsInitial,
  userId,
}) => {
  const [products, setProducts] = useState(productsInitial);
  const [success, setSuccess] = useState(null);
  const invalidRows = products.some((product) => !product.product.upc);
  const [requestError, setRequestError] = useState(null);
  const handleClose = () => {
    if (success) {
      datatable.ajax.reload();
    }

    close();
  };
  const schema = yup.object().shape(
    products.reduce((acc, product) => {
      acc[product.product.upc] = yup
        .number()
        .typeError("Please enter a number.")
        .required()
        .moreThan(-1, "Can't use negative values.")
        .not([product.product.inv], "No adjustment was made.");
      return acc;
    }, {})
  );

  const formik = useFormik({
    initialValues: products.reduce((acc, product) => {
      acc[product.product.upc] = product.product.inv;
      return acc;
    }, {}),
    validationSchema: schema,

    onSubmit: async (values) => {
      setRequestError(null);
      setSuccess(null);
      const adjustments = Object.entries(values).map(
        ([upc, newInventoryValue]) => ({
          upc,
          newInventoryValue,
        })
      );

      const body = {
        userId,
        adjustments,
      };

      try {
        await axios.post("/inventory/adjustments", body);

        setSuccess(true);

        setProducts((prevProducts) =>
          prevProducts.map((product) => {
            // Finding the adjustment for this upc
            const adjustmentRow = adjustments.find(
              (p) => p.upc === product.product.upc
            );
            // Updating the inv value to the new value
            return {
              ...product,
              product: {
                ...product.product,
                inv: adjustmentRow.newInventoryValue,
              },
            };
          })
        );
      } catch (err) {
        setRequestError(err);
      }
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      {success && <Message success header={"Submission was successful!"} />}
      {requestError && (
        <Message
          error
          header={"Submission failed!"}
          content={JSON.stringify(requestError.message || "error!")}
        />
      )}
      {invalidRows && (
        <Message
          warning
          header={"Invalid rows!"}
          content={"Some rows don't have a UPC. Please deselect and try again."}
        />
      )}

      <Modal.Header>Adjust Inventory</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        >
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>UPC</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Current Inventory</Table.HeaderCell>
                <Table.HeaderCell>New Inventory</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {products.map((product) => (
                <Table.Row key={product.product.upc}>
                  <Table.Cell>{product.product.upc}</Table.Cell>
                  <Table.Cell>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product.product.title,
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>{product.product.inv}</Table.Cell>
                  <Table.Cell>
                    <Form.Input
                      name={product.product.upc}
                      type="number"
                      placeholder="Enter new inventory"
                      value={formik.values[product.product.upc]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[product.product.upc] &&
                        formik.errors[product.product.upc]
                          ? formik.errors[product.product.upc]
                          : null
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button color="grey" onClick={handleClose}>
              Close
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={formik.isSubmitting || invalidRows}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
