import React from "react";
import { Button } from "semantic-ui-react";
import _ from "lodash";
import ReactToPrint from "react-to-print";

import "./ShelvingLabelGenerator.css";

// generates a barcode
class ShelvingLabelGenerator extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ReactToPrint
          trigger={() => {
            return <Button>Print labels</Button>;
          }}
          content={() => this.labelRef}
        />
        <div className="label-outer" ref={(el) => (this.labelRef = el)}>
          {_.map(this.props.rowData, (data, index) => {
            const { shelf, prod_list_type } = data.product;
            return (
              <div key={index}>
                <div>
                  <h1 style={{ fontSize: "58px", marginBottom: "8px" }}>
                    {shelf}
                  </h1>
                  <h3 style={{ fontSize: "32px", marginTop: 0 }}>
                    {prod_list_type}
                  </h3>
                </div>
                <div className="page-break" />
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default ShelvingLabelGenerator;
