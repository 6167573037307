/**
 * required functions
 * 1. appendValue
 * 2. appendUnixDate
 */

export const appendValue = (
  datatable,
  editor,
  tableNames,
  columnNames,
  columnData
) => {
  const rows = datatable.rows({ selected: true }).data();
  const rowIndexes = datatable.rows({ selected: true }).indexes();
  editor.edit(rowIndexes, false);

  // Updating rows
  rows.each((row) => {
    for (let i = 0; i < tableNames.length; i++) {
      const table = tableNames[i];
      const column = columnNames[i];
      const fieldToUpdate = `${table}.${column}`;
      const valToRemove = columnData["remove_from_" + column];
      const valToAdd = columnData[column];
      let currVal = row[table][column];
      let newValue = "";

      if (currVal === null || currVal === undefined) currVal = "";

      // Setting new value
      if (column === "next_amz_ship_qty") {
        newValue = valToAdd;
      } else newValue = newValString(currVal, valToAdd, valToRemove);

      editor.field(fieldToUpdate).multiSet(row.DT_RowId, newValue);
    }
  });

  return editor;
};

/**
 * Returns a cleaned, appended string value for the row.
 * @param {string} currVal
 * @param {string} valToAdd
 * @param {string} valToRemove
 * @returns string
 */
export const newValString = (currVal, valToAdd, valToRemove) => {
  const cleanedField = currVal.replace(valToRemove, "");

  return valToAdd.concat(` ${cleanedField}`).replace(/\s\s+/g, " ").trim();
};

/**
 * Clears out data for row and column selected
 * @param {*} datatable
 * @param {*} editor
 * @param {*} tableNames
 * @param {*} columnNames
 */
export const clearData = (datatable, editor, tableNames, columnNames) => {
  if (
    window.confirm(
      "Are you sure you would like to clear the data in the Sort column?"
    )
  ) {
    const rows = datatable.rows({ selected: true }).data();
    const rowIndexes = datatable.rows({ selected: true }).indexes();

    editor.edit(rowIndexes, false);

    // Updating eaching row, setting column value to null
    rows.each((row) => {
      for (let i = 0; i < tableNames.length; i++) {
        const fieldToUpdate = `${tableNames[i]}.${columnNames[i]}`;
        editor.field(fieldToUpdate).multiSet(row.DT_RowId, null);
      }
    });

    editor.submit();
    return editor;
  }
};
