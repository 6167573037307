import React from "react";
import { Button } from "semantic-ui-react";

const FloatingLabelButton = ({ label, labelValue, ...rest }) => (
  <Button {...rest} style={{ position: "relative" }}>
    {label}
    {labelValue > 0 && (
      <div
        style={{
          position: "absolute",
          top: "-10px",
          right: "-10px",
          backgroundColor: "red",
          borderRadius: "50%",
          width: "20px",
          height: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          fontWeight: "bold",
          fontSize: "12px",
        }}
      >
        {labelValue}
      </div>
    )}
  </Button>
);

export default FloatingLabelButton;
