export const appendUnixDate = (datatable, editor, tableName, columnName) => {
  let fieldToUpdate = `${tableName[0]}.${columnName[0]}`;
  if (!tableName[0]) fieldToUpdate = columnName[0];

  const unixTimestamp = Math.round(+new Date() / 1000);

  const rowIndexes = datatable.rows({ selected: true }).indexes();
  editor.edit(rowIndexes, false);
  const rows = datatable.rows({ selected: true }).data();

  if (!rows.length) return alert("Please select a row(s)");

  rows.each((row) => {
    editor.field(fieldToUpdate).multiSet(row.DT_RowId, unixTimestamp);
  });

  return editor;
};
