import uniqid from "uniqid";

export default (state = [], action) => {
  switch (action.type) {
    case "ADD_NOTIFICATION":
      return [...state, { ...action.payload, id: uniqid() }];
    case "REMOVE_NOTIFICATION":
      return state.filter((notification) => notification.id !== action.payload);
    default:
      return state;
  }
};
