import React, { useEffect } from "react";
import { Button, Form, Icon, Loader, Modal, TextArea } from "semantic-ui-react";

function MessageModal({ msg, open, setOpen, type }) {
  useEffect(() => {
    let c = document.getElementById("upc");
    if (c) c.select();
  });
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      {!msg.length ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Modal.Content>
            {type === "copy" ? (
              <Form>
                <h3>UPCs</h3>
                <TextArea id="upc" defaultValue={msg} />
              </Form>
            ) : (
              <p>{msg}</p>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" inverted onClick={() => setOpen(false)}>
              <Icon name="checkmark" /> Okay
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default MessageModal;
