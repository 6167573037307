import { snakeCaseToTitleCase } from "../../../utilities/caseConverters";

const formatErrorArrayString = (errorArray) => {
  let errorArrayString = "";

  errorArray.forEach((errorObj) => {
    const [, field] = errorObj.name.split(".");
    errorArrayString = errorArrayString.concat(
      `Field: ${snakeCaseToTitleCase(field)}\nError: ${errorObj.status}\n`
    );
  });
  return errorArrayString;
};

const attachDamageRowUPCToErrorObject = (datatable, errorRow) => {
  const dtFormattedRowId = `row_${errorRow.id}`;
  const errorRowData = datatable
    .row((index, data, node) => data.DT_RowId === dtFormattedRowId)
    .data();
  const { upc } = errorRowData.damage;
  return { ...errorRow, upc };
};

const formatDamageErrorAlert = (groupedErrorObjects) => {
  let formattedErrorString = "";
  Object.entries(groupedErrorObjects).forEach((entry) => {
    const [upc, errorArray] = entry;
    const errorArrayString = formatErrorArrayString(errorArray);
    formattedErrorString = formattedErrorString.concat(
      `UPC: ${upc}\n${errorArrayString}\n\n`
    );
  });

  return formattedErrorString;
};

const groupErrorsByUPC = (errorObjects) => {
  const errUPCGroups = {};
  errorObjects.forEach((row) => {
    if (!errUPCGroups[row.upc]) errUPCGroups[row.upc] = [row];
    else errUPCGroups[row.upc] = [...errUPCGroups[row.upc], row];
  });
  return errUPCGroups;
};

export const handleDamageSubmitErrors = (datatable, dtErrors) => {
  const { fieldErrors } = dtErrors;
  const errorObjects = fieldErrors.map((row) =>
    attachDamageRowUPCToErrorObject(datatable, row)
  );
  const groupedErrorObjects = groupErrorsByUPC(errorObjects);
  const formattedErrorString = formatDamageErrorAlert(groupedErrorObjects);

  alert(`ROW ERROR(S): \n\n${formattedErrorString}`);
};
