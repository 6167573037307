import React from "react";
import { Button, Modal, Progress } from "semantic-ui-react";

const ProgressBarModal = ({
  title,
  open,
  setOpen,
  value,
  total,
  progressType,
  label,
}) => {
  return (
    <Modal size="small" open={open} onClose={() => setOpen(false)}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Progress value={value} total={total} progress={progressType}>
          {label}
        </Progress>

        {value === total && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={() => setOpen(false)} color="green">
              Close
            </Button>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ProgressBarModal;
