/**
 * Takes in two dates. Returns the gap between them as a string.
 * Uses hours if the difference is less than one day.
 *
 * e.g: "2 Hours Ago", "3 Days Ago"
 * @param {date} a
 * @param {date} b
 * @returns {string}
 */

const millisecondsInOneHour = 60 * 60 * 1000;
const millisecondsInOneDay = 24 * millisecondsInOneHour;

export function hourlyOrDailyDateDifference(a, b) {
  const timeDiffMs = Math.abs(a.getTime() - b.getTime());

  // Is the difference greater than 24 hours?
  if (timeDiffMs >= millisecondsInOneDay) {
    // Is it multiple days?
    const usePlural = timeDiffMs >= millisecondsInOneDay * 2;
    return usePlural
      ? `${convertMsToDays(timeDiffMs)} Days Ago`
      : `${convertMsToDays(timeDiffMs)} Day Ago`;
  } else {
    // Is it multiple hours?
    const usePlural = timeDiffMs >= millisecondsInOneHour * 2;
    return usePlural
      ? `${convertMsToHours(timeDiffMs)} Hours Ago`
      : `${convertMsToHours(timeDiffMs)} Hour Ago`;
  }
}

function convertMsToDays(ms) {
  return Math.floor(ms / millisecondsInOneDay);
}

function convertMsToHours(ms) {
  return Math.floor(ms / millisecondsInOneHour);
}
