import {
  ADD_PRODUCT,
  ADD_PRODUCTS,
  DELETE_PRODUCT,
  CLEAR_PRODUCT_QUEUE,
} from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case CLEAR_PRODUCT_QUEUE:
      return [];
    case ADD_PRODUCT:
      // create a new array containing "add" at the end
      // merge that array with state and de-duplicate it
      return [action.payload, ...state];
    case ADD_PRODUCTS:
      return [...action.payload, ...state];
    case DELETE_PRODUCT:
      const id = Number(action.payload);

      return state.filter((_, index) => index !== id);
    default:
      return state;
  }
};
