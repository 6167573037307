import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Segment, Table, Button } from "semantic-ui-react";

import { fetchWeeklyInventoryLog } from "../../../redux/actions";
import LoadingBox from "../LoadingBox";

class InventoryWeeklyReport extends React.Component {
  state = {
    weeklyReportItems: [],
    weeklyTotalSumObject: {},
    loading: false,
  };

  fetchLog() {
    this.setState({ loading: true });
    this.props
      .fetchWeeklyInventoryLog()
      .catch((err) => alert(err))
      .finally(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.fetchLog();
    this.dateSumAggregator();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.inventoryLog !== this.props.inventoryLog) {
      this.dateSumAggregator();
    }
  }

  logsByDay = {};

  sortProcessedLogs() {
    const aggregatedLogs = [];
    Object.values(this.logsByDay).forEach((log) => aggregatedLogs.push(log));
    this.logsByDay = {};
    this.setState({
      weeklyReportItems: aggregatedLogs.sort((a, b) => b.date - a.date),
    });
  }

  dateSumAggregator() {
    if (!this.props.inventoryLog) return;
    this.props.inventoryLog.forEach((entry) => {
      const formattedLog = this.formatLogEntry(entry);
      this.handleAggregation(formattedLog);
    });

    this.sortProcessedLogs();
  }

  /**
   * Increments quantities for given inventory actions and stores unique UPCs.
   * @param {object} log A formatted log.
   */
  handleAggregation(log) {
    if (!this.logsByDay[log.day])
      this.logsByDay[log.day] = {
        day: log.day,
        date: log.date,
      };
    this.incrementActionCount(log);
    this.incrementUniqueUPCSet(log);
  }

  incrementActionCount(log) {
    if (!this.logsByDay[log.day][log.action])
      this.logsByDay[log.day][log.action] = 0;
    this.logsByDay[log.day][log.action] += log.quantity;
  }

  incrementUniqueUPCSet(log) {
    const setName = `${log.action}UPCS`;
    if (!this.logsByDay[log.day][setName])
      this.logsByDay[log.day][setName] = new Set();
    this.logsByDay[log.day][setName].add(log.upc);
  }

  formatLogEntry(log) {
    return {
      day: new Date(log.date).getDate(),
      date: new Date(log.date),
      action: log.action,
      upc: log.UPC,
      quantity:
        log.added !== undefined
          ? parseInt(log.added, 10)
          : parseInt(log.count, 10),
    };
  }

  formatInventoryDisplay(entry, actionName) {
    if (!entry[actionName]) return "0-0";
    const upcSet = `${actionName}UPCS`;
    const commaSeparatedFormat = Intl.NumberFormat("en-US");

    return `${commaSeparatedFormat.format(
      entry[actionName]
    )}-${commaSeparatedFormat.format(entry[upcSet].size)}`;
  }

  formatTooltip(displayNum) {
    const [total, uniqueUpcs] = displayNum.split("-");
    return `${total} Total Units\n${uniqueUpcs} Unique UPCs`;
  }

  formatWeeklySumObject() {
    let sumObject = {};
    this.state.weeklyReportItems.forEach(
      (dayTotals) => (sumObject = this.mergeDailyObjects(sumObject, dayTotals))
    );
    sumObject = this.attachMinMaxDates(this.state.weeklyReportItems, sumObject);
    return sumObject;
  }

  mergeDailyObjects(obj1, obj2) {
    let obj = this.combineUpcSets(obj1, obj2);
    obj = this.sumCommonNumericFields(obj1, obj2);
    return obj;
  }

  attachMinMaxDates(weeklyReportItems, obj) {
    if (weeklyReportItems.length < 1) return {};
    const firstDay = weeklyReportItems[weeklyReportItems.length - 1];
    const lastDay = weeklyReportItems[0];
    obj.startDate = firstDay.date;
    obj.endDate = lastDay.date;
    return obj;
  }

  combineUpcSets(obj1, obj2) {
    Object.entries(obj2).forEach((entry) => {
      const [key] = entry;
      if (!key.includes("UPCS")) return;
      if (!obj1[key]) obj1[key] = obj2[key];
      else obj1[key] = new Set([...obj1[key], ...obj2[key]]);
    });
    return obj1;
  }

  sumCommonNumericFields(obj1, obj2) {
    Object.entries(obj2).forEach((entry) => {
      const [key, value] = entry;
      if (typeof value !== "number") return;
      if (!obj1[key]) obj1[key] = obj2[key];
      else obj1[key] += obj2[key];
    });
    return obj1;
  }

  renderWeeklyProducts() {
    return _.map(this.state.weeklyReportItems, (entry, index) => (
      <Table.Row key={entry.day} data-testid="weekly-inventory-row">
        <Table.Cell>{entry.date.toLocaleDateString()}</Table.Cell>
        <Table.Cell
          title={this.formatTooltip(this.formatInventoryDisplay(entry, "ADD"))}
        >
          {this.formatInventoryDisplay(entry, "ADD")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(this.formatInventoryDisplay(entry, "SUB"))}
        >
          {this.formatInventoryDisplay(entry, "SUB")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(entry, "HOUSE")
          )}
        >
          {this.formatInventoryDisplay(entry, "HOUSE")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(this.formatInventoryDisplay(entry, "MARK"))}
        >
          {this.formatInventoryDisplay(entry, "MARK")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(entry, "DAMAGE")
          )}
        >
          {this.formatInventoryDisplay(entry, "DAMAGE")}
        </Table.Cell>

        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(entry, "REPAIRED")
          )}
        >
          {this.formatInventoryDisplay(entry, "REPAIRED")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(entry, "RETURNED")
          )}
        >
          {this.formatInventoryDisplay(entry, "RETURNED")}
        </Table.Cell>
      </Table.Row>
    ));
  }

  formatDateRange(weeklySumObject) {
    if (!weeklySumObject.startDate) return;
    return `${weeklySumObject.startDate.toLocaleDateString()} - ${weeklySumObject.endDate.toLocaleDateString()}`;
  }

  renderWeeklyTotal = () => {
    const weeklySumObject = this.formatWeeklySumObject();

    return (
      <Table.Row>
        <Table.Cell>{this.formatDateRange(weeklySumObject)}</Table.Cell>
        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(weeklySumObject, "ADD")
          )}
        >
          {this.formatInventoryDisplay(weeklySumObject, "ADD")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(weeklySumObject, "SUB")
          )}
        >
          {this.formatInventoryDisplay(weeklySumObject, "SUB")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(weeklySumObject, "HOUSE")
          )}
        >
          {this.formatInventoryDisplay(weeklySumObject, "HOUSE")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(weeklySumObject, "MARK")
          )}
        >
          {this.formatInventoryDisplay(weeklySumObject, "MARK")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(weeklySumObject, "DAMAGE")
          )}
        >
          {this.formatInventoryDisplay(weeklySumObject, "DAMAGE")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(weeklySumObject, "REPAIRED")
          )}
        >
          {this.formatInventoryDisplay(weeklySumObject, "REPAIRED")}
        </Table.Cell>
        <Table.Cell
          title={this.formatTooltip(
            this.formatInventoryDisplay(weeklySumObject, "RETURNED")
          )}
        >
          {this.formatInventoryDisplay(weeklySumObject, "RETURNED")}
        </Table.Cell>
      </Table.Row>
    );
  };

  render() {
    return (
      <Segment className="grid-box">
        {/* Daily Breakdown */}
        <div
          style={{ padding: "24px 0", display: "flex", alignItems: "center" }}
        >
          <h2 style={{ margin: "0 24px 0 0" }}>Daily Breakdown</h2>

          <Button
            type="button"
            color="blue"
            icon="sync"
            title="Refresh data."
            onClick={() => this.fetchLog()}
          />
        </div>

        {this.state.loading ? (
          <LoadingBox />
        ) : (
          <Table fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>DATE</Table.HeaderCell>
                <Table.HeaderCell>ADD</Table.HeaderCell>
                <Table.HeaderCell>SUB</Table.HeaderCell>
                <Table.HeaderCell>HOUSE</Table.HeaderCell>
                <Table.HeaderCell>MARK</Table.HeaderCell>
                <Table.HeaderCell>DAMAGE</Table.HeaderCell>
                <Table.HeaderCell>REPAIRED</Table.HeaderCell>
                <Table.HeaderCell>RETURNED</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderWeeklyProducts()}</Table.Body>
          </Table>
        )}

        {/* Weekly Total */}
        <h2 style={{ padding: "24px 0" }}>Weekly Total</h2>
        {this.state.loading ? (
          <LoadingBox />
        ) : (
          <Table fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>DATE</Table.HeaderCell>
                <Table.HeaderCell>ADD</Table.HeaderCell>
                <Table.HeaderCell>SUB</Table.HeaderCell>
                <Table.HeaderCell>HOUSE</Table.HeaderCell>
                <Table.HeaderCell>MARK</Table.HeaderCell>
                <Table.HeaderCell>DAMAGE</Table.HeaderCell>
                <Table.HeaderCell>REPAIRED</Table.HeaderCell>
                <Table.HeaderCell>RETURNED</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderWeeklyTotal()}</Table.Body>
          </Table>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return { inventoryLog: state.reports.inventoryLog };
};

export default connect(mapStateToProps, { fetchWeeklyInventoryLog })(
  InventoryWeeklyReport
);
