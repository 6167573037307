import "tippy.js/dist/tippy.css";
import { renderDate } from "../../formatting/dateFormat";
import { hourlyOrDailyDateDifference } from "../../formatting/dateDifferHoursDaily";
import {
  BUYBOX_CONDITION_IMAGE,
  BUYBOX_SELLER_IMAGE,
} from "../../formatting/buyboxInfo";

export const masterColumnSet = [
  {
    className: "details-control",
    orderable: false,
    searchable: false,
    data: null,
    name: "",
    defaultContent: "",
  },
  {
    name: "product.upc",
    label:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "bottom" trigger = "click"><p>UPC</p></Tooltip>',
    data: "product.upc",
    title:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "bottom" trigger = "click"><p>UPC</p></Tooltip>',
  },
  {
    name: "product.title",
    label:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Title</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.title",
    title:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Title</p></Tooltip>',
    render: function (data) {
      if (data) {
        return (
          "<div style='max-width: 900px;overflow: hidden !important;text-overflow: ellipsis;'>" +
          data +
          "</div>"
        );
      }
      return null;
    },
  },
  {
    name: "product.listing_price",
    label:
      '<Tooltip title =  "Your Online Marketplace\'s current selling price" position =  "bottom " trigger =  "click "><p>Listing Price</p></Tooltip>',
    data: "product.listing_price",
    title:
      '<Tooltip title =  "Your Online Marketplace\'s current selling price" position =  "bottom " trigger =  "click "><p>Listing Price</p></Tooltip>',
  },
  {
    name: "product.asin",
    label:
      '<Tooltip title = "ASIN (Amazon Standard Identification Number)" position = "bottom" trigger = "click"><p>ASIN</p></Tooltip>',
    data: "product.asin",
    title:
      '<Tooltip title = "ASIN (Amazon Standard Identification Number)" position = "bottom" trigger = "click"><p>ASIN</p></Tooltip>',
  },
  {
    name: "product.fnsku",
    label:
      '<Tooltip title =  "FNSKU (Amazon\'s Fulfillment Network SKU) " position =  "bottom " trigger =  "click "><p>FNSKU</p></Tooltip>',
    data: "product.fnsku",
    title:
      '<Tooltip title =  "FNSKU (Amazon\'s Fulfillment Network SKU) " position =  "bottom " trigger =  "click "><p>FNSKU</p></Tooltip>',
  },
  {
    name: "product.stock",
    label:
      '<Tooltip title =  "Purchasable quantity available on Amazon " position =  "bottom " trigger =  "click "><p>Stock</p></Tooltip>',
    data: "product.stock",
    title:
      '<Tooltip title =  "Purchasable quantity available on Amazon " position =  "bottom " trigger =  "click "><p>Stock</p></Tooltip>',
  },
  {
    name: "product.status",
    label:
      '<Tooltip title =  "Your Online Marketplace\'s listing status (F - FBA, M - Merchant, .A - Active, .Inactive, .Incomplete) " position =  "bottom " trigger =  "click "><p>Status</p></Tooltip>',
    data: "product.status",
    title:
      '<Tooltip title =  "Your Online Marketplace\'s listing status (F - FBA, M - Merchant, .A - Active, .Inactive, .Incomplete) " position =  "bottom " trigger =  "click "><p>Status</p></Tooltip>',
  },
  {
    name: "product.total_orders",
    label:
      '<Tooltip title =  "Total number of Amazon sales per SKU" position =  "bottom " trigger =  "click "><p>Total Orders</p></Tooltip>',
    data: "product.total_orders",
    title:
      '<Tooltip title =  "Total number of Amazon sales per SKU" position =  "bottom " trigger =  "click "><p>Total Orders</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Total quantity of orders shipped out to customers (Status cannot equal Cancelled, Returned, or be S01 orders)" position =  "bottom " trigger =  "click "><p>Total Sale Qty Shipped</p></Tooltip>',
    name: "product.total_sale_qty_shipped",
    data: "product.total_sale_qty_shipped",
    title:
      '<Tooltip title =  "Total quantity of orders shipped out to customers (Status cannot equal Cancelled, Returned, or be S01 orders)" position =  "bottom " trigger =  "click "><p>Total Sale Qty Shipped</p></Tooltip>',
  },
  {
    name: "product.unshipped_orders",
    label:
      '<Tooltip title =  "Unshipped Orders " position =  "bottom " trigger =  "click "><p>Unshipped Orders</p></Tooltip>',
    data: "product.unshipped_orders",
    title:
      '<Tooltip title =  "Unshipped Orders " position =  "bottom " trigger =  "click "><p>Unshipped Orders</p></Tooltip>',
  },
  {
    name: "product_listings.last_non_cancelled_order_timestamp",
    label:
      '<Tooltip title =  "How many days since Your Online Marketplace has sold this product " position =  "bottom " trigger =  "click "><p>Time Since Last Order</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.last_non_cancelled_order_timestamp",
    title:
      '<Tooltip title =  "How many days since Your Online Marketplace has sold this product " position =  "bottom " trigger =  "click "><p>Time Since Last Order</p></Tooltip>',
    render: function (data) {
      if (!data) return null;

      const today = new Date();
      const lastestOrderDate = new Date(data * 1000);
      return hourlyOrDailyDateDifference(today, lastestOrderDate);
    },
  },
  {
    name: "product.last_order_price",
    label:
      '<Tooltip title =  "Selling price for Your Online Marketplace\'s most recent order" position =  "bottom " trigger =  "click "><p>Last Order Price</p></Tooltip>',
    data: "product.last_order_price",
    title:
      '<Tooltip title =  "Selling price for Your Online Marketplace\'s most recent order" position =  "bottom " trigger =  "click "><p>Last Order Price</p></Tooltip>',
  },
  {
    name: "product.last_order_qty",
    label:
      '<Tooltip title =  "Number of units sold for Your Online Marketplace\'s most recent order " position =  "bottom " trigger =  "click "><p>Last Order Qty</p></Tooltip>',
    data: "product.last_order_qty",
    title:
      '<Tooltip title =  "Number of units sold for Your Online Marketplace\'s most recent order " position =  "bottom " trigger =  "click "><p>Last Order Qty</p></Tooltip>',
  },
  {
    name: "product.last_shipment",
    label:
      '<Tooltip title =  "Last Shipment " position =  "bottom " trigger =  "click "><p>Last Shipment</p></Tooltip>',
    data: "product.last_shipment",
    title:
      '<Tooltip title =  "Last Shipment " position =  "bottom " trigger =  "click "><p>Last Shipment</p></Tooltip>',
    render: renderDate,
  },
  {
    name: "product.last_shipment_qty",
    label:
      '<Tooltip title =  "Last Ship Qty " position =  "bottom " trigger =  "click "><p>Last Ship Qty</p></Tooltip>',
    data: "product.last_shipment_qty",
    title:
      '<Tooltip title =  "Last Ship Qty " position =  "bottom " trigger =  "click "><p>Last Ship Qty</p></Tooltip>',
  },
  {
    name: "product.received_inventory",
    label:
      '<Tooltip title =  "Received Inv " position =  "bottom " trigger =  "click "><p>Received Inv</p></Tooltip>',
    data: "product.received_inventory",
    title:
      '<Tooltip title =  "Received Inv " position =  "bottom " trigger =  "click "><p>Received Inv</p></Tooltip>',
  },
  {
    name: "product.inbound_inventory",
    label:
      '<Tooltip title =  "Number of units currently being shipped from the East Aurora to Amazon\'s warehouse network " position =  "bottom " trigger =  "click "><p>Inbound Inv</p></Tooltip>',
    data: "product.inbound_inventory",
    title:
      '<Tooltip title =  "Number of units currently being shipped from the East Aurora to Amazon\'s warehouse network " position =  "bottom " trigger =  "click "><p>Inbound Inv</p></Tooltip>',
  },
  {
    name: "product.removed_inventory",
    label:
      '<Tooltip title =  "Number of units removed from Amazon\'s warehouse network and sent back to East Aurora" position =  "bottom " trigger =  "click "><p>Removed Inv</p></Tooltip>',
    data: "product.removed_inventory",
    title:
      '<Tooltip title =  "Number of units removed from Amazon\'s warehouse network and sent back to East Aurora" position =  "bottom " trigger =  "click "><p>Removed Inv</p></Tooltip>',
  },
  {
    name: "product.adjusted_inventory",
    label:
      '<Tooltip title =  "Adjusted Inv" position =  "bottom " trigger =  "click "><p>Adjusted Inv</p></Tooltip>',
    data: "product.adjusted_inventory",
    title:
      '<Tooltip title =  "Adjusted Inv" position =  "bottom " trigger =  "click "><p>Adjusted Inv</p></Tooltip>',
  },
  {
    name: "product.shipped_inventory",
    label:
      '<Tooltip title =  "Number of units shipped from our warehouse to Amazon\'s warehouse network " position =  "bottom " trigger =  "click "><p>Shipped Inv</p></Tooltip>',
    data: "product.shipped_inventory",
    title:
      '<Tooltip title =  "Number of units shipped from our warehouse to Amazon\'s warehouse network " position =  "bottom " trigger =  "click "><p>Shipped Inv</p></Tooltip>',
  },
  {
    name: "product.calculated_stock",
    label:
      '<Tooltip title =  "Recieved Inventory + Inbound Inventory - Total Sale Quantity - Removed Inventory + Adjusted Inventory " position =  "bottom " trigger =  "click "><p>Calculated Stock</p></Tooltip>',
    data: "product.calculated_stock",
    title:
      '<Tooltip title =  "Recieved Inventory + Inbound Inventory - Total Sale Quantity - Removed Inventory + Adjusted Inventory " position =  "bottom " trigger =  "click "><p>Calculated Stock</p></Tooltip>',
  },
  {
    name: "product.shipped_stock",
    label:
      '<Tooltip title =  "Shs (Shipped Stock) Shipped Inventory - Total Sale Quantity - Removed Inventory + Adjusted Inventory " position =  "bottom " trigger =  "click "><p>Shipped Stock</p></Tooltip>',
    data: "product.shipped_stock",
    title:
      '<Tooltip title =  "Shs (Shipped Stock) Shipped Inventory - Total Sale Quantity - Removed Inventory + Adjusted Inventory " position =  "bottom " trigger =  "click "><p>Shipped Stock</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Restock Shipped Stock = Available + FC Transfer + FC Processing + Working + Shipped + Receiving " position =  "bottom " trigger =  "click "><p>New Shipped Stock</p></Tooltip>',
    name: "product.ship_stock",
    data: "product.ship_stock",
    title:
      '<Tooltip title =  "Restock Shipped Stock = Available + FC Transfer + FC Processing + Working + Shipped + Receiving " position =  "bottom " trigger =  "click "><p>New Shipped Stock</p></Tooltip>',
  },
  {
    name: "product.inv_age_271_to_365",
    label:
      '<Tooltip title =  "Number of unsold units at Amazon\'s warehouse that have been there for more than 270 days" position =  "bottom " trigger =  "click "><p>Inv Age 271- 365</p></Tooltip>',
    data: "product.inv_age_271_to_365",
    title:
      '<Tooltip title =  "Number of unsold units at Amazon\'s warehouse that have been there for more than 270 days" position =  "bottom " trigger =  "click "><p>Inv Age 271- 365</p></Tooltip>',
  },
  {
    name: "product_listings.purchasable_stock",
    label:
      '<Tooltip title =  "The total purchasable units for the ASIN\'s listing page." position =  "bottom " trigger =  "click "><p>Page Stock</p></Tooltip>',
    data: "product_listings.purchasable_stock",
    title:
      '<Tooltip title =  "The total purchasable units for the ASIN\'s listing page." position =  "bottom " trigger =  "click "><p>Page Stock</p></Tooltip>',
  },
  {
    name: "product_listings.30_day_profit",
    label:
      '<Tooltip title =  "The total profit of this ASIN’s last 30 day sales." position =  "bottom " trigger =  "click "><p>30 Day Profit</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.30_day_profit",
    title:
      '<Tooltip title =  "The total profit of this ASIN’s last 30 day sales." position =  "bottom " trigger =  "click "><p>30 Day Profit</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;

      const numericUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data);

      return numericUsd;
    },
  },
  {
    name: "product_listings.total_sales",
    label:
      '<Tooltip title =  "The lifetime aggregate sales for YOM\'s products on this ASIN listing page." position =  "bottom " trigger =  "click "><p>Total Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.total_sales",
    title:
      '<Tooltip title =  "The lifetime aggregate sales for YOM\'s products on this ASIN listing page." position =  "bottom " trigger =  "click "><p>Total Page Sales</p></Tooltip>',
  },
  {
    name: "product_listings.365_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 365 days." position =  "bottom " trigger =  "click "><p>365 Day Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.365_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 365 days." position =  "bottom " trigger =  "click "><p>365 Day Page Sales</p></Tooltip>',
  },
  {
    name: "product_listings.90_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.90_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day Page Sales</p></Tooltip>',
  },
  {
    name: "product_listings.30_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.30_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day Page Sales</p></Tooltip>',
  },
  {
    name: "product_listings.7_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.7_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day Page Sales</p></Tooltip>',
  },
  {
    name: "product.sales_rank",
    label:
      '<Tooltip title =  "Sales rank on Amazon\'s website" position =  "bottom " trigger =  "click "><p>Sales Rank</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.sales_rank",
    title:
      '<Tooltip title =  "Sales rank on Amazon\'s website" position =  "bottom " trigger =  "click "><p>Sales Rank</p></Tooltip>',
  },
  {
    name: "product.product_category",
    label:
      '<Tooltip title =  "" position =  "bottom " trigger =  "click "><p>Product Category</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.product_category",
    title:
      '<Tooltip title =  "" position =  "bottom " trigger =  "click "><p>Product Category</p></Tooltip>',
  },
  {
    name: "product.holds_buy_box",
    label:
      '<Tooltip title =  "Is the buy box Your Online Marketplace\'s (ex. Yes, No)" position =  "bottom " trigger =  "click "><p>Our BuyBox</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.holds_buy_box",
    title:
      '<Tooltip title =  "Is the buy box Your Online Marketplace\'s (ex. Yes, No)" position =  "bottom " trigger =  "click "><p>Our BuyBox</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 0) return "No";
      if (!data) return null;
      return "Yes";
    },
  },
  {
    name: "product.buy_box_price",
    label:
      '<Tooltip title =  "The price that is currently listed under the buy box" position =  "bottom " trigger =  "click "><p>BuyBox</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.buy_box_price",
    title:
      '<Tooltip title =  "The price that is currently listed under the buy box" position =  "bottom " trigger =  "click "><p>BuyBox</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;

      const numericUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data);

      return numericUsd;
    },
  },
  {
    name: "product.buy_box_info",
    label:
      '<Tooltip title =  "Info about the buybox including the seller and fulfillment method." position =  "bottom " trigger =  "click "><p>BuyBox Condition</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.buy_box_info",
    title:
      '<Tooltip title =  "Info about the buybox including the seller and fulfillment method." position =  "bottom " trigger =  "click "><p>BuyBox Info</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;
      data = data.toLowerCase();

      if (data.includes("-")) {
        const [status, seller] = data.split(" - ");

        let statusImg = BUYBOX_CONDITION_IMAGE[status];
        if (seller === "not yom")
          return `<img src=${statusImg} alt='${status}' title='${status} - not yom' />`;

        let sellerImg = BUYBOX_SELLER_IMAGE[seller];
        return `<div><img src=${statusImg} alt='${status}' title='${status}' /><img src=${sellerImg} alt='${seller}' title='${seller}' /></div>`;
      }

      let statusImg = BUYBOX_CONDITION_IMAGE[data];
      return `<img src=${statusImg} alt='${data}' title='${data}' />`;
    },
  },
  {
    name: "product.total_sales",
    label:
      '<Tooltip title =  "The total units of this UPC sold on Amazon over the product\'s lifetime." position =  "bottom " trigger =  "click "><p>Total UPC Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.total_sales",
    title:
      '<Tooltip title =  "The total units of this UPC sold on Amazon over the product\'s lifetime." position =  "bottom " trigger =  "click "><p>Total UPC Sales</p></Tooltip>',
  },
  {
    name: "product.90_day_sales",
    label:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day UPC Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.90_day_sales",
    title:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day UPC Sales</p></Tooltip>',
  },
  {
    name: "product.30_day_sales",
    label:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day UPC Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.30_day_sales",
    title:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day UPC Sales</p></Tooltip>',
  },
  {
    name: "product.7_day_sales",
    label:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day UPC Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.7_day_sales",
    title:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day UPC Sales</p></Tooltip>',
  },
  {
    name: "product.alert",
    label:
      '<Tooltip title =  "Amazon\'s recommendation for fixing a poor preforming listing " position =  "bottom " trigger =  "click "><p>Alert</p></Tooltip>',
    data: "product.alert",
    title:
      '<Tooltip title =  "Amazon\'s recommendation for fixing a poor preforming listing " position =  "bottom " trigger =  "click "><p>Alert</p></Tooltip>',
  },
  {
    name: "product.fba_sell_through",
    label:
      '<Tooltip title =  "Units sold and shipped over the past 90 days divided by the average number of units available at fulfillment centers during that time period. A higher number means we sell through inventory fast, and vice versa. " position =  "bottom " trigger =  "click "><p>FBA Sell Thru</p></Tooltip>',
    data: "product.fba_sell_through",
    title:
      '<Tooltip title =  "Units sold and shipped over the past 90 days divided by the average number of units available at fulfillment centers during that time period. A higher number means we sell through inventory fast, and vice versa. " position =  "bottom " trigger =  "click "><p>FBA Sell Thru</p></Tooltip>',
  },
  {
    name: "product.notes",
    label:
      '<Tooltip title = "Notes" position = "bottom" trigger = "click"><p>Notes</p></Tooltip>',
    data: "product.notes",
    title:
      '<Tooltip title = "Notes" position = "bottom" trigger = "click"><p>Notes</p></Tooltip>',
  },
];
