/**
 * returns the width of child text of any DOM node as a float
 */
export function getTextWidth(el) {
  // uses a cached canvas if available
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  // get the full font style property
  const font = window.getComputedStyle(el, null).getPropertyValue("font");
  const text = el.value;

  // set the font attr for the canvas text
  context.font = font;
  const textMeasurement = context.measureText(text);
  return textMeasurement.width;
}

export function getTableColumnWidth(tdNode) {
  return tdNode.offsetWidth;
}
