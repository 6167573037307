import React from "react";
import {
  Icon,
  Menu,
  Segment,
  Sidebar,
  Button,
  Container,
} from "semantic-ui-react";

import "./PricetoolSidebar.css";

// creates 2 custom semantic ui sidebars for pricetool (one on each side)
class PricetoolSidebar extends React.Component {
  state = { leftVisible: false, rightVisible: false, buttonShift: 0 };

  logContainer = React.createRef();

  componentDidUpdate() {
    const sidebarRef = this.logContainer.current;

    if (
      sidebarRef.ref.current.scrollHeight > sidebarRef.ref.current.offsetHeight
    ) {
      this.props.clearSidebar();
    }
  }

  checkedStores = {
    walmartca: 1,
    importcdsebay: 1,
  };

  renderOpenLinks(stores) {
    if (this.props.openPricetoolLink) {
      return stores.map((store) => {
        let storeName = store;
        if (store === "ebayv1") {
          storeName = "Ebay Used and New";
        }
        return (
          <Menu.Item
            as="a"
            onClick={() => this.props.openPricetoolLink(store)}
            key={store}
          >
            <input
              type="image"
              alt={store}
              title={`Open ${storeName}`}
              src={require(`../../../assets/icons/${store}.png`)}
              height={30}
            />
            {this.checkedStores[store] && (
              <i className="check square outline icon"></i>
            )}
          </Menu.Item>
        );
      });
    } else {
      return <div>Loading...</div>;
    }
  }

  render() {
    return (
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Menu}
          animation="push"
          icon="labeled"
          direction="left"
          vertical
          visible={this.state.leftVisible}
          width="thin"
          onMouseLeave={() => {
            this.setState({ leftVisible: false });
          }}
        >
          {this.renderOpenLinks([
            "dealsareus",
            "alliance",
            "walmart",
            "amazon",
            "ebayv1",
            "target",
            "ebayblowit",
            "gruv",
            "rarewaves",
            "importcds",
            "ccvideo",
            "rightstuf",
            "deepdiscount",
            "wowhd",
            "blowitoutahere",
            "importcdsebay",
            "walmartca",
            "amazonca",
            "amazonuk",
            "bestbuy",
            "chapters",
            "bookdepo",
            "bullmoose",
            "mila",
            "couch potato",
            "camel",
            "barnesandnoble",
          ])}
        </Sidebar>
        <Button
          icon
          onMouseEnter={() => {
            this.setState({ leftVisible: true });
          }}
          className="right attached fixed"
        >
          <Icon name="list" />
        </Button>

        <Sidebar
          ref={this.logContainer}
          as={Container}
          animation="overlay"
          direction="right"
          vertical="true"
          visible={this.state.rightVisible}
          width="wide"
        >
          {this.props.content}
        </Sidebar>

        <Button
          icon
          onClick={() => {
            this.state.rightVisible
              ? this.setState({ buttonShift: 0 })
              : this.setState({ buttonShift: 350 });
            this.setState({ rightVisible: !this.state.rightVisible });
          }}
          style={{
            float: "right",
            transform: `translate(${-this.state.buttonShift}px)`,
            transition: "0.5s",
          }}
        >
          <Icon name="list" />
        </Button>

        <Sidebar.Pusher>{this.props.children}</Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

export default PricetoolSidebar;
