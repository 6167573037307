import React from "react";

import { masterColumnSet } from "./aoColumns";

import DataTablesEditor from "../DatatablesEditor/DataTablesEditor";

import { baseURL } from "../../../apis/dvdlocator";
import { renderDate } from "../../formatting/dateFormat";

class AmazonOrders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: this.props.match.params.search
        ? this.props.match.params.search
        : "",
      columns: masterColumnSet,
      colSets: [],

      fields: masterColumnSet,

      options: {
        dom: "Blrtip", // https://datatables.net/reference/option/dom
        processing: true,
        serverSide: true,
        select: true,
        paging: true,
        paginate: true,
        pageLength: 10,
        lengthChange: true,
        scrollX: true,
        scrollY: window.innerHeight - 200,
        scrollCollapse: true,
        autoWidth: true,

        // 	stateSave: true,
        colReorder: true,
        deferRender: true,
        order: [[1, "asc"]],
      },
    };

    this.dataTablesRef = React.createRef();
  }
  componentDidMount() {
    // run initial search if one given
    if (this.state.searchValue) {
      this.dataTablesRef.current.search(this.state.searchValue);
    }
  }
  // search field onChange event handler
  onChangeSearch = (e) => {
    const { value } = e.target;
    const searchValue = value;
    this.setState({ searchValue });
    this.dataTablesRef.current.search(searchValue);
  };

  // generate a mini table of child rows below main row
  // `d` is the datatables object for row
  format = (d) => {
    if (
      d.amazon_order_history !== null &&
      d.amazon_order_history.length !== 0
    ) {
      let ids = [];
      let prices = [];
      let profits = [];
      let proceeds = [];
      let qty = [];
      let timestamps = [];
      let status = [];
      let countries = [];

      let order_data = d.amazon_order_history;
      let upc = d.product.upc;

      this.sortArray(order_data);

      this.seperateArray(
        order_data,
        ids,
        prices,
        profits,
        proceeds,
        qty,
        timestamps,
        status,
        countries
      );

      let ret =
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;" width="1500px">';
      ret +=
        "<tr>" +
        "<td>Order ID:</td>" +
        "<td>UPC:</td>" +
        "<td>Sale Price:</td>" +
        "<td>Sale Quantity:</td>" +
        "<td>Profit:</td>" +
        "<td>Proceeds:</td>" +
        "<td>Timestamp:</td>" +
        "<td>Status:</td>" +
        "<td>Country:</td>" +
        "</tr>";

      for (let i = 0; i < ids.length; i++) {
        const dateString = renderDate(new Date(timestamps[i]));

        const formattedProfit =
          profits[i] !== null
            ? profits[i].toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "";

        const formattedProceeds =
          proceeds[i] !== null
            ? proceeds[i].toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "";
        const formattedPrice =
          prices[i] !== null
            ? prices[i].toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "";

        ret +=
          "<tr>" +
          "<td>" +
          ids[i] +
          "</td>" +
          "<td>" +
          upc +
          "</td>" +
          "<td>" +
          formattedPrice +
          "</td>" +
          "<td>" +
          qty[i] +
          "</td>" +
          "<td>" +
          formattedProfit +
          "</td>" +
          "<td>" +
          formattedProceeds +
          "</td>" +
          "<td>" +
          dateString +
          "</td>" +
          "<td>" +
          status[i] +
          "</td>" +
          "<td>" +
          countries[i] +
          "</td>" +
          "</tr>";
      }

      ret += "</table>";

      return ret;
    } else {
      return null;
    }
  };

  sortArray = (list) => {
    list.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.added_timestamp) - new Date(a.added_timestamp);
    });
  };

  seperateArray = (
    list,
    id,
    price,
    profit,
    proceeds,
    quantity,
    timestamp,
    status,
    countries
  ) => {
    for (let i = 0; i < list.length; i++) {
      id[i] = list[i].order_id;
      price[i] = list[i].order_price;
      profit[i] = list[i].order_profit;
      proceeds[i] = list[i].order_proceeds;
      quantity[i] = list[i].order_qty;
      timestamp[i] = list[i].order_timestamp;
      status[i] = list[i].order_status;
      countries[i] = list[i].order_country;
    }
  };

  customButtons = () => {
    return [
      {
        text: "<span title='Refresh Table'>Refresh</span>",
        action: (e, dt, node, config) => {
          dt.ajax.reload();
        },
      },
    ];
  };

  render() {
    const { fields, columns, options, searchValue } = this.state;

    return (
      <div>
        <div className="ui input">
          <input
            value={searchValue}
            onChange={this.onChangeSearch}
            autoComplete={"off"}
            type="text"
            placeholder="Search ..."
          />
        </div>
        <DataTablesEditor
          key={this.state.render}
          ref={this.dataTablesRef}
          ajax={{
            url: `${baseURL}/datatables/amazon-orders`,
            contentType: "application/json",
            type: "POST",
            data: (d) => {
              d.smartSearch = this.state.searchValue;
              return JSON.stringify(d);
            },
          }}
          editorAjax={{
            url: `${baseURL}/datatables/amazon-orders`,
            contentType: "application/json",
            type: "POST",
            data: function (d) {
              return JSON.stringify(d);
            },
          }}
          columns={columns}
          options={options}
          fields={fields}
          createButton={false}
          editButton={true}
          removeButton={true}
          customButtons={this.customButtons}
          colVis={false}
          rowExpansion={this.format}
        />
      </div>
    );
  }
}

export default AmazonOrders;
