//USE THIS TO CREATE DROPDOWN FOR DIRECTIVE BUILDER
export const storeKey = {
  alliance: "OMKT",
  amazon: "AMZZ",
  amazonca: "AMCA",
  amazonfba: "AFBA",
  amazonuk: "AMUK",
  barnesandnoble: "BRNO",
  bestbuy: "BBUY",
  blowit: "BIOW",
  blowitebay: "BIOH",
  bookdepository: "BKDP",
  bullmoose: "BUMO",
  ccvideo: "CCVD",
  dealsareus: "DAUS",
  deepdiscount: "DPDS",
  discount_entertainment: "DTET",
  ebay: "EBAY",
  gruv: "GRUV",
  importcds: "IMPC",
  inetvideo: "INTT",
  mila: "MILA",
  pbs_store: "PBSS",
  rarewaves: "RRWV",
  rightstuff: "RSAS",
  scholastic: "SCHO",
  target_store: "TRGT",
  "unv amazon": "",
  "unv ebay": "",
  "unv half": "",
  vei: "VVEI",
  walmart: "WLMT",
  wildcard1: "WLD1",
  wildcard2: "WDL2",
  wildcard3: "WDL3",
  wildcard4: "WLD4",
  wowhd: "WOWHD",
  ua11: "ua1",
};
