export const masterColumnSet = [
  {
    label:
      '<Tooltip title = "Favi" position = "bottom" trigger = "click"><p>Favi</p></Tooltip>',
    name: "favi",
    data: "favi",
    title:
      '<Tooltip title = "Favi" position = "bottom" trigger = "click"><p>Favi</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Store" position = "bottom" trigger = "click"><p>Store</p></Tooltip>',
    name: "store",
    data: "store",
    title:
      '<Tooltip title = "Store" position = "bottom" trigger = "click"><p>Store</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "ML" position = "bottom" trigger = "click"><p>ML</p></Tooltip>',
    name: "ml",
    data: "ml",
    title:
      '<Tooltip title = "ML" position = "bottom" trigger = "click"><p>ML</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Coupon" position = "bottom" trigger = "click"><p>Coupon</p></Tooltip>',
    name: "coupon",
    data: "coupon",
    title:
      '<Tooltip title = "Coupon" position = "bottom" trigger = "click"><p>Coupon</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Incentive" position = "bottom" trigger = "click"><p>Incentive</p></Tooltip>',
    name: "incentive",
    data: "incentive",
    title:
      '<Tooltip title = "Incentive" position = "bottom" trigger = "click"><p>Incentive</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Rebate" position = "bottom" trigger = "click"><p>Rebate</p></Tooltip>',
    name: "rebate",
    data: "rebate",
    title:
      '<Tooltip title = "Rebate" position = "bottom" trigger = "click"><p>Rebate</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Forex" position = "bottom" trigger = "click"><p>Forex</p></Tooltip>',
    name: "forex",
    data: "forex",
    title:
      '<Tooltip title = "Forex" position = "bottom" trigger = "click"><p>Forex</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Shipping" position = "bottom" trigger = "click"><p>Shipping</p></Tooltip>',
    name: "shipping",
    data: "shipping",
    title:
      '<Tooltip title = "Shipping" position = "bottom" trigger = "click"><p>Shipping</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Winner" position = "bottom" trigger = "click"><p>Winner</p></Tooltip>',
    name: "winner",
    data: "winner",
    title:
      '<Tooltip title = "Winner" position = "bottom" trigger = "click"><p>Winner</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Changed" position = "bottom" trigger = "click"><p>Changed</p></Tooltip>',
    name: "changed",
    data: "changed",
    title:
      '<Tooltip title = "Changed" position = "bottom" trigger = "click"><p>Changed</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Notes" position = "bottom" trigger = "click"><p>Notes</p></Tooltip>',
    name: "notes",
    data: "notes",
    title:
      '<Tooltip title = "Notes" position = "bottom" trigger = "click"><p>Notes</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "DTBAlg1" position = "bottom" trigger = "click"><p>DTBAlg1</p></Tooltip>',
    name: "dtbalg1",
    data: "dtbalg1",
    title:
      '<Tooltip title = "DTBAlg1" position = "bottom" trigger = "click"><p>DTBAlg1</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "DTBAlg2" position = "bottom" trigger = "click"><p>DTBAlg2</p></Tooltip>',
    name: "dtbalg2",
    data: "dtbalg2",
    title:
      '<Tooltip title = "DTBAlg2" position = "bottom" trigger = "click"><p>DTBAlg2</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "ShipRule" position = "bottom" trigger = "click"><p>ShipRule</p></Tooltip>',
    name: "shippingrule",
    data: "shippingrule",
    title:
      '<Tooltip title = "ShipRule" position = "bottom" trigger = "click"><p>ShipRule</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "todayscost" position = "bottom" trigger = "click"><p>TodayCost</p></Tooltip>',
    name: "todayscost",
    data: "todayscost",
    title:
      '<Tooltip title = "todayscost" position = "bottom" trigger = "click"><p>TodayCost</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Promotion" position = "bottom" trigger = "click"><p>Promotion</p></Tooltip>',
    name: "promotions",
    data: "promotions",
    title:
      '<Tooltip title = "Promotion" position = "bottom" trigger = "click"><p>Promotion</p></Tooltip>',
  },
];

export const viewableDatatableColumns = masterColumnSet.filter(
  (col) => col.type !== "hidden"
);
