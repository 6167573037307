import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Form, Dropdown, Segment, Grid } from "semantic-ui-react";
import { reduxForm } from "redux-form";

import dvdlocator from "../../apis/dvdlocator";
import ColumnReorder from "./ColumnReorder";
import { fetchColumnSets, putColumnSets } from "../../redux/actions";
import { masterColumnSet } from "../database/Pricetool/ptColumns";
import {
  extractTooltipOrAltText,
  formatSQLColumnString,
} from "../utilities/formatters/columnSetForm";

class ColumnSets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      selectedColumnSet: "",
      selectedColumn: "",
      selectedShareRecipient: "",
      usernames: [],
    };

    this.grid = 2;
    this.dropdownStyle = { marginTop: "10px", marginBottom: "10px" };
  }

  componentDidMount() {
    // check that login request completed first
    if (this.props.auth.userId && _.isEmpty(this.props.columnSets)) {
      this.props.fetchColumnSets(this.props.auth.userId);
    }

    this.usernames = dvdlocator
      .get("/nosql/get-usernames")
      .then((response) => response.data)
      .then((data) => {
        this.setState({ usernames: data });
      });
  }

  componentDidUpdate() {
    // check that login request completed first
    if (this.props.auth.userId && _.isEmpty(this.props.columnSets)) {
      this.props.fetchColumnSets(this.props.auth.userId);
    }
    // assign initial value to state
    if (_.isEmpty(this.state.items) && !_.isEmpty(this.props.columnSets)) {
      this.getItemsNew().filter((row) => row);
      //this.setState({ items: this.getItemsNew().filter(row=> row) });
    }
  }

  dropdownOptions = () => {
    return _.map(
      masterColumnSet.filter((item) => item.title),
      (col) => {
        let fieldName = extractTooltipOrAltText(col.title);
        if (!fieldName) {
          fieldName = formatSQLColumnString(col.data);
        }
        return {
          key: col.data,
          text: fieldName,
          value: col.data,
          disabled: this.state.items.some((c) => c.id === col.data),
        };
      }
    );
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: this.grid * 2,
    borderRadius: "3px",
    margin: `0 0 ${this.grid}px 0`,
    width: "125px",
    flexShrink: 0,

    background: isDragging ? "lightgreen" : "lightgrey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver, itemsLength) => ({
    background: isDraggingOver ? "lightblue" : "none",
    display: "flex",
    padding: this.grid,
    overflowX: "scroll",
    gap: "5px",
  });

  removeColumn = (id) => {
    this.setState({ items: this.state.items.filter((col) => col.id !== id) });
  };

  addColumn = () => {
    const colIndex = _.findIndex(masterColumnSet, (col) => {
      return col.data === this.state.selectedColumn;
    });

    const newItem = masterColumnSet[colIndex];

    const exists = _.some(this.state.items, (item) => {
      return item.id === this.state.selectedColumn;
    });

    if (!exists) {
      this.setState({
        items: [
          ...this.state.items,
          { id: newItem.data, content: newItem.title },
        ],
      });
    }
  };

  // data generator

  getItemsNew = () => {
    return _.map(
      this.props.columnSets[this.props.auth.userId][
        this.state.selectedColumnSet
      ],
      (name) => {
        const currentColumn = masterColumnSet.find((row) => row.data === name);
        if (currentColumn) {
          return {
            id: currentColumn.data,
            content: currentColumn.title,
          };
        }
      }
    );
  };

  deleteColumnSet = () => {
    if (!this.state.selectedColumnSet) {
      alert("You must select a column set");
    } else {
      const accepted = window.confirm(
        `Do you want to delete the column set '${this.state.selectedColumnSet}'?`
      );

      if (!accepted) return;

      dvdlocator
        .delete(
          `/nosql/${this.props.auth.userId}/column-sets/${this.state.selectedColumnSet}`
        )
        .then(() => {
          this.setState({ selectedColumnSet: "", items: [] });
          this.props.fetchColumnSets(this.props.auth.userId);
          alert(`${this.state.selectedColumnSet} successfully deleted!`);
        })
        .catch((err) => alert(err.message));
    }
  };

  // load a new colSet into the colReorder panel
  onLoadSubmit = () => {
    if (!this.state.selectedColumnSet) {
      alert("You must select a column set");
    } else {
      // second setState relies on colSet being updated
      // setState is asynchronous so we must chain the second one in a callback function
      this.setState({ items: this.getItemsNew().filter((row) => row) });
    }
  };

  onShareSubmit = () => {
    if (!this.state.selectedColumnSet) {
      alert("You must select a column set");
    } else if (!this.state.selectedShareRecipient) {
      alert("You must select a user to share column set with");
    } else {
      dvdlocator
        .post("/nosql/share-column-sets", {
          sender: this.props.auth.userId,
          recipient: this.state.selectedShareRecipient,
          columnSet: this.state.selectedColumnSet,
        })
        .then((response) => alert(response.data));
    }
  };

  // push updated colSet to dynamodb
  onSaveSubmit = () => {
    if (!this.state.selectedColumnSet) {
      alert("You must select a column set");
    } else {
      const accepted = window.confirm(
        `Are you sure you want to save these columns for column set '${this.state.selectedColumnSet}'?`
      );

      if (!accepted) return;

      // format state to be consumable by action creator
      const newItem = {
        [this.state.selectedColumnSet]: _.map(this.state.items, (item) => {
          return item.id;
        }),
      };
      // call some action creator to update colSet
      this.props.putColumnSets(newItem);
      alert("Column set updated");
    }
  };

  // push new colSet to dynamodb
  onNewSubmit = () => {
    let colSetName = prompt("Enter New Column Set Name");

    if (!colSetName) return alert("Must provide a name.");

    colSetName = colSetName.trim();

    const newItem = {
      [colSetName]: _.map(this.state.items, (item) => {
        return item.id;
      }),
    };

    // call some action creator to update colSet
    this.props
      .putColumnSets(newItem)
      .then(() => this.setState({ selectedColumnSet: colSetName }));
    alert("New column set created");
  };

  reorderItems = (items) => {
    this.setState({ items });
  };

  render() {
    // wait until data loaded to render
    if (!_.isEmpty(this.props.columnSets) && this.props.auth.userId) {
      return (
        <React.Fragment>
          <Segment padded className="grid-box">
            <Grid columns={3} stackable textAlign="center">
              <Grid.Row verticalAlign="middle">
                {/* Add Column Form */}
                <Grid.Column>
                  <div>
                    <div className="ui label">Add Column</div>
                    <Form.Field>
                      <Dropdown
                        style={this.dropdownStyle}
                        search={handleSearchChange}
                        selection
                        value={this.state.selectedColumn}
                        onChange={(_, data) => {
                          this.setState({ selectedColumn: data.value });
                        }}
                        placeholder="Select column"
                        options={this.dropdownOptions()}
                      />
                    </Form.Field>
                  </div>
                  <button
                    disabled={!this.state.selectedColumn}
                    onClick={this.addColumn}
                    className="button ui blue inverted"
                  >
                    Add
                  </button>
                </Grid.Column>

                {/* Select Column Set Form */}
                <Grid.Column>
                  <div>
                    <div className="ui label">Select Column Set</div>
                    <Form.Field>
                      <Dropdown
                        style={this.dropdownStyle}
                        search
                        selection
                        value={this.state.selectedColumnSet}
                        onChange={(param, data) =>
                          this.setState({ selectedColumnSet: data.value })
                        }
                        placeholder={"Select column set"}
                        options={_.map(
                          _.keys(this.props.columnSets[this.props.auth.userId]),
                          (colSet) => {
                            return {
                              key: colSet,
                              text: colSet,
                              value: colSet,
                            };
                          }
                        )}
                      />
                    </Form.Field>
                  </div>
                  <button
                    onClick={this.onLoadSubmit}
                    className="button ui blue inverted"
                    disabled={!this.state.selectedColumnSet}
                  >
                    Load
                  </button>
                  <button
                    onClick={this.onSaveSubmit}
                    className="button ui blue inverted"
                    disabled={this.state.items.length < 1}
                  >
                    Save
                  </button>
                  <button
                    onClick={this.onNewSubmit}
                    disabled={this.state.items.length < 1}
                    className="button ui blue inverted"
                  >
                    New
                  </button>
                  <button
                    onClick={this.deleteColumnSet}
                    disabled={!this.state.selectedColumnSet}
                    className="button ui red"
                    title="Delete selected column set"
                  >
                    Delete
                  </button>
                </Grid.Column>

                {/* Share Column Set Form */}
                <Grid.Column>
                  <div>
                    <div className="ui label">Share Column Set</div>
                    <Form.Field>
                      <Dropdown
                        style={this.dropdownStyle}
                        selection
                        search
                        value={this.state.selectedShareRecipient}
                        onChange={(param, data) =>
                          this.setState({ selectedShareRecipient: data.value })
                        }
                        placeholder={"Select user"}
                        options={this.state.usernames.map((name) => {
                          return {
                            key: name,
                            text: name,
                            value: name,
                          };
                        })}
                      />
                    </Form.Field>
                  </div>
                  <button
                    onClick={this.onShareSubmit}
                    disabled={!this.state.selectedShareRecipient}
                    className="button ui blue inverted"
                  >
                    Share
                  </button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <ColumnReorder
            items={this.state.items}
            removeColumn={this.removeColumn}
            reorderItems={this.reorderItems}
            getItemStyle={this.getItemStyle}
            getListStyle={this.getListStyle}
          />
        </React.Fragment>
      );
    } else {
      return (
        <div className="ui active transition visible dimmer">
          <div className="content">
            <div className="ui loader"></div>
          </div>
        </div>
      );
    }
  }
}

const handleSearchChange = (options, value) => {
  return options.filter((option) => {
    return option.text.toLowerCase().includes(value.toLowerCase());
  });
};

// called by redux-form on any user interaction. validates input
// and returns errors if necessary
const validate = (formValues) => {
  const errors = {};

  return errors;
};

const mapStateToProps = (state) => {
  return { columnSets: state.columnSets, auth: state.auth };
};

export default reduxForm({
  form: "colSetForm",
  validate,
})(connect(mapStateToProps, { fetchColumnSets, putColumnSets })(ColumnSets));
