import _ from "lodash";
import React from "react";
import { storeKey } from "./directiveBuilderStores";
export const directiveBuilder = (datatable, editor) => {
  let upcs = []; //The upcs for the selected rows
  let ovr = []; //The osid for the selected rows, which is the overstock id
  let fam = []; //The fvid for the selected rows, which is the family video id
  let wbs = []; //The wbsku for the selected rows, which is the warner brothers sku (id)
  let prices = []; //The price for the selected store, based off of the row
  let nums = []; //The number of the product
  let asn = []; //The asin of the product
  let casin = []; //The casin of the product
  let houseCol = []; //The house column for product
  let upcStoreJSON = [];
  let store = document.querySelector(".selectstore").value.toLowerCase();
  store = handleStoreNameConversion(store);
  const supplierName = storeKey[store];
  const house_dir = document.querySelector("#housedir").value;
  const initials = document.querySelector("#initials").value;
  let item_price = 0;
  let cogs = 0;

  const datas = datatable.rows({ selected: true }).data();
  _.each(datas, function (row) {
    // Push the data to the respective arrays based off of the selected row
    if (
      row["product"]["house"].toLowerCase().includes(supplierName.toLowerCase())
    ) {
      upcs.push(row["product"]["upc"]);
      ovr.push(row["product"]["osid"]);
      fam.push(row["product"]["fvid"]);
      wbs.push(row["product"]["wbsku"]);
      asn.push(row["product"]["asin"]);
      casin.push(row["product"]["casin"]);
      nums.push(row["collection"]["num"]);
      houseCol.push(row["product"]["house"]);
      upcStoreJSON.push({
        upc: row["product"]["upc"],
        store: store,
        supplier: row["product"]["house"],
        media_type: row["product"]["media_type"],
      });
    }

    if (store === "ebay") {
      store = "ebayv1";
    }

    prices.push(parseFloat(row["supplier_pricing"][store]));
  });

  // Get the total of all of the prices
  let priceTotal = _.reduce(prices, function (total, n) {
    return total + n;
  });
  let storeInvValues = getStoreInvVals(houseCol, store);
  let houseInv = [];
  storeInvValues.forEach((outerRow) => {
    houseInv.push(outerRow[1]);
  });
  let storeUPCQtyJSON = storeQtyUPCJSON(upcStoreJSON, store, supplierName);
  // Special store cases
  if (store === "overstock") upcs = ovr;
  if (store === "familyvideo") upcs = fam;
  if (store === "wbshop") upcs = wbs;
  if (store === "amazon" || store === "amazonfba") upcs = asn;
  if (store === "amazonca" || store === "amazoncafba") upcs = casin;
  if (store === "gohastings") priceTotal = priceTotal + upcs.length * 1.29;
  if (store === "gohastings") store = "Hastings";

  // Check if there are any duplicate UPCS
  // Cancel function if there are
  const duplicates = _.uniq(upcs).length !== upcs.length;
  if (duplicates) {
    alert("Duplicate UPCs: Please try again.");
    return;
  }

  // Price suggestion calculations
  const blist = (159.0 / 841.0) * ((cogs - 3.69) / 0.159 + 3.99); //Price to list it at for a 15.9 percent profit margin
  const list = (249.0 / 751.0) * ((cogs - 3.69) / 0.249 + 3.99); //Price to list it at for a 24.9 percent profit margin

  // Profit calcualtions
  const profit = item_price + 3.99 - cogs - (0.159 * (item_price + 3.99) + 0.3);
  const perc_profit = Math.round((profit / (item_price + 3.99)) * 100, 0);

  // Get the current date
  const today = new Date();
  const dd = ("0" + today.getDate()).slice(-2);
  const mm = today.getMonth() + 1;
  const yy = JSON.stringify(today.getFullYear()).slice(-2);

  // Get a unicode char based on certain profit, and price conditions
  let unicode_char = "";

  if (item_price < list && profit > 0) {
    unicode_char = String.fromCharCode(186); // º
  } else if (profit < 0) {
    unicode_char = String.fromCharCode(182); // ¶
  } else if (item_price >= list) {
    unicode_char = String.fromCharCode(177); // ±
  }

  // The top of the console output
  let top = [];

  // If we are creating a house directive
  if (house_dir === "H") {
    top.push("# " + mm + "." + dd + "." + yy + "." + initials + "*");
    top.push(
      housePrice(
        datas,
        storeInvValues,
        supplierName,
        storeUPCQtyJSON,
        store
      ).reduce((acc, x) => (acc === null ? [x] : [acc, <br />, x]), null)
    );
    // If the store is unventted
    if (!store.includes("unv")) {
      top.push("#");
    }
  } else {
    // Use JSON.stringnify to format arrays
    top.push(JSON.stringify(prices));
    top.push(
      "# " +
        mm +
        "." +
        dd +
        "." +
        yy +
        "." +
        initials +
        "* Terminal: $" +
        cogs.toString(10) +
        "\\Sale Price: $" +
        item_price.toString(10) +
        " | Profit: %c" +
        unicode_char +
        "$" +
        JSON.stringify(Math.round(profit, 2)) +
        "," +
        Math.round(perc_profit, 2).toString(10) +
        "% %c | 9%\\BE: $" +
        Math.round(list, 2).toString(10) +
        "\\$" +
        Math.round(blist, 2).toString(10) +
        " #"
    );

    // Example output: [13.19,12.49]
    // # 3.15.19.$test* Terminal: $12\Sale Price: $18.99 | Profit: ±$7.03,31%  | 9%\BE: $12.39\$10.64 #
  }

  // The console output
  let output = [];

  // If the store is a unvetted store print a special format
  // Unvented amazon
  if (store.includes("unv amazon")) {
    if (house_dir === "H") {
      top.push("");
    } else {
      top.push("#");
    }
    // top += mm + "." + dd + "." + yy + "." + $('#initials').val() + "\r\n";

    // Add data to the top
    top.push("UNVETTED");
    top.push("Account        : George");
    top.push("Condition      : NEW");
    top.push("Seller            : ");
    top.push("Location       : ");
    top.push("Notes           : ");
    top.push("#");
    top.push("");
    top.push("");
  }

  // Unvented ebay
  if (store.includes("unv ebay")) {
    if (house_dir === "H") {
      top.push("");
    } else {
      top.push("");
      top.push("#");
      top.push("");
    }
    // top += mm + "." + dd + "." + yy + "." + $('#initials').val() + "\r\n";
    top.push("UNVETTED");
    top.push("Account        : ");
    top.push("Condition      : NEW");
    top.push("Seller            : ");
    top.push("Location       : ");
    // top += "UPC,QTY      : \r\n";
    top.push("Notes           : ");
    top.push("#");
    top.push("");
    top.push("");
  }

  // Unvetted half
  if (store.includes("unv half")) {
    if (house_dir === "H") {
      top.push("");
    } else {
      top.push("");
      top.push("#");
      top.push("");
    }

    top.push("UNVETTED");
    top.push("Condition      : NEW");
    top.push("Seller            : ");
    top.push("Location       : ");
    top.push("Notes           : ");
    top.push("#");
    top.push("");
    top.push("");
  }

  // Remove any v1 or unv characters from the stores string. Clear it to just be the store
  store = store.replace("v1", "").replace("unv ", "");

  // If it is an fba store
  if (store.includes("fba")) {
    if (!priceTotal) {
      priceTotal = 0;
    }
    store = store.replace("fba", "");
    output.push("@" + store);
    output.push(
      "[ $" +
        Math.round(priceTotal, 2) +
        " fba, " +
        upcs.length +
        " pcs %c" +
        nums.join(",") +
        "%c ]"
    );
    output.push(
      upcs.reduce((acc, x) => (acc === null ? [x] : [acc, <br />, x]), null)
    );
  } else {
    // If the store is just a regular store
    let tote = priceTotal;
    if (isNaN(tote)) {
      tote = "0";
    } // If the total is not a number set it equal to $.¢¢
    output.push("@" + store);
    output.push(
      "[ $" +
        Math.round(tote * 100) / 100 +
        ", " +
        upcs.length +
        " pcs %c" +
        nums.join(",") +
        "%c ]"
    );
    if (store === "dealsareus") {
      storeUPCQtyJSON.forEach((row) => {
        if (row.supplier.toLowerCase() === "daus") {
          output.push(row.upc + " " + row.qty);
        }
      });
    } else {
      storeUPCQtyJSON.forEach((row) => {
        if (row.supplier.toLowerCase() !== "daus") {
          output.push(row.upc + " ," + row.qty);
        }
      });
    }
    let totalPrice = calculateDiscount(storeUPCQtyJSON, store);
    output.push("");
    output.push("Total Price = $" + totalPrice);
  }

  // If the store is bestbuy or walmart. Set the output equal to a custom type of output
  if (store === "bestbuy" || store === "walmart") {
    if (!priceTotal) {
      priceTotal = 0;
    }
    output = [
      "@" + store,
      "[ $" +
        Math.round(priceTotal, 2) +
        ", " +
        upcs.length +
        " pcs +Tax " +
        nums.join(",") +
        " ]",
      upcs.reduce((acc, x) => (acc === null ? [x] : [acc, <br />, x]), null),
    ];

    let totalPrice = calculateDiscount(storeUPCQtyJSON, store);
    output.push("");
    output.push("Total Price = $" + totalPrice);
  }
  // If the store is a type of amazon store. Set the output equal to a custom type of output
  if (store === "amazonca" || store === "amazoncafba") {
    if (!priceTotal) {
      priceTotal = 0;
    }
    output.push("@" + store);
    output.push("[ USD Conv. 0");
    output.push("  " + upcs.length + " Pcs     " + Math.round(priceTotal, 2));
    output.push("  Ship      0");
    output.push("  GST/PST   0");
    output.push("  CDN$      0]");
    output.push(
      upcs.reduce((acc, x) => (acc === null ? [x] : [acc, <br />, x]), null)
    );
  }

  // Get the date, and initials of the directive
  // dbd (directive builder date)
  const pricedate = mm + "." + dd + "." + yy + "." + initials;
  editor
    .edit(datatable.rows({ selected: true }).indexes(), false)
    .set("product.dbd", pricedate)
    .submit(); // Edit the gnotes column and add the pricedate to the column

  // Log the final output
  return { top, output };
};

const handleStoreNameConversion = (store) => {
  if (store === "ebayo") return "ebayv1";
  if (store === "barnes&noble") return "barnesandnoble";
  if (store === "wildcard1") return "wildcard0";
  if (store === "wildcard2") return "wildcard1";
  if (store === "wildcard3") return "wildcard2";
  if (store === "wildcard4") return "wildcard3";
  if (store === "unv amazon") return "amazonlowest";
  if (store === "unv ebay") return "elow";
  if (store === "unv half") return "halfl";
  if (store === "discount entertainment") return store.replace(/ /g, "_");
  if (store === "blowit ebay") return store.replace(/\s/g, "");
  if (store === "target" || store === "pbs") return store.concat("_store");

  return store;
};

function getStoreDtb(store, row) {
  let dtb = null;
  if (!row["supplier_pricing"]["dtb1"]) return dtb;

  for (let i = 1; i < 11; i++) {
    const supplierCol = `dtb${i}_supplier`;
    const priceCol = `dtb${i}`;
    if (row["supplier_pricing"][supplierCol].toLowerCase() === store) {
      return row["supplier_pricing"][priceCol];
    }
  }

  return dtb;
}

function housePrice(
  datas,
  storeInvValues,
  supplierName,
  storeUPCQtyJSON,
  storeName
) {
  let upcs = [];
  let ovr = [];
  let fam = [];
  let wbs = [];
  let prices = [];
  let dtbs = [];
  let nums = [];
  let asn = [];
  let casin = [];
  let todays = 0.0; // The current coupon
  let store = storeName;

  let houseInv = [];
  storeInvValues.forEach((outerRow) => {
    houseInv.push(outerRow[1]);
  });

  // Change the store depending on its name
  store = storeName;

  _.each(datas, function (row) {
    // Get the column data for the selected upcs
    if (
      row["product"]["house"].toLowerCase().includes(supplierName.toLowerCase())
    ) {
      upcs.push(row["product"]["upc"]);
      ovr.push(row["product"]["osid"]);
      fam.push(row["product"]["fvid"]);
      wbs.push(row["product"]["wbsku"]);
      asn.push(row["product"]["asin"]);
      casin.push(row["product"]["casin"]);
      nums.push(row["collection"]["num"]);
    }
    storeUPCQtyJSON.forEach((value) => {
      if (value.upc === row["product"]["upc"]) {
        value.price = parseFloat(row["supplier_pricing"][store]);
      }
    });
    prices.push(parseFloat(row["supplier_pricing"][store]));

    // If the store is amazon just add the coupon
    if (
      store === "amazon" ||
      store === "amazonlowest" ||
      store === "amazonfba"
    ) {
      if (todays) {
        todays += parseFloat(row["supplier_pricing"][store]);
      } else {
        todays = 0;
      }
    }

    let the_upc = row["product"]["upc"]; // Current row upc

    // Update the_upc depending on the store
    if (store === "overstock") the_upc = row["product"]["OSID"]; // If the store is overstock set it to the overstock id
    if (store === "amazon" || store === "amazonfba" || store === "amazonlowest")
      the_upc = row["product"]["asin"]; // If the store is amazon set it to the asin
    if (
      store === "amazonca" ||
      store === "amazoncafba" ||
      store === "amazoncalowest"
    )
      the_upc = row["product"]["CASIN"]; // If the store is amazon canada set it to the canada asin

    // If the store isn't amazon push the correct dtb entry that correspods to the store. Also include in the pushed array the current upc for the product
    if (
      store !== "amazon" &&
      store !== "amazonlowest" &&
      store !== "amazonfba"
    ) {
      try {
        const dtbPrice = getStoreDtb(store, row);
        if (dtbPrice) {
          dtbs.push([the_upc, dtbPrice]);
          storeUPCQtyJSON.forEach((value) => {
            if (value.upc === the_upc) {
              value.dtbPrice = dtbPrice;
            }
          });
        }
      } catch (err) {
        console.log("Error getting price dtb - ".concat(row["product"]["upc"]));
        console.log(err);
      }
    }

    if (
      store === "amazon" ||
      store === "amazonlowest" ||
      store === "amazonfba"
    ) {
      dtbs.push([the_upc, row["supplier_pricing"][store]]);
    }
  });

  let output = [];
  // For each selected row tryq to print the price, and upc for each entry in the dtbs array
  // Expected error would be index error

  _.each(storeUPCQtyJSON, function (row, i) {
    try {
      if (!row.dtbPrice) throw new Error("No Valid DTB Price");
      if (
        row.store === "alliance" &&
        row.qty >= 30 &&
        row.price < 30 &&
        row.media_type?.toLowerCase() !== "vinyl"
      ) {
        let discPrice = Math.round((row.price - row.price * 0.02) * 100) / 100;
        output.push(discPrice + "," + row.upc + "," + row.qty);
      } else {
        output.push(row.dtbPrice + "," + row.upc + "," + row.qty);
      }
    } catch (err) {
      console.log(err, " ", row.upc);
      // If we did re[ $7.29, 1 pcs %c1%c ]cieve an index error just print the price as zero, and the upc from the row
      output.push(" ," + row.upc + "," + row.qty);
    }
  });
  // Format the output to include the todays coupon
  output.push("");
  if (todays) {
    todays = 0;
  }
  output.push("Today = " + todays.toFixed(2));
  output.push("");

  return output;
}
function calculateDiscount(selectedRows, store) {
  let prices = [];
  var totalPrice = 0;
  if (store === "alliance") {
    selectedRows.forEach((row) => {
      if (
        row.qty >= 30 &&
        row.price < 30 &&
        row.media_type?.toLowerCase() !== "vinyl"
      ) {
        const preDiscount = row.qty * row.price;
        prices.push(preDiscount - preDiscount * 0.02);
      } else {
        const price = row.qty * row.price;
        prices.push(price);
      }
    });
  } else {
    selectedRows.forEach((row) => {
      const price = row.qty * row.price;
      prices.push(price);
    });
  }
  prices.forEach((price) => {
    totalPrice += price;
  });
  if (isNaN(totalPrice)) {
    return 0;
  } else {
    return Math.round(totalPrice * 100) / 100;
  }
}
function getStoreInvVals(houseCol, store) {
  let houseStoreInv = [];
  houseCol.forEach((row) => {
    houseStoreInv.push(row.split(" "));
  });
  let storeInv = [];
  let houseRow = [];
  houseStoreInv.forEach((row) => {
    row.forEach((innerRow) => {
      if (innerRow.includes(".")) {
        houseRow.push(innerRow.split("."));
      }
    });
  });
  houseRow.forEach((rows) => {
    const supplierKey = rows[2];
    const qty = rows[3];
    if (supplierKey.toLowerCase() === "daus" && store === "dealsareus") {
      storeInv.push([supplierKey, qty]);
    } else if (supplierKey.toLowerCase() === "omkt" && store === "alliance") {
      storeInv.push([supplierKey, qty]);
    }
  });
  return storeInv;
}

function storeQtyUPCJSON(upcStoreJSON, store, supplierName) {
  let supplierList = [];
  let houseInv = [];
  upcStoreJSON.forEach((row) => {
    supplierList.push(row.supplier.split(" "));
    supplierList.forEach((supplierKey) => {
      supplierKey.forEach((supplierRow) => {
        if (supplierRow.includes(".")) {
          houseInv.push(supplierRow.split("."));
          houseInv.forEach((vals) => {
            if (
              row.store === store &&
              vals[2].toLowerCase() === supplierName.toLowerCase()
            ) {
              row.supplier = vals[2];
              row.qty = vals[3];
            }
          });
        }
      });
    });
  });
  return upcStoreJSON;
}
