import { GET_USER_TOKEN } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_USER_TOKEN:
      // create a new array containing "add" at the end
      // merge that array with state and de-duplicate it
      return {
        ...state,
        idToken: action.payload.idToken,
        userId: action.payload.userId,
      };
    default:
      return state;
  }
};
