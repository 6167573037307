import React from "react";
import { Modal, ModalHeader } from "semantic-ui-react";

function AppendMessage({ closeModal, rows, status, errors = [] }) {
  const duplicateIdLookup = {};

  // Building duplicateIdLookup
  errors.forEach((err) =>
    err.status === "This UPC is already in use."
      ? (duplicateIdLookup[err.id] = 1)
      : null
  );

  const rowIsDuplicate = (row) => {
    const rowId = row.DT_RowId.split("_")[1];
    return duplicateIdLookup[rowId];
  };

  // Returning p elements for all duplicate rows
  const listItems = rows.map((row) =>
    rowIsDuplicate(row) ? <p key={row.DT_RowId}>{row.product.upc}</p> : null
  );

  return (
    <Modal defaultOpen onClose={closeModal}>
      {status ? handleMessage(true) : <>{handleMessage(false, listItems)}</>}
    </Modal>
  );
}

const handleMessage = (status, rows) => {
  const failedMessage =
    "All rows failed, submission contained duplicate row(s). Edit duplicates and re-submit all rows: \n";
  const successMessage = "Success";
  return (
    <>
      <ModalHeader>Append Result</ModalHeader>
      <Modal.Content>
        <Modal.Description>
          {!status ? (
            <>
              <h3>{failedMessage}</h3>
              {rows}
            </>
          ) : (
            <p>{successMessage}</p>
          )}
        </Modal.Description>
      </Modal.Content>
    </>
  );
};

export default AppendMessage;
