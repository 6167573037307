export function extractTooltipOrAltText(htmlString) {
  // Try to match Tooltip pattern
  const tooltipRegex = /<Tooltip[^>]*>.*?<p>(.*?)<\/p>.*?<\/Tooltip>/s;
  const tooltipMatch = htmlString.match(tooltipRegex);

  if (tooltipMatch) {
    return tooltipMatch[1];
  }

  // If Tooltip pattern doesn't match, try to extract 'alt' property from the provided HTML string
  const altRegex = /alt="(.*?)"/;
  const altMatch = htmlString.match(altRegex);

  return altMatch ? altMatch[1] : null;
}

export function formatSQLColumnString(inputString) {
  // Split the input string into parts using the dot as a delimiter
  const [, column] = inputString.split(".");

  // Capitalize the first letter of each word and join them with a space
  const formattedString = column
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedString;
}
