/**
 * Splits and trims all given values from the bulk house input.
 * @param {string} csvInput
 * @param {string} eolCharacter The end of line character
 * @returns {array} Split and trimmed array of values.
 */
export const splitBulkHouseInput = (csvInput, eolCharacter) => {
  csvInput = csvInput.replace(/  +/g, " ");

  // Storing Price, UPC, Quantity as separate indexes
  let separatedItems = [];

  const splitOnComma = csvInput.split(",");

  // Handling initial price point to make loop logic simpler
  separatedItems.push(splitOnComma[0].trim());

  // Pushing rest onto the array, handling conjoined end of lines
  for (let i = 1; i < splitOnComma.length; i++) {
    const currentString = splitOnComma[i].trim();

    // Odd indexes aren't conjoined- can just be pushed
    if (i % 2 !== 0) {
      separatedItems.push(currentString);
      continue;
    }

    // Even indexes include EOL conjoined with start of newline
    // Must be split depending on what EOL character is present
    const unconjoinedEOLStrings = handleEOLSplit(
      currentString,
      eolCharacter
    ).map((str) => str.trim());
    separatedItems = [...separatedItems, ...unconjoinedEOLStrings];
  }

  // Removing any empty values
  return separatedItems.filter((str) => str.length > 0);
};

/**
 * Condenses a given array of sanitized/split bulk house values
 * into an array of 3 items per index, in the given order.
 * @param {array} splitHouseInput
 * @returns {array} Array of records ordered by Price, UPC, Quantity
 */
export const createHouseRecords = (splitHouseInput) => {
  const records = [];

  // Grabs items three at a time and inserts them into records array
  for (
    let i = 0, j = 1, k = 2;
    k < splitHouseInput.length;
    i += 3, j += 3, k += 3
  ) {
    const record = [splitHouseInput[i], splitHouseInput[j], splitHouseInput[k]];
    records.push(record);
  }

  return records;
};

const handleEOLSplit = (str, eolCharacter) =>
  eolCharacter === "\n" ? str.split(/\r?\n/) : str.split(" ");
