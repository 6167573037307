import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Segment, Table, Button } from "semantic-ui-react";

import { fetchTotals } from "../../../redux/actions";
import LoadingBox from "../LoadingBox";

class TotalsReport extends React.Component {
  state = {
    loading: false,
  };

  fetchDailyTotals() {
    this.setState({ loading: true });
    this.props
      .fetchTotals()
      .catch((err) => alert(err))
      .finally(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.fetchDailyTotals();
  }

  renderProducts() {
    const commaSeparatedFormat = Intl.NumberFormat("en-US");

    // eventually props.totals should be returned as an array, but we just convert it for now
    // for proper reporting, ptTotals lambda function needs rework
    const myArr = this.props.totals ? [this.props.totals] : [];
    return _.map(myArr, (ret, index) => {
      const date = new Date();

      return (
        <Table.Row key={date.toLocaleDateString()}>
          <Table.Cell>{date.toLocaleDateString()}</Table.Cell>
          <Table.Cell>{commaSeparatedFormat.format(ret.yom_sku)}</Table.Cell>
          <Table.Cell>{commaSeparatedFormat.format(ret.alliance)}</Table.Cell>
          <Table.Cell>{commaSeparatedFormat.format(ret.dealsareus)}</Table.Cell>
          <Table.Cell>
            {commaSeparatedFormat.format(ret.deepdiscount)}
          </Table.Cell>
          <Table.Cell>{commaSeparatedFormat.format(ret.rightstuf)}</Table.Cell>
          <Table.Cell>{commaSeparatedFormat.format(ret.walmart)}</Table.Cell>
          <Table.Cell>{commaSeparatedFormat.format(ret.wowhd)}</Table.Cell>
          <Table.Cell>{commaSeparatedFormat.format(ret.ccvideo)}</Table.Cell>
          <Table.Cell>{commaSeparatedFormat.format(ret.importcds)}</Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <Segment className="grid-box">
        <div
          style={{ padding: "24px 0", display: "flex", alignItems: "center" }}
        >
          <h2 style={{ margin: "0 24px 0 0" }}>Daily Totals</h2>

          <Button
            type="button"
            color="blue"
            icon="sync"
            title="Refresh data."
            onClick={() => this.fetchDailyTotals()}
          />
        </div>

        {this.state.loading ? (
          <LoadingBox />
        ) : (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Attempted</Table.HeaderCell>
                <Table.HeaderCell>Alliance</Table.HeaderCell>
                <Table.HeaderCell>Dealsareus</Table.HeaderCell>
                <Table.HeaderCell>Deepdiscount</Table.HeaderCell>
                <Table.HeaderCell>Rightstuf</Table.HeaderCell>
                <Table.HeaderCell>Walmart</Table.HeaderCell>
                <Table.HeaderCell>Wowhd</Table.HeaderCell>
                <Table.HeaderCell>CCVideo</Table.HeaderCell>
                <Table.HeaderCell>ImportCDs</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderProducts()}</Table.Body>
          </Table>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return { totals: state.reports.totals };
};

export default connect(mapStateToProps, { fetchTotals })(TotalsReport);
