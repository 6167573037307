import React from "react";

import { masterColumnSet } from "./damageColumns";

import DataTablesEditor from "../DatatablesEditor/DataTablesEditor";

import { baseURL } from "../../../apis/dvdlocator";
import { handleDamageSubmitErrors } from "./buttonHandlers";
import { appendUnixDate } from "../_genericButtonHandlers/appendUnixDate";

class Damage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchDelay: null,
      searchValue: this.props.match.params.search
        ? this.props.match.params.search
        : "",
      columns: masterColumnSet,
      colSets: [],

      fields: masterColumnSet,

      options: {
        dom: "Blrtip", // https://datatables.net/reference/option/dom
        processing: true,
        serverSide: true,
        select: true,
        paging: true,
        paginate: true,
        pageLength: 50,
        lengthChange: true,
        scrollX: true,
        scrollY: window.innerHeight - 200,
        scrollCollapse: true,
        autoWidth: true,

        // 	stateSave: true,
        colReorder: true,
        deferRender: true,
        order: [[1, "asc"]],
      },
    };

    this.dataTablesRef = React.createRef();
  }

  doesRowHaveValidWorkOrder(row) {
    const validWorkOrders = [".repair", ".return"];
    let isValid = false;
    validWorkOrders.forEach((str) =>
      row.damage?.work_order && row.damage.work_order.includes(str)
        ? (isValid = true)
        : null
    );
    return isValid;
  }

  /**
   * Updates a row's status using the datatables API.
   * @param {object} datatable Datatable API instance.
   * @param {*} value
   */
  setSelectedRowStatus(datatable, value) {
    const editor = this.dataTablesRef.current.editor;
    let rowCount = 0;
    const rowSelector = datatable.rows((index, data, node) => {
      if (!node.className.includes("selected")) return false;
      rowCount += 1;
      return this.doesRowHaveValidWorkOrder(data);
    });

    if (rowCount !== rowSelector.count())
      alert("Cannot set picked/lost on product with no work order.");

    editor
      .edit(rowSelector.nodes(), false)
      .val("damage.status", value)
      .submit(null, (err) => handleDamageSubmitErrors(datatable, err));
  }

  customButtons = () => {
    return [
      {
        text: "<span title='Refresh Table'>Refresh</span>",
        action: (e, dt, node, config) => {
          dt.ajax.reload();
        },
      },
      {
        extend: "collection",
        text: "<span title='Append to Table'>Append</span>",
        buttons: [
          {
            text: "<span title='The date the product was reordered from a supplier (Manually added by Analyst)'>Date Reordered</span>",
            action: (e, dt, node, config) => {
              let editor = appendUnixDate(
                dt,
                this.dataTablesRef.current.editor,
                ["damage"],
                ["date_reordered"]
              );

              editor && editor.submit();
            },
          },
          {
            text: "<span title='The product was located in our facility and removed from its location to be moved in the next step of the work order.'>Picked</span>",
            action: (e, dt, node, config) => {
              this.setSelectedRowStatus(dt, "X");
            },
          },
          {
            text: "<span title='The product was unable to be located in our facility at its assigned location.'>Lost</span>",
            action: (e, dt, node, config) => {
              this.setSelectedRowStatus(dt, "Y");
            },
          },
        ],
      },
    ];
  };

  componentDidMount() {
    // run initial search if one given
    if (this.state.searchValue) {
      this.dataTablesRef.current.search(this.state.searchValue);
    }
  }
  // search field onChange event handler
  onChangeSearch = (e) => {
    const { value } = e.target;
    const searchValue = value;
    this.setState({ searchValue: searchValue });
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
      this.dataTablesRef.current.search("");
    }, 1000);
  };

  render() {
    const { fields, columns, options, searchValue } = this.state;

    return (
      <div>
        <div className="ui input">
          <input
            value={searchValue}
            onChange={this.onChangeSearch}
            autoComplete={"off"}
            type="text"
            placeholder="Search ..."
          />
        </div>
        <DataTablesEditor
          key={this.state.render}
          ref={this.dataTablesRef}
          ajax={{
            url: `${baseURL}/datatables/damage`,
            contentType: "application/json",
            type: "POST",
            data: (d) => {
              d.smartSearch = this.state.searchValue;
              return JSON.stringify(d);
            },
          }}
          editorAjax={{
            url: `${baseURL}/datatables/damage`,
            contentType: "application/json",
            type: "POST",
            data: function (d) {
              return JSON.stringify(d);
            },
          }}
          columns={columns}
          options={options}
          fields={fields}
          createButton={false}
          editButton={true}
          removeButton={true}
          customButtons={this.customButtons}
          exportButton={true}
          colVis={false}
        />
      </div>
    );
  }
}

export default Damage;
