import React, { useState } from "react";
import { Modal, Checkbox, Button } from "semantic-ui-react";

const FilterOptions = ({ isOpen, onClose, filterOptions, updateFilters }) => {
  const [checkboxStates, setCheckboxStates] = useState(filterOptions);

  const handleCheckboxChange = (event, data) => {
    const { name, checked } = data;
    setCheckboxStates({ ...checkboxStates, [name]: checked });
  };

  return (
    <Modal open={isOpen} onClose={onClose} size="tiny">
      <Modal.Header>Extra Search Filters</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Checkbox
            label="Retired Products"
            name="retired"
            checked={checkboxStates.retired}
            onChange={handleCheckboxChange}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          primary
          onClick={() => {
            updateFilters(checkboxStates);
            onClose();
          }}
        >
          Apply
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default FilterOptions;
