import React from "react";
import _ from "lodash";
import { Icon, Item, Popup } from "semantic-ui-react";
import BigNumber from "bignumber.js";
import { formatToPoundsOunces } from "../../formatting/weightFormat";
import { removeHtmlFromString } from "../../utilities/removeHtmlFromString";

class PricetoolLog extends React.Component {
  renderDirective(ret) {
    const top = ret.top.reduce(
      (acc, x) => (acc === null ? [x] : [acc, <br />, x]),
      null
    );
    const output = ret.output.reduce(
      (acc, x) => (acc === null ? [x] : [acc, <br />, x]),
      null
    );

    return (
      <Item>
        <Item.Content>
          <Item.Header style={{ color: "white" }}>Directive</Item.Header>
          <Item.Meta>
            <div style={{ color: "white" }}>{top}</div>
          </Item.Meta>
          <Item.Description>
            <p style={{ color: "white" }}>{output}</p>
          </Item.Description>
        </Item.Content>
      </Item>
    );
  }

  renderAmazonPricing(fee) {
    console.log(fee);
    const {
      title,
      weight,
      componentQty,
      mediaTypes,
      margin,
      profit,
      proceeds,
      roi,
      fees,
      marginZero,
      marginFifteen,
      marginThirty,
      totalCost,
      price,
    } = fee;

    const zeroReturn = this.formatMarginTextRow(0, marginZero);
    const fifteenReturn = this.formatMarginTextRow(0.15, marginFifteen);
    const thirtyReturn = this.formatMarginTextRow(0.3, marginThirty);

    return (
      <Item>
        <Item.Content>
          <Item.Header style={{ color: "white" }}>
            Amazon Pricing Log
          </Item.Header>
          <Item.Meta>
            <div style={{ marginTop: "2px" }}>
              <b style={{ color: "white" }}>
                Title: {removeHtmlFromString(title)}
              </b>
            </div>
            <div style={{ marginTop: "2px" }}>
              <b
                style={{ color: "white" }}
              >{`${componentQty} ${mediaTypes} ${formatToPoundsOunces(
                weight
              )}`}</b>
            </div>
            <br />
            <div style={{ marginTop: "2px" }}>
              <b style={{ color: "white" }}>
                Listing price:{" "}
                {price ? this.formatCurrencyFromDollars(price) : "---"}
              </b>
            </div>
            <div style={{ marginTop: "2px" }}>
              <b style={{ color: "white" }}>
                COGS: {this.formatCurrencyFromDollars(fees.cogs)}
              </b>
            </div>
            <div style={{ marginTop: "2px" }}>
              <b style={{ color: "white" }}>
                Estimated amz fees: {this.formatSumAmazonFees(fees)}
              </b>
            </div>

            <div style={{ marginTop: "2px" }}>
              <b style={{ color: "white" }}>
                Total cost and fees: ${totalCost}{" "}
                <Popup
                  trigger={
                    <Icon
                      name="info circle"
                      color="yellow"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  content={
                    <div style={{ whiteSpace: "pre-line" }}>
                      <i>Cost Breakdown{`\n\n`}</i>
                      {this.formatFeesForInfoBox(fees, price)}
                    </div>
                  }
                  wide
                  openOnTriggerMouseEnter={false}
                  openOnTriggerClick={true}
                  closeOnTriggerMouseLeave={false}
                  position="top center"
                />
              </b>
            </div>

            <div style={{ marginTop: "2px" }}>
              <b style={{ color: "white" }}>{`Margin: ${
                this.formatPercent(margin) || "---"
              }`}</b>
            </div>
            <div style={{ marginTop: "2px" }}>
              <b style={{ color: "white" }}>{`Profit: ${profit || "---"}`}</b>
            </div>
            <div style={{ marginTop: "2px" }}>
              <b style={{ color: "white" }}>{`Proceeds: ${
                proceeds || "---"
              }`}</b>
            </div>
            <div style={{ marginTop: "2px" }}>
              <b style={{ color: "white" }}>{`ROI: ${
                this.formatPercent(roi) || "---"
              }`}</b>
            </div>
          </Item.Meta>
          <Item.Description>
            <div style={{ whiteSpace: "pre", color: "white" }}>
              % | Price | Profit
            </div>
            <div style={{ color: "white" }}>{zeroReturn}</div>
            <div style={{ color: "white" }}>{fifteenReturn}</div>
            <div style={{ color: "white" }}>{thirtyReturn}</div>
          </Item.Description>
        </Item.Content>
      </Item>
    );
  }

  formatSumAmazonFees(fees) {
    const inboundFee = new BigNumber(fees.inboundFee);
    const storageFee = new BigNumber(fees.storageFee);
    const fbaFee = new BigNumber(fees.fbaFee);
    const referralFee = new BigNumber(fees.referralFee);
    const variableFee = new BigNumber(fees.variableFee);

    return `$${inboundFee
      .plus(storageFee)
      .plus(fbaFee)
      .plus(referralFee)
      .plus(variableFee)
      .toFixed(2)}`;
  }

  formatFeesForInfoBox(fees, price) {
    const cogs = new BigNumber(fees.cogs);
    const outboundFee = new BigNumber(fees.outboundFee);
    const inboundFee = new BigNumber(fees.inboundFee);
    const storageFee = new BigNumber(fees.storageFee);
    const yomHandlingFee = new BigNumber(fees.yomHandlingFee);
    const yomHandlingFeeAdjustment = new BigNumber(
      fees.yomHandlingFeeAdjustment
    );
    const fbaFee = new BigNumber(fees.fbaFee);
    const referralFee = new BigNumber(fees.referralFee);
    const variableFee = new BigNumber(fees.variableFee);

    const yomFees = yomHandlingFee
      .plus(outboundFee)
      .plus(yomHandlingFeeAdjustment);

    const amzConstantFees = fbaFee
      .plus(inboundFee)
      .plus(storageFee)
      .plus(variableFee);

    const totalConstantFees = yomFees.plus(amzConstantFees).plus(cogs);
    const totalCost = totalConstantFees.plus(referralFee);

    return (
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "4px" }}>
          <b>COGS:</b>
          <br />
          <br />
          <b>YOM fees:</b>
          <br />
          <div style={{ paddingLeft: "20px" }}>
            YOM handling fee:
            <br />
            Adjusted handling fee:
            <br />
            Outbound shipping fee:
            <br />
          </div>
          <br />
          <b>Amazon constant fees:</b>
          <br />
          <div style={{ paddingLeft: "20px" }}>
            Inbound fee:
            <br />
            Monthly storage fee:
            <br />
            Fulfillment fee:
            <br />
            Variable fee:
            <br />
          </div>
          <br />
          <b>Total constant fees:</b>
          <br />
          <br />
          <b>Amazon 15% referral fee:</b>
          <br />
          <i>{`(${price ? `$${price}` : "no"} listing price)`}</i>
          <br />
          <br />
          <b>Total costs and fees:</b>
        </div>
        <div style={{ textAlign: "right" }}>
          ${cogs.toFixed(2)}
          <br />
          <br />${yomFees.toFixed(2)}
          <div style={{ paddingLeft: "20px" }}>
            ${yomHandlingFee.toFixed(2)}
            <br />
            {yomHandlingFeeAdjustment.isEqualTo(0)
              ? "---"
              : `$${yomHandlingFeeAdjustment.toFixed(2)}`}
            <br />${outboundFee.toFixed(2)}
            <br />
          </div>
          <br />${amzConstantFees.toFixed(2)}
          <div style={{ paddingLeft: "20px" }}>
            ${inboundFee.toFixed(2)}
            <br />${storageFee.toFixed(2)}
            <br />${fbaFee.toFixed(2)}
            <br />${variableFee.toFixed(2)}
          </div>
          <br />${totalConstantFees.toFixed(2)}
          <br />
          <br />
          {referralFee.isEqualTo(0) ? "---" : `$${referralFee.toFixed(2)}`}
          <br />
          <br />
          <br />${totalCost.toFixed(2)}
        </div>
      </div>
    );
  }

  formatMarginTextRow = (percent, marginPrice) => {
    const usdFormatMargin = this.formatCurrencyFromDollars(marginPrice);
    const marginProfit = new BigNumber(marginPrice * percent).toFixed(2);
    const usdFormatProfit = this.formatCurrencyFromDollars(marginProfit);
    return `${percent * 100}% | ${usdFormatMargin} | ${usdFormatProfit}`;
  };

  formatCurrencyFromCents = (data) => {
    if (data === undefined || data === null) return;

    return `$${(data / 100).toFixed(2)}`;
  };

  formatCurrencyFromDollars = (data) => {
    if (data === undefined || data === null) return;

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(data);
  };

  formatPercent = (data) => {
    if (data !== null && data !== "") {
      const numberData = parseFloat(data);
      if (!isNaN(numberData)) {
        return `${numberData.toFixed(1)}%`;
      }
    }
    return null; // or return a default value or message indicating invalid input
  };

  render() {
    return (
      <div style={{ padding: "7px 14px" }}>
        {this.props.clearedTimestamp && (
          <span style={{ color: "white" }}>
            Last Cleared: {this.props.clearedTimestamp}
          </span>
        )}
        {_.map(this.props.content, (rec, i) => {
          return (
            <Item.Group divided key={i}>
              {rec.log_type === "ap"
                ? this.renderAmazonPricing(rec)
                : this.renderDirective(rec)}
            </Item.Group>
          );
        })}
      </div>
    );
  }
}
export default PricetoolLog;
