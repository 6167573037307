import _ from "lodash";
import dvdlocator from "../../apis/dvdlocator";
import { DAMAGE_INVENTORY, RESOLVED_INVENTORY } from "./types";

const getUpcSets = (products) => {
  let repairedUpcs = new Set();
  let returnUpcs = new Set();

  products.forEach((entry) => {
    if (entry.category === "Repaired") {
      repairedUpcs = repairedUpcs.add(entry.UPC);
    } else returnUpcs = returnUpcs.add(entry.UPC);
  });

  return [repairedUpcs, returnUpcs];
};

const generatePromiseHelper = (repairedUpcs, returnUpcs) => {
  const repairedPromise =
    repairedUpcs.size > 0
      ? dvdlocator.post("/inventory/repaired", {
          upcs: Array.from(repairedUpcs),
        })
      : { data: [] };
  const returnPromise =
    returnUpcs.size > 0
      ? dvdlocator.post("/inventory/return", { upcs: Array.from(returnUpcs) })
      : { data: [] };

  return [repairedPromise, returnPromise];
};

export const resolveInventory = () => async (dispatch, getState) => {
  const products = _.without(getState().products, undefined);

  const [repairedUpcs, returnUpcs] = getUpcSets(products);
  const [repairedPromise, returnPromise] = generatePromiseHelper(
    repairedUpcs,
    returnUpcs
  );

  Promise.allSettled([repairedPromise, returnPromise]).then((values) => {
    let runReq = false;
    let sessionLog = [];
    const { userId } = getState().auth;
    values.forEach((res) => {
      if (res.status === "fulfilled") {
        const { data } = res.value;
        data["userId"] = userId;
        runReq = true;
        sessionLog = [...sessionLog, ...data];
      }

      if (res.status === "rejected") {
        console.log(res.reason);
        alert(res.reason);
      }
    });
    const sessionLogBody = {};
    sessionLogBody["items"] = sessionLog.filter(
      (row) => row.status !== "TEST ROW SUBMISSION"
    );
    sessionLogBody.items.forEach((item) => {
      item["userId"] = userId;
    });
    console.log(sessionLogBody);

    if (runReq) {
      dvdlocator.put("/nosql/session-log", sessionLogBody);
    }

    dispatch({
      type: RESOLVED_INVENTORY,
      payload: sessionLog,
    });
  });
};

export const damageInventory = () => async (dispatch, getState) => {
  // remove undefineds
  const products = _.without(getState().products, undefined);

  const upcs = [];
  const damageShelves = [];
  const damageIds = [];
  const damageNotes = [];

  products.forEach((product) => {
    upcs.push(product.UPC);
    damageShelves.push(product.damageShelf);
    damageIds.push(product.damageReason);
    damageNotes.push(product.damageNote);
  });

  let requestBody = {
    upcs,
    damageShelves: damageShelves,
    damage_ids: damageIds,
    damage_notes: damageNotes,
  };

  const response = await dvdlocator.post("/inventory/damage", requestBody);

  const body = response.data;
  const { userId } = getState().auth;

  body.forEach((element) => {
    element.userId = userId;
  });

  const sessionLogBody = {
    items: body,
  };

  const newSessionLog = {};
  newSessionLog.items = sessionLogBody.items.filter((row) => row);
  dvdlocator
    .put("/nosql/session-log", newSessionLog)
    .catch((err) => console.log(err));

  return dispatch({
    type: DAMAGE_INVENTORY,
    payload: body,
  });
};
