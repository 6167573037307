export const FETCH_SESSION_LOG = "FETCH_SESSION_LOG";
export const ADD_INVENTORY = "ADD_INVENTORY";
export const ONO_INVENTORY = "ONO_INVENTORY";
export const SUPPLIER_INVENTORY = "SUPPLIER_INVENTORY";
export const SUB_INVENTORY = "SUB_INVENTORY";
export const MARK_INVENTORY = "MARK_INVENTORY";
export const DAMAGE_INVENTORY = "DAMAGE_INVENTORY";
export const HOUSE_INVENTORY = "HOUSE_INVENTORY";
export const CLEAR_PRODUCT_QUEUE = "CLEAR_PRODUCT_QUEUE";
export const INIT_INVENTORY_REQUEST = "INIT_INVENTORY_REQUEST";
export const SET_SUPPLIER_TYPE = "SET_SUPPLIER_TYPE";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const RESOLVED_INVENTORY = "RESOLVED_INVENTORY";

export const GET_USER_TOKEN = "GET_USER_TOKEN";

export const SIGN_UP = "SIGN_UP";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const FETCH_COLUMN_SETS = "FETCH_COLUMN_SETS";
export const FETCH_TOTALS = "FETCH_TOTALS";
export const FETCH_WEEKLY_INVENTORY = "FETCH_WEEKLY_INVENTORY";
export const SUB_REFRESH_QTY = "SUB_REFRESH_QTY";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
