import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./styles.css";
import { Input, Modal, Button, Label, Icon } from "semantic-ui-react";
import { addNotification } from "../../../redux/actions/notificationActions";

function CreateNewFamily({ createFamily, open, close }) {
  const dispatch = useDispatch();
  const [familyName, setFamilyName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (familyName) => {
    if (familyName.includes(","))
      return setError("Family name cannot include comma.");

    setSubmitting(true);

    createFamily(familyName)
      .then(() => {
        setFamilyName("");
        close();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 409) {
            return setError("This family name already exists.");
          }
        }
        dispatch(
          addNotification({
            text: "Error creating new family.",
            icon: <Icon color="red" name="warning circle" />,
          })
        );
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <Modal onClose={close} open={open} size="tiny">
      <Modal.Header>Create New Family</Modal.Header>

      <Modal.Content>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "32px",
          }}
        >
          {error && (
            <span style={{ color: "red", marginBottom: "8px" }}>{error}</span>
          )}
          <Label>Enter Family Name</Label>
          <Input
            placeholder="Family name"
            size="big"
            disabled={submitting}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(familyName);
              }
            }}
            onChange={(e) => {
              if (error) setError("");
              setFamilyName(e.target.value);
            }}
            value={familyName}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={close}>Cancel</Button>
          <Button
            primary
            onClick={() => handleSubmit(familyName)}
            disabled={submitting}
          >
            Create
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default CreateNewFamily;
