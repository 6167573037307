import { Hub, Logger } from 'aws-amplify';
import dvdlocator from '../../apis/dvdlocator';
import { Auth } from 'aws-amplify';

const logger = new Logger('Logger', 'INFO');

export const listener = (data) => {

  switch (data.payload.event) {
    case 'signIn':
      logger.info('user signed in');
      checkUser();
      break;
    case 'signUp':
      logger.info('user signed up');
      break;
    case 'signOut':
      logger.info('user signed out');
      break;
    case 'signIn_failure':
      logger.info('user sign in failed');
      break;
    case 'configured':
      logger.info('the Auth module is configured');
      break;
    default:
      logger.error('Something went wrong, look at data object', data);
  }
}
Hub.listen('auth', listener);

async function checkUser() {
  let user = await Auth.currentAuthenticatedUser();  
  let userId =(user.attributes.email);
  console.log(userId);
  dvdlocator.post('/nosql/create-column-sets', userId );
}
