import amazon from "../../assets/icons/buybox/amazon.png";
import yom from "../../assets/icons/buybox/yom.png";
import fba from "../../assets/icons/buybox/fba.png";
import ineligible from "../../assets/icons/buybox/ineligible.png";
import merchant from "../../assets/icons/buybox/merchant.png";
import noOffers from "../../assets/icons/buybox/no_offers.png";
import suppressed from "../../assets/icons/buybox/suppressed.png";
import tempOutOfStock from "../../assets/icons/buybox/temp_out_of_stock.png";
import used from "../../assets/icons/buybox/used.png";
import fast from "../../assets/icons/buybox/fast.png";
import medium from "../../assets/icons/buybox/medium.png";
import slow from "../../assets/icons/buybox/slow.png";

export const BUYBOX_SELLER_IMAGE = {
  amazon: amazon,
  yom: yom,
};

export const BUYBOX_CONDITION_IMAGE = {
  amazon: amazon,
  fba: fba,
  merchant: merchant,
  "ineligible - new": ineligible,
  "no offers": noOffers,
  suppressed: suppressed,
  "temp out of stock": tempOutOfStock,
  used: used,
};

export const BUYBOX_SHIP_SPEED_IMAGE = {
  fast: fast,
  medium: medium,
  slow: slow,
};
