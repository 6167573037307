import React from "react";
import { addProduct, addProducts } from "../../redux/actions";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import "./Inventory.css";
import InputGroups from "./inputGroups";

export const ProductForm = ({ title, addProduct, addProducts }) => {
  return (
    <Segment padded className="grid-box">
      <InputGroups
        title={title}
        addProduct={addProduct}
        addProducts={addProducts}
      />
    </Segment>
  );
};

export default connect(null, { addProduct, addProducts })(ProductForm);
