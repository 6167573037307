import React from "react";
import { Grid } from "semantic-ui-react";
import _ from "lodash";

import InventoryForm from "./InventoryForm";
import ProductForm from "./ProductForm";
import SessionLogList from "./SessionLogList";
import { processQueueFields } from "./processQueue/tableFields";
import "./Inventory.css";
import { sessionLogFieldSets } from "./sessionLog/tableFields";

// handles overall page styling/formatting for inventory tool
// rendered directly to page
export const Inventory = ({ match }) => {
  // name of tool being used. if no param given, default to: Add
  let title = match.params.title
    ? _.startCase(_.toLower(match.params.title))
    : "Add";
  if (title === "Update Ono") title = "Update ONO";

  // determine what inputs the form will have
  // amount field used for adding multiple rows with the same values
  // amount field not used by api endpoints
  const { textInputs, checkBoxes, queueFields } = (() => {
    switch (title) {
      case "Add":
        return {
          queueFields: processQueueFields[title],
        };
      case "Sub":
        return {
          queueFields: processQueueFields[title],
        };
      case "Damage":
        return {
          queueFields: processQueueFields[title],
        };

      case "Resolve":
        return {
          queueFields: processQueueFields[title],
        };
      case "House":
        return {
          checkBoxes: [{ text: "override", dataKey: "override" }],
          queueFields: processQueueFields[title],
        };
      case "Mark":
        return {
          queueFields: processQueueFields[title],
        };
      case "Update ONO":
        return {
          queueFields: processQueueFields[title],
        };
      case "Supplier Data":
        return {
          queueFields: processQueueFields[title],
        };
      default:
        title = "Add";
        return {
          queueFields: processQueueFields[title],
          checkBoxes: [{ text: "override", dataKey: "override" }],
        };
    }
  })();

  return (
    <Grid padded>
      <Grid.Column width={8}>
        <div>
          <h1>{title}</h1>
        </div>
      </Grid.Column>
      <Grid.Column width={8}>
        <div>
          <h1>Session Log</h1>
        </div>
      </Grid.Column>
      <Grid.Column width={8}>
        <ProductForm
          textInputs={textInputs}
          checkBoxes={checkBoxes}
          title={title}
        />
        <h1>In Process Queue</h1>
        <InventoryForm
          title={title}
          fieldSet={checkBoxes ? [...queueFields, ...checkBoxes] : queueFields}
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <div>
          <SessionLogList fieldSet={sessionLogFieldSets[title]} />
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default Inventory;
