import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "semantic-ui-react";
import { removeNotification } from "../../redux/actions/notificationActions";
import BottomRightNotification from "./Notification";

const Notifications = () => {
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      if (notifications.length < 1) return;
      const notificationId = notifications[0].id;
      dispatch(removeNotification(notificationId));
    }, 3000);
  }, [notifications, dispatch]);

  return (
    <div style={{ position: "absolute" }}>
      <Container
        style={{
          width: "400px",
          position: "fixed",
          bottom: 0,
          right: 0,
          marginBottom: "1rem",
          marginRight: "1rem",
          zIndex: 99999,
        }}
      >
        {notifications.map((notification, i) => (
          <BottomRightNotification
            key={i}
            icon={notification.icon}
            text={notification.text}
          />
        ))}
      </Container>
    </div>
  );
};

export default Notifications;
