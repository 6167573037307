import $ from "jquery";
import ReactDOM from "react-dom";
import React from "react";
import { Provider } from "react-redux";
import { store } from "../../..";
import AddFamilyPopups from "./Custom/AddFamiliesPopups";

/**
 * Emits the "close" event using the editor when the form's background modal is clicked.
 * There seems to be a bug where the background modal doesn't automatically do this so this is a custom fix.
 * @param {*} editor
 */
export const emitCloseEventOnBackgroundClick = (editor) => {
  $("body").on(
    "click",
    ".ui.dimmer.modals.page.transition.visible.active",
    (e) => {
      if (!e.target.matches(".ui.dimmer.modals.page.transition.visible.active"))
        return;

      if (editor.display()) return editor.close();
    }
  );
};

export const attachFamiliesInputOnCreate = (editor) => {
  editor.on("opened", function () {
    const isDuplicateForm = document
      .querySelector(".DTE_Header_Content")
      ?.textContent.includes("Duplicate");

    if (editor.mode() !== "create" || isDuplicateForm) return;
    if (document.getElementById("pt-family-custom-container")) return;

    if (!editorFieldExists(editor, "custom_add_families")) {
      editor.add({ type: "hidden", name: "custom_add_families" }, null);
    }

    const containerDiv = document.createElement(`div`);
    containerDiv.setAttribute("id", "pt-family-custom-container");

    const formContentContainer = document
      .getElementsByClassName("DTE_Action_Create")[0]
      .getElementsByClassName("DTE_Form_Content")[0];

    formContentContainer.prepend(containerDiv);

    ReactDOM.render(
      <Provider store={store}>
        <AddFamilyPopups editor={editor} />
      </Provider>,
      containerDiv
    );
  });
};

const editorFieldExists = (editor, fieldName) => {
  const fields = editor.fields();

  let exists = false;

  fields.forEach((field) => {
    if (field === fieldName) exists = true;
  });

  return exists;
};
