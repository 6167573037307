import {
  FETCH_SESSION_LOG,
  FETCH_TOTALS,
  FETCH_WEEKLY_INVENTORY,
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_SESSION_LOG:
      return { ...state, inventoryLog: action.payload };
    case FETCH_TOTALS:
      return { ...state, ...{ totals: action.payload } };
    case FETCH_WEEKLY_INVENTORY:
      return { ...state, inventoryLog: action.payload };
    default:
      return state;
  }
};
