import React, { useRef, useState } from "react";
import {
  Modal,
  Dropdown,
  Form,
  Button,
  Loader,
  Segment,
  Dimmer,
} from "semantic-ui-react";
import axios from "../../../../../../apis/dvdlocator";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as XLSX from "xlsx";

const SupplierDataCSVModal = ({ closeModal }) => {
  const fileInputRef = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const formik = useFormik({
    initialValues: {
      supplier: "",
      file: null,
    },
    validationSchema: Yup.object({
      supplier: Yup.string().required("Please select a supplier"),
      file: Yup.mixed().required("Please select a file"),
    }),
    onSubmit: (values) => {
      setShowConfirmation(true);
    },
  });

  const supplierOptions = [
    { key: "mila", value: "mila", text: "Mila" },
    {
      key: "discount_entertainment",
      value: "discount_entertainment",
      text: "Discount Entertainment",
    },
    { key: "gruv", value: "gruv", text: "Gruv" },
    { key: "wildcard1", value: "wildcard1", text: "Wildcard 1" },
    { key: "wildcard2", value: "wildcard2", text: "Wildcard 2" },
  ];

  const handleFileChange = (event) => {
    let file = event.target.files[0];

    formik.setFieldValue("file", file);
  };

  const convertXlsxToTsv = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const csvData = XLSX.utils.sheet_to_csv(worksheet, {
          raw: false,
          defval: null,
          strip: true,
          FS: "\t",
        });

        // Create a new File object with the CSV data and the correct filename and content type
        const csvFilename = `${file.name.split(".")[0]}.tsv`;
        const csvFile = new File([csvData], csvFilename, {
          type: "text/tsv;charset=utf-8",
        });
        resolve(csvFile);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleConfirmation = async () => {
    setSubmissionError("");
    setSubmissionSuccess(false);
    setProcessing(true);
    try {
      const file = formik.values.file;

      // Check if the file is an XLSX file
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      let fileToSend = file;

      if (isXlsx) {
        console.log("Converting to CSV");
        // Converting XLSX to CSV
        fileToSend = await convertXlsxToTsv(file);
      }

      // Creating form data object and sending to API
      const formData = new FormData();

      formData.append("supplier", formik.values.supplier);
      formData.append("file", fileToSend);

      await axios.post(`/supplier-csv-upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setSubmissionSuccess(true);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // Handle 400 error
        setSubmissionError(err.response.data.message);
      } else {
        // Handle other errors
        setSubmissionError("Encountered an error submitting form.");
      }
      setShowConfirmation(false);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal defaultOpen onClose={closeModal}>
      <Modal.Header>Upload Supplier Data</Modal.Header>

      <Modal.Content>
        <p>
          Upload a TSV/XSLX file containing price and inventory data from a
          supplier.
        </p>
        <p>
          <i>Must include columns named 'sku', 'qty', and 'price'.</i>
        </p>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Field>
            <label>Supplier</label>
            <Dropdown
              placeholder="Select Supplier"
              fluid
              selection
              options={supplierOptions}
              value={formik.values.supplier}
              onChange={(e, { value }) =>
                formik.setFieldValue("supplier", value)
              }
              error={formik.errors.supplier && formik.touched.supplier}
            />
            {formik.errors.supplier && formik.touched.supplier && (
              <div className="ui pointing red basic label">
                {formik.errors.supplier}
              </div>
            )}
          </Form.Field>
          <Form.Field>
            <label>File</label>
            <Button
              as="label"
              htmlFor="file-input"
              content={
                formik.values.file ? formik.values.file.name : "Select File"
              }
              labelPosition="left"
              icon="file"
              error={
                formik.errors.file && formik.touched.file
                  ? formik.errors.file
                  : ""
              }
            />
            <input
              accept=".tsv,.xlsx"
              type="file"
              id="file-input"
              hidden
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            {formik.errors.file && formik.touched.file && (
              <div className="ui pointing red basic label">
                {formik.errors.file}
              </div>
            )}
          </Form.Field>
        </Form>
        {submissionError && (
          <div className="ui pointing red basic label">{submissionError}</div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          content="Cancel"
          onClick={closeModal}
          floated="left"
        />

        <Button
          type="button"
          content="Upload"
          labelPosition="right"
          icon="cloud upload"
          onClick={formik.handleSubmit}
          positive
        />
      </Modal.Actions>

      <Modal
        open={showConfirmation}
        size="tiny"
        onClose={() => setShowConfirmation(false)}
      >
        {processing ? (
          <Segment
            style={{
              minHeight: "125px",
            }}
          >
            <Dimmer active inverted>
              <Loader
                inverted
                content={
                  <>
                    <p>Processing Upload</p>
                    <p>This may take a moment</p>
                  </>
                }
              />
            </Dimmer>
          </Segment>
        ) : submissionSuccess ? (
          <>
            <Modal.Header>Upload Complete</Modal.Header>
            <Modal.Content>
              <p>
                File has been successfully uploaded and has begun processing.
                Processing may take 30 seconds to a few minutes depending on
                size of the file. You can close this window now.
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                positive
                onClick={() => {
                  setShowConfirmation(false);
                  setSubmissionSuccess(false);
                }}
                content="Okay"
                labelPosition="right"
                icon="checkmark"
              />
            </Modal.Actions>
          </>
        ) : (
          <>
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Content>
              <p>
                Uploading this file will erase existing data for the selected
                supplier. Are you sure you want to proceed?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                negative
                onClick={() => setShowConfirmation(false)}
                content="Cancel"
              />
              <Button
                positive
                onClick={handleConfirmation}
                content="Confirm"
                labelPosition="right"
                icon="checkmark"
              />
            </Modal.Actions>
          </>
        )}
      </Modal>
    </Modal>
  );
};

export default SupplierDataCSVModal;
