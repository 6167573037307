import React, { useEffect } from "react";
import { useState } from "react";
import {
  Accordion,
  Card,
  Divider,
  Dropdown,
  Grid,
  Icon,
  List,
  Popup,
  Segment,
} from "semantic-ui-react";
import axios from "../../../apis/dvdlocator";
import LoadingBox from "../LoadingBox";

export default function OvernightTaskDashboard() {
  const [stage, setStage] = useState("prod");
  const [error, setError] = useState("");
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  let successfulTasks = 0;

  tasks.forEach((task) => {
    const latestTask = task.runs[0];
    if (latestTask.result === "Success") {
      successfulTasks += 1;
    }
  });

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? null : index;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const ecsServerDomains = {
      "react.dvdlocator.com": "https://api.pt-ecs-task-info-prod.com",
      "preprod.dvdlocator.com": "https://api.pt-ecs-task-info-dev.com",
      "test.dvdlocator.com": "https://api.pt-ecs-task-info-dev.com",
      localhost: "https://api.pt-ecs-task-info-dev.com",
    };

    setError("");
    setTasks([]);
    setLoading(true);

    const hostname = window.location.hostname;
    const domainName = ecsServerDomains[hostname];

    axios
      .get(`${domainName}/tasks`, { params: { stage } })
      .then((res) => {
        const tasks = res.data;
        tasks.sort((a, b) => a.task_name.localeCompare(b.task_name)); // alphabetical sort
        setTasks(tasks.filter((task) => task.runs.length > 0)); // using only tasks that have completed one run
      })
      .catch((err) => setError(JSON.stringify(err)))
      .finally(() => setLoading(false));
  }, [stage]);

  return (
    <Segment className="grid-box">
      {/* Header Container */}
      <div
        style={{
          width: "800px",
          display: "flex",
          gap: "20px",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <h2 style={{ marginBottom: 0 }}>Overnight Tasks</h2>
        <Dropdown
          title="Select which environment's tasks to view"
          selection
          value={stage}
          onChange={(e, d) => setStage(d.value)}
          options={[
            {
              key: "Production",
              text: "Production",
              value: "prod",
            },
            {
              key: "Preproduction",
              text: "Preproduction",
              value: "preprod",
            },
            {
              key: "Development",
              text: "Development",
              value: "dev",
            },
          ]}
        />

        {/* Healthy Task Count */}
        {tasks.length > 0 && (
          <div>
            {successfulTasks === tasks.length ? (
              <div>
                <Icon name="check" color="green" />

                <span style={{ color: "green" }}>
                  {successfulTasks} / {tasks.length} Successful tasks (100%)
                </span>
              </div>
            ) : (
              <div>
                <Icon
                  name="exclamation circle"
                  color="yellow"
                  title="A task did not complete successfully"
                />

                <span style={{ color: "grey" }}>
                  {successfulTasks} / {tasks.length} Successful tasks
                </span>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Error Container */}
      {error && <div style={{ color: "red" }}>{error}</div>}

      {/* Loading Container */}
      {loading && <LoadingBox />}

      {/* Task Container */}
      <Grid columns={4}>
        {tasks.map((task) => (
          <Grid.Column key={task.id}>
            <Card fluid>
              <Card.Content
                style={
                  task.runs[0].result === "Failed"
                    ? { backgroundColor: "#ffb6c1" }
                    : null
                }
              >
                <Card.Header style={{ marginBottom: "20px" }}>
                  {task.runs[0].result === "Success" && (
                    <Icon name="check" color="green" />
                  )}
                  {task.runs[0].result === "Failed" && (
                    <Icon name="exclamation circle" color="red" />
                  )}
                  {task.runs[0].result === "ManuallyStopped" && (
                    <Icon
                      name="hand pointer"
                      color="grey"
                      title="Task was manually stopped."
                    />
                  )}

                  {task.task_name}
                </Card.Header>
                <Card.Meta style={{ marginBottom: "20px" }}>
                  <div>
                    <p>
                      Average Run Time: {task.averageRunTimeSeconds} seconds
                    </p>
                    <p>
                      Last run completed:{" "}
                      {new Date(
                        task.runs[0].task_stop_unix_timestamp * 1000
                      ).toLocaleString()}
                    </p>
                  </div>
                </Card.Meta>
                <Accordion styled>
                  <Accordion.Title
                    active={activeIndex === task.id}
                    index={task.id}
                    onClick={handleAccordionClick}
                  >
                    <Icon name="dropdown" />
                    View Runs
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === task.id}>
                    <Card.Description>
                      <Divider />
                      <List>
                        {task.runs.map((run, i) => (
                          <List.Item key={run.id}>
                            <List.Content style={{ marginBottom: "10px" }}>
                              {run.result === "Success" && (
                                <List.Header>
                                  Result:{" "}
                                  <span style={{ color: "green" }}>
                                    {run.result}
                                  </span>
                                </List.Header>
                              )}
                              {run.result === "Failed" && (
                                <List.Header>
                                  <Popup
                                    wide
                                    trigger={
                                      <Icon name="info circle" color="grey" />
                                    }
                                  >
                                    <div>
                                      Run ID: {run.id}
                                      <br />
                                      task_started:{" "}
                                      {new Date(
                                        run.task_start_unix_timestamp * 1000
                                      ).toLocaleString()}
                                      <br />
                                      task_stopped:{" "}
                                      {new Date(
                                        run.task_stop_unix_timestamp * 1000
                                      ).toLocaleString()}
                                      <br />
                                      stopped_reason: {run.stopped_reason}
                                      <br />
                                      stop_code: {run.stop_code}
                                      <br />
                                      container_exit_code:{" "}
                                      {run.container_exit_code}
                                      <br />
                                      result: Failed
                                    </div>
                                  </Popup>
                                  Result:{" "}
                                  <span style={{ color: "red" }}>
                                    {run.result}
                                  </span>
                                </List.Header>
                              )}
                              {run.result === "ManuallyStopped" && (
                                <List.Header>
                                  Result:{" "}
                                  <span style={{ color: "grey" }}>
                                    {run.result}
                                  </span>
                                </List.Header>
                              )}
                              <List.Description>
                                <p>
                                  Started:{" "}
                                  {new Date(
                                    run.task_start_unix_timestamp * 1000
                                  ).toLocaleString()}
                                </p>
                                <p>
                                  Stopped:{" "}
                                  {new Date(
                                    run.task_stop_unix_timestamp * 1000
                                  ).toLocaleString()}
                                </p>
                                <p></p>
                              </List.Description>
                            </List.Content>
                            {i < task.runs.length - 1 && <Divider />}
                          </List.Item>
                        ))}
                      </List>
                    </Card.Description>
                  </Accordion.Content>
                </Accordion>
              </Card.Content>
            </Card>
          </Grid.Column>
        ))}
      </Grid>
    </Segment>
  );
}
