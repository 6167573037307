import React, { useEffect, useState } from "react";
import {
  Segment,
  Table,
  TableHeader,
  TableRow,
  TableBody,
  TableCell,
  TableHeaderCell,
  Button,
} from "semantic-ui-react";
import dvdlocator from "../../../apis/dvdlocator";
import { renderDate } from "../../formatting/dateFormat";
import LoadingBox from "../LoadingBox";

const InventoryTable = ({ report }) => {
  const formatTooltip = (displayNum) => {
    if (!displayNum) return null;
    const [total, uniqueUpcs] = displayNum.split("-");
    return `${total} Total Units\n${uniqueUpcs} Unique UPCs`;
  };

  const commaSeparatedFormat = Intl.NumberFormat("en-US");
  const formatCellText = (displayNum) => {
    if (!displayNum) return null;
    const [total, uniqueUpcs] = displayNum.split("-");
    return `${commaSeparatedFormat.format(total)}-${commaSeparatedFormat.format(
      uniqueUpcs
    )}`;
  };

  return (
    <Table padded>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Date</TableHeaderCell>
          <TableHeaderCell title="Total units of sellable inventory.">
            Total Units
          </TableHeaderCell>
          <TableHeaderCell>DVDs</TableHeaderCell>
          <TableHeaderCell>BluRays</TableHeaderCell>
          <TableHeaderCell>4K</TableHeaderCell>
          <TableHeaderCell>Vinyl</TableHeaderCell>
          <TableHeaderCell>CDs</TableHeaderCell>
          <TableHeaderCell>Other</TableHeaderCell>
          <TableHeaderCell>Artcards</TableHeaderCell>
          <TableHeaderCell>Uncategorized</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>{renderDate(report?.timestamp)}</TableCell>
          <TableCell title={formatTooltip(report?.total)}>
            {formatCellText(report?.total)}
          </TableCell>
          <TableCell title={formatTooltip(report?.dvd)}>
            {formatCellText(report?.dvd)}
          </TableCell>
          <TableCell title={formatTooltip(report?.bluray)}>
            {formatCellText(report?.bluray)}
          </TableCell>{" "}
          <TableCell title={formatTooltip(report?.fourk)}>
            {formatCellText(report?.fourk)}
          </TableCell>
          <TableCell title={formatTooltip(report?.vinyl)}>
            {formatCellText(report?.vinyl)}
          </TableCell>
          <TableCell title={formatTooltip(report?.cd)}>
            {formatCellText(report?.cd)}
          </TableCell>
          <TableCell title={formatTooltip(report?.other)}>
            {formatCellText(report?.other)}
          </TableCell>
          <TableCell title={formatTooltip(report?.artcard)}>
            {formatCellText(report?.artcard)}
          </TableCell>
          <TableCell title={formatTooltip(report?.uncategorized)}>
            {formatCellText(report?.uncategorized)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const InventoryOnHand = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({});

  const getInventoryOnHand = () => {
    setLoading(true);
    dvdlocator
      .get("inventory/totals")
      .then((res) => {
        setReport(res.data);
        console.log(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getInventoryOnHand();
  }, []);

  return (
    <Segment className="grid-box">
      <div style={{ padding: "24px 0", display: "flex", alignItems: "center" }}>
        <h2 style={{ margin: "0 24px 0 0" }}>On-hand Inventory</h2>

        <Button
          type="button"
          color="blue"
          icon="sync"
          title="Refresh data."
          onClick={() => getInventoryOnHand()}
        />
      </div>

      {loading ? <LoadingBox /> : <InventoryTable report={report} />}
    </Segment>
  );
};

export default InventoryOnHand;
