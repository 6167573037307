import { FETCH_COLUMN_SETS } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_COLUMN_SETS:
      // use username as a key
      const username = action.payload.username,
        obj = { [username]: action.payload.columns };

      return { ...state, ...obj };
    default:
      return state;
  }
};
