import "./App.css";
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";

import Inventory from "./inventory/Inventory";

import SessionLogReport from "./reports/InventoryReports/SessionLogReport";
import TotalsReport from "./reports/TotalsReports/TotalsReport";
import InventoryWeeklyReport from "./reports/InventoryReports/InventoryWeeklyReport";
import Pricetool from "./database/Pricetool/Pricetool";
import AmazonPricing from "./database/AmazonPricing/AmazonPricing";
import AmazonOrders from "./database/AmazonOrders/AmazonOrders";
import Rebates from "./database/Rebates/Rebates";
import House from "./database/House/House";
import HouseReceived from "./database/House/HouseReceived";
import Damage from "./database/Damage/Damage";

import ColumnSets from "./settings/ColumnSets";
import BarcodeGenerator from "./utilities/BarcodeGenerator";

import Header from "./Header";
import Dashboard from "./Dashboard";
import DamageResolved from "./database/Damage/DamageResolved";
import InventoryOnHand from "./reports/TotalsReports/InventoryTotalReport";
import AnticipatedBillingReport from "./reports/BillingReports/anticipatedBillingReport";
import Notifications from "./notifications/Notifications";
import OvernightTaskDashboard from "./reports/OvernightTasks/OvernightTasksDashboard";
import DailyFbaCsvForm from "./reports/Shipments/FbaDailyCsvUpload/DailyFbaCsvUploader";

const App = () => {
  useEffect(() => {
    if (!process.env.REACT_APP_BACKEND_API_URL)
      return alert(
        "WARNING: No backend URL environment variable provided. Check README."
      );
  }, []);

  return (
    <div>
      <div>
        <Header />

        <Notifications />
        <Route path="/" exact component={Dashboard} />
        <Route
          path={[
            "/datatables/pricetool/family/:family/:colset?",
            "/datatables/pricetool/:search?/:colset?",
          ]}
          exact
          component={Pricetool}
        />
        <Route
          path="/datatables/amazon-pricing/:search?"
          exact
          component={AmazonPricing}
        />
        <Route
          path="/datatables/amazon-orders/:search?"
          exact
          component={AmazonOrders}
        />
        <Route path="/datatables/rebates" exact component={Rebates} />
        <Route path="/datatables/house/:search?" exact component={House} />
        <Route
          path="/datatables/house-received/:search?"
          exact
          component={HouseReceived}
        />
        <Route path="/datatables/damage/:search?" exact component={Damage} />
        <Route
          path="/datatables/damage-resolved/:search?"
          exact
          component={DamageResolved}
        />
        <Route path="/inventory/:title?" exact component={Inventory} />

        <Route
          path="/reports/on-hand-inventory"
          exact
          component={InventoryOnHand}
        />
        <Route
          path="/reports/overnight-tasks"
          exact
          component={OvernightTaskDashboard}
        />
        <Route path="/reports/inventory" exact component={SessionLogReport} />
        <Route
          path="/reports/weekly-inventory"
          exact
          component={InventoryWeeklyReport}
        />
        <Route path="/reports/totals" exact component={TotalsReport} />
        <Route
          path="/reports/anticipated-billing"
          exact
          component={AnticipatedBillingReport}
        />
        <Route
          path="/reports/shipments/daily-fba-upload"
          exact
          component={DailyFbaCsvForm}
        />
        <Route path="/columnsets" exact component={ColumnSets} />
        <Route path="/barcode" exact component={BarcodeGenerator} />
      </div>
    </div>
  );
};

export default withAuthenticator(App);
