export function getStoreLinks(store, row, upc) {
  let walsku = row?.["product"]["walsku"];
  walsku = walsku ? walsku : 0;
  const storeProductPage = {
    amazon: row?.["product"]["asin"]
      ? `https://www.amazon.com/gp/offer-listing/${row?.["product"]["asin"]}/ref=olp_f_freeShipping?ie=UTF8&f_freeShipping=true&f_new=true`
      : `https://www.amazon.com/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=${upc}`,
    amazonca: row?.["product"]["casin"]
      ? `https://www.amazon.ca/gp/offer-listing/${row?.["product"]["casin"]}/ref=olp_f_freeShipping?ie=UTF8&f_new=true`
      : `https://www.amazon.ca/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=${upc}&fap=1`,
    amazonuk: `https://www.amazon.co.uk/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=${upc}`,
    alliance: `https://webami.aent.com/${upc}`,
    barnesandnoble: `https://www.barnesandnoble.com/s/${upc}`,
    bestbuy: `https://www.bestbuy.com/site/searchpage.jsp?st=${upc}&_dyncharset=UTF-8&id=pcat17071`,
    blowitoutahere: `https://www.blowitoutahere.com/${upc}`,
    bookdepo: `https://www.bookdepository.com/a/${upc}`,
    bullmoose: `https://www.bullmoose.com/search?q=${upc}`,
    camel: `https://camelcamelcamel.com/search?sq=${upc}`,
    ccvideo: `https://www.ccvideo.com/${upc}`,
    chapters: `https://www.chapters.indigo.ca/en-ca/home/search/?keywords=${upc}#facetIds=321011`,
    "couch potato": `https://www.couchpotato.com/store/search/?q=${upc}`,
    deepdiscount: `https://www.deepdiscount.com/${upc}`,
    dealsareus: `https://b2b.dealsareus.ca/${upc}`,
    ebayv1: [
      `https://www.ebay.com/sch/i.html?_from=R40&_nkw=${upc}
      &LH_TitleDesc=0&_sac=1&_sop=15&_fsrp=1&LH_All=1&_oac=1&rt=nc&LH_ItemCondition=2`,
      `https://www.ebay.com/sch/i.html?_from=R40&_nkw=${upc}&LH_TitleDesc=0&_sac=1&_sop=15&_fsrp=1&LH_All=1&_oac=1&rt=nc&LH_ItemCondition=3`,
    ],
    ebayblowit: `https://www.ebay.com/sch/i.html?_dkr=1&iconV2Request=true&_ssn=blowitoutahere&store_cat=0&store_name=blowitoutahere&_oac=1&_nkw=${upc}`,
    familyvideo: `https://www.familyvideo.com/catalog/product_info.php?products_id=${upc}`,
    frys: `https://www.frys.com/search?resultpage=0&start=0&rows=25&pType=pDisplay&cat=-68984&inq=${upc}`,
    heartland: `https://www.heartlandmusic.com/${upc}`,
    gruv: `https://www.ebay.com/sch/i.html?_dkr=1&iconV2Request=true&_ssn=gruv-entertainment&store_cat=0&store_name=gruventertainment&_oac=1&_nkw=${upc}`,
    importcds: `https://www.importcds.com/${upc}`,
    importcdsebay: `https://www.ebay.com/sch/i.html?_dkr=1&iconV2Request=true&_ssn=get_importcds&store_cat=0&store_name=getimportcds&_oac=1&_nkw=${upc}`,
    mila: `https://milavideo.com/index.php?fc=module&module=czproductsearch&controller=productsearch&cate=&search_query=${upc}`,
    rarewaves: `https://www.rarewaves.com/pages/search-results?type=product&q=${upc}`,
    rakuten: `https://www.rakuten.com/sr/searchresults#qu=${upc}"&rt=1&page=1`,
    rightstuf: `https://www.rightstufanime.com/search?keywords=${upc}`,
    target: `https://www.target.com/s?searchTerm=${upc}`,
    walmart: `https://www.walmart.com/ip/${walsku}`,
    walmartca: `https://walmart.ca/search/${upc}`,
    wowhd: `https://www.wowhd.us/${upc}`,
  };
  if (!upc) {
    return storeHomePage[store];
  }
  console.log(storeProductPage[store]);
  return storeProductPage[store];
}

const storeHomePage = {
  amazon: "https://www.amazon.com",
  amazonca: "https://www.amazon.ca/",
  amazonuk: "https://www.amazon.co.uk",
  alliance: "https://webami.aent.com/",
  barnesandnoble: "https://www.barnesandnoble.com/",
  bestbuy: "https://www.bestbuy.com/",
  blowitoutahere: "https://blowitoutahere.com",
  bookdepo: "https://www.bookdepository.com/",
  bullmoose: "https://www.bullmoose.com/",
  camel: "https://camelcamelcamel.com/",
  ccvideo: "https://www.ccvideo.com/",
  chapters: "https://www.chapters.indigo.ca/",
  "couch potato": "https://www.couchpotato.com/",
  deepdiscount: "https://www.deepdiscount.com/",
  dealsareus: "https://b2b.dealsareus.ca/",
  ebayv1: "https://www.ebay.com/",
  ebayblowit: "https://stores.ebay.com/Blowitoutahere",
  familyvideo: "https://www.familyvideo.com/",
  frys: "https://www.frys.com/",
  gruv: "https://www.ebay.com/str/gruventertainment",
  heartland: "https:/heartlandmusic.com/",
  importcds: "https://www.importcds.com/",
  importcdsebay: "https://stores.ebay.com/get-importCDs/",
  rarewaves: "https://rarewaves.com/",
  rakuten: "https://www.rakuten.com/",
  rightstuf: "https://www.rightstufanime.com/",
  target: "https://target.com/",
  mila: "https://milavideo.com/",
  walmart: "https://www.walmart.com/",
  walmartca: "https://walmart.ca/",
  wowhd: "https://www.wowhd.us/",
};
