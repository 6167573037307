import React from "react";
import { Message } from "semantic-ui-react";
import "./animation.css";

const BottomRightNotification = ({ icon, text }) => (
  <Message className="fade-in-right">
    <Message.Content>
      {icon && icon} {text}
    </Message.Content>
  </Message>
);

export default BottomRightNotification;
