import React, { useState } from "react";
import { Label, Button, Modal, Divider } from "semantic-ui-react";

const DeleteFamilySelect = ({ families, open, close, remove }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal onClose={close} open={open} size="tiny">
      <Modal.Header>Remove family from all selected products</Modal.Header>
      <Modal.Content>
        <p>
          Select a family to remove from <strong>all selected products</strong>
        </p>
        <Label.Group circular>
          {families.map(({ familyId, familyName }) => (
            <Label
              size="large"
              key={familyId}
              as="a"
              onClick={() => {
                setSelectedId(familyId);
                setSelectedName(familyName);
              }}
              color={selectedId === familyId ? "red" : null}
            >
              {familyName}
            </Label>
          ))}
        </Label.Group>

        <Divider hidden />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={close}>Cancel</Button>
          <Button
            disabled={!selectedId || submitting}
            onClick={() => {
              setSubmitting(true);
              remove(selectedId, selectedName);
            }}
            negative
          >
            Remove from selection
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteFamilySelect;
