import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Table } from "semantic-ui-react";

import SemanticTable from "../utilities/SemanticTable";
import "./SessionLogList.css";
import uniqid from "uniqid";

// uses redux store to generate table based on back end response
export const SessionLogList = ({ inventory, fieldSet }) => {
  // creates semantic ui table rows
  const renderProducts = () => {
    return _.map(inventory.items, (ret) => {
      return <Table.Row key={uniqid()}>{renderFields(ret)}</Table.Row>;
    });
  };

  // creates semantic ui table cells
  // helper function to renderProducts()
  const renderFields = (f) => {
    console.log(f);
    return _.map(fieldSet, (field, i) => {
      const name = field.dataKey.split(" ").join("_");
      return (
        <Table.Cell key={uniqid()}>
          <span dangerouslySetInnerHTML={{ __html: f[name] }} />
        </Table.Cell>
      );
    });
  };

  // creates semantic ui header
  const header = _.map(fieldSet, (field) => {
    return (
      <Table.HeaderCell key={uniqid()}>
        {_.startCase(field.text)}
      </Table.HeaderCell>
    );
  });

  return (
    <SemanticTable header={header} body={renderProducts()} inverted={false} />
  );
};

const mapStateToProps = (state) => {
  return { inventory: state.inventory };
};

export default connect(mapStateToProps, null)(SessionLogList);
