import React from "react";
import LoadingIcon from "../database/LoadingIcon/LoadingIcon";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import { Table } from "semantic-ui-react";
import {
  updateONO,
  deleteProduct,
  addInventory,
  subInventory,
  damageInventory,
  houseInventory,
  markInventory,
  resolveInventory,
  supplierData,
} from "../../redux/actions";
import SemanticTable from "../utilities/SemanticTable";
import "./InventoryForm.css";
import Modal from "react-modal";
import {
  CLEAR_PRODUCT_QUEUE,
  INIT_INVENTORY_REQUEST,
} from "../../redux/actions/types";

Modal.setAppElement("#root");

var uniqid = require("uniqid");

// requires title and fields, rest of props are populated via redux store/action handlers
export const InventoryForm = ({
  title,
  fieldSet,
  products,
  inventory,
  updateONO,
  deleteProduct,
  addInventory,
  subInventory,
  damageInventory,
  houseInventory,
  markInventory,
  resolveInventory,
  supplierData,
}) => {
  const dispatch = useDispatch();

  console.log(products);

  const renderFields = (f) => {
    return _.map(fieldSet, (field) => {
      const fieldKey = field.dataKey;
      if (typeof f[fieldKey] === "undefined") {
        if (field.text === "override") {
          return <Table.Cell key={uniqid()}>false</Table.Cell>;
        }
        return <Table.Cell key={uniqid()}>{f[fieldKey]}</Table.Cell>;
      }
      return (
        <Table.Cell key={uniqid()}>
          <span dangerouslySetInnerHTML={{ __html: f[fieldKey] }} />
        </Table.Cell>
      );
    });
  };

  const renderProducts = () => {
    return _.map(products, (data, index) => {
      if (!data) return null;

      return (
        <Table.Row key={uniqid()}>
          {renderFields(data)}
          <Table.Cell className="collapsing" key={uniqid()}>
            <button
              id={index}
              onClick={onDeleteClick}
              className="red circular ui compact icon button"
            >
              <i id={index} className="trash alternate icon"></i>
            </button>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  // delete from product list
  const onDeleteClick = (event) => {
    deleteProduct(event.target.id);
  };

  // execute correct action handler to query back end
  // returns promises to chain off of for executing
  // post-submission logic
  const callToolSubmission = () => {
    switch (title) {
      case "Add":
        return addInventory();

      case "Sub":
        return subInventory();

      case "House":
        return houseInventory();

      case "Damage":
        return damageInventory();

      case "Mark":
        return markInventory();

      case "Resolve":
        return resolveInventory();

      case "Update ONO":
        return updateONO();

      case "Supplier Data":
        return supplierData();

      default:
        // defaults to Add tool
        return addInventory();
    }
  };

  const onSubmit = () => {
    dispatch({ type: INIT_INVENTORY_REQUEST });
    callToolSubmission()
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch({ type: CLEAR_PRODUCT_QUEUE });
      });
  };

  // generate header row

  const header = _.map(fieldSet, (field) => {
    // Display damageQuantity only for the Retail add tool
    if (inventory.supplierType !== "Retail" && field.text === "damageQuantity")
      return null;

    return (
      <Table.HeaderCell key={uniqid()}>
        {_.startCase(field.text)}
      </Table.HeaderCell>
    );
  });
  return (
    <SemanticTable
      header={header}
      body={renderProducts()}
      footer={
        <Table.HeaderCell colSpan="10">
          <button
            className="blue ui left floated small labeled icon button"
            onClick={() => onSubmit()}
          >
            <i className="database icon"></i>Submit Here
          </button>
          <Modal
            style={{
              overlay: {
                position: "center",
                top: 0,
                left: 0,
                bottom: 0,
              },
              content: {
                top: 400,
                left: 500,
                right: 500,
                bottom: "auto",
                border: "none",
                background: "transparent",
              },
            }}
            isOpen={inventory.loading}
          >
            <div className="loadingIcon">
              <LoadingIcon></LoadingIcon>
            </div>
          </Modal>
        </Table.HeaderCell>
      }
    />
  );
};

const mapStateToProps = (state) => {
  return { products: state.products, inventory: state.inventory };
};

export default connect(mapStateToProps, {
  updateONO,
  deleteProduct,
  addInventory,
  subInventory,
  damageInventory,
  houseInventory,
  markInventory,
  resolveInventory,
  supplierData,
})(InventoryForm);
