import React from "react";
import { useState } from "react";
import dvdlocator from "../../../apis/dvdlocator";
import { Formik, ErrorMessage } from "formik";
import { Form } from "semantic-ui-react";
import { getTitle } from "../titleHandling";
import { sanitizeObjectStrings } from "../../utilities/sanitizeObjectStrings";
import { FileUploader } from "../../utilities/FileUploader";

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

const SupplierDataInput = ({ addProduct }) => {
  const [fileName, setFileName] = useState("");

  const handleFile = (file) => {
    setFileName(file.name);
  };

  const bulkAdd = async (values, actions) => {
    const records = values.bulkAddCsv.split(/\r?\n/);

    const data = {
      UPC: [],
      details: [],
    };
    for (const record of records) {
      const [upc, qty, yomOrderNumber] = record.split(",");
      data.UPC.push(upc.trim());
      data.details.push({
        ...values,
        UPC: upc.trim(),
        quantity: qty.trim(),
        yomOrderNumber: yomOrderNumber.trim(),
      });
    }
    await handleSubmit(data, actions);
  };

  const handleSubmit = async (values, actions) => {
    await dvdlocator
      .post("/product-titles?match=soft", values)
      .then((response) => response.data)
      .then((data) => {
        //for each UPC look up in the data
        for (let i = 0; i < values.UPC.length; i++) {
          const record = {
            UPC: values.UPC[i],
            quantity: values.details[i].quantity,
            yomOrderNumber: values.details[i].yomOrderNumber,
          };

          record.title = "NEEDS TITLE NEW";

          if (values.UPC[i] in data) {
            const title = data[values.UPC[i]];
            if (title) record.title = getTitle(title);
          }

          submitData(record, actions);
        }
      });
  };

  const submitData = (values, actions) => {
    actions.setFieldValue("UPC", "", false);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        supplier: "",
        bulkAddCsv: "",
      }}
      // validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        values = sanitizeObjectStrings(values);
        await bulkAdd(values, actions);
      }}
    >
      {(formikProps) => (
        <Form widths="equal">
          {/* Inputs */}
          <Form.Group>
            {/* End Inputs */}
            {/* Buttons */}
            <Form.Field>
              <ErrorMessage name="bulkAddCsv" component={RedErrorText} />
              <FileUploader handleFile={handleFile} />
              {fileName ? <p>Uploaded file: {fileName}</p> : null}
            </Form.Field>
          </Form.Group>
          {/* End Buttons */}
        </Form>
      )}
    </Formik>
  );
};

export default SupplierDataInput;
