import React from "react";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";

const LoadingIcon = () => {
  return (
    <div>
      <Segment>
        <Dimmer active inverted>
          <Loader indeterminate className="loading" size="medium" active inline>
            Loading
          </Loader>
        </Dimmer>
        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    </div>
  );
};

export default LoadingIcon;
