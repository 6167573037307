import React, { useRef } from "react";
import amazon from "../../../../assets/icons/buybox/amazon.png";
import yom from "../../../../assets/icons/buybox/yom.png";
import fba from "../../../../assets/icons/buybox/fba.png";
import ineligible from "../../../../assets/icons/buybox/ineligible.png";
import merchant from "../../../../assets/icons/buybox/merchant.png";
import noOffers from "../../../../assets/icons/buybox/no_offers.png";
import suppressed from "../../../../assets/icons/buybox/suppressed.png";
import tempOutOfStock from "../../../../assets/icons/buybox/temp_out_of_stock.png";
import used from "../../../../assets/icons/buybox/used.png";
import fast from "../../../../assets/icons/buybox/fast.png";
import medium from "../../../../assets/icons/buybox/medium.png";
import slow from "../../../../assets/icons/buybox/slow.png";
import { Header, List, Popup, Image } from "semantic-ui-react";

const BuyboxInfoLegend = () => {
  const ref = useRef(null);

  const listItemStyle = {
    fontSize: "0.9rem", // Adjust the font size as needed
    display: "flex",
    alignItems: "center",
  };
  const iconStyle = {
    marginRight: "8px",
    maxWidth: "20px",
  };

  return (
    <Popup
      closeOnPortalMouseLeave
      style={{ width: "400px" }}
      flowing
      ref={ref}
      trigger={<p>Buybox Info</p>}
    >
      <Header as="h2" id="buybox-info-legend-tooltip">
        Buybox Info Legend
      </Header>

      <Header as="h3">Seller Statuses:</Header>
      <List bulleted>
        <List.Item style={listItemStyle}>
          <Image src={yom} style={iconStyle} /> YOM
        </List.Item>
        <List.Item style={listItemStyle}>
          <Image src={amazon} style={iconStyle} /> AMAZON
        </List.Item>
        <List.Item style={listItemStyle}>(No icon) NOT YOM</List.Item>
      </List>

      <Header as="h3">Fulfillment:</Header>
      <List bulleted>
        <List.Item style={listItemStyle}>
          <Image src={fba} style={iconStyle} /> FBA
        </List.Item>
        <List.Item style={listItemStyle}>
          <Image src={merchant} style={iconStyle} /> MERCHANT
        </List.Item>
      </List>

      <Header as="h3">FBA Handling Time:</Header>
      <List bulleted>
        <List.Item style={listItemStyle}>
          <Image src={fast} style={iconStyle} /> FAST (1-3 Days)
        </List.Item>
        <List.Item style={listItemStyle}>
          <Image src={medium} style={iconStyle} /> MEDIUM (4-6 Days)
        </List.Item>
        <List.Item style={listItemStyle}>
          <Image src={slow} style={iconStyle} /> SLOW (7-27 Days)
        </List.Item>
        <List.Item style={listItemStyle}>
          <Image src={tempOutOfStock} style={iconStyle} /> TEMP OUT OF STOCK
          (28+ Days OR Out of Stock)
        </List.Item>
      </List>

      <Header as="h3">Other:</Header>
      <List bulleted>
        <List.Item style={listItemStyle}>
          <Image src={ineligible} style={iconStyle} /> INELIGIBLE - (New Offer,
          BuyBox Ineligible)
        </List.Item>
        <List.Item style={listItemStyle}>
          <Image src={suppressed} style={iconStyle} /> SUPPRESSED (Eligible
          Offers but No BuyBox)
        </List.Item>
        <List.Item style={listItemStyle}>
          <Image src={used} style={iconStyle} /> USED (Used Item has BuyBox OR
          Only Used Offers)
        </List.Item>
        <List.Item style={listItemStyle}>
          <Image src={noOffers} style={iconStyle} /> NO OFFERS (No New or Used
          Offers on Page)
        </List.Item>
      </List>
    </Popup>
  );
};

export default BuyboxInfoLegend;
