import uniqid from "uniqid";
import { masterColumnSet } from "../database/Pricetool/ptColumns";
/**
 * Returns fields to use for duplicated row form.
 * Creates a unique filler UPC. Prepends duplicate to title.
 * @param {object} dt A datatables API instance.
 * @returns {object} Key value pairs of empty fields.
 */
export const getCleanedFields = (rowData) => {
  const flattenedRow = flattenObject(rowData);
  const cleanedFields = cleanFields(flattenedRow);

  removeNonEditorFields(cleanedFields);

  // Overwriting with desired dupe-values
  cleanedFields["product.upc"] = `DUPE-${uniqid().slice(0, 14).toUpperCase()}`;
  const title = rowData.product.title;
  cleanedFields["product.title"] = `DUPLICATE - ${title}`;

  return cleanedFields;
};

// removing properties that don't match a defined field in ptColumns
function removeNonEditorFields(fields) {
  Object.keys(fields).forEach((key) => {
    const exists = masterColumnSet.find((col) => col.data === key);

    if (!exists) {
      delete fields[key];
    }
  });
}

function flattenObject(obj, prefix = "") {
  const flattenedObject = {};

  for (const key in obj) {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "object" && value !== null) {
      Object.assign(flattenedObject, flattenObject(value, newKey));
    } else {
      flattenedObject[newKey] = value;
    }
  }

  return flattenedObject;
}
const cleanFields = (rowData) => {
  const cleanedFields = {};
  const persistFields = [
    "product.asin",
    "product.genre",
    "product.ship_weight",
    "product.osid",
    "product.wwo",
    "product.wn",
    "product.shelf",
  ];

  Object.keys(rowData).forEach((column) => {
    // Cleaning any fields that shouldn't persist
    if (!persistFields.includes(column) && !column.includes("yom_sku")) {
      cleanedFields[column] = "";
    }
  });

  return cleanedFields;
};
