// default fields used by session log
const defaultFields = [
  { text: "action", dataKey: "action" },
  { text: "UPC", dataKey: "UPC" },
  { text: "title", dataKey: "title" },
  { text: "shelf", dataKey: "shelf" },
  { text: "count", dataKey: "count" },
  { text: "total", dataKey: "total" },
  { text: "status", dataKey: "status" },
  { text: "submitted", dataKey: "submitted" },
  { text: "error", dataKey: "error" },
];

// Header text to display and data keys for the session log
// for various inventory tools
export const sessionLogFieldSets = {
  Add: [
    { text: "action", dataKey: "action" },
    { text: "UPC", dataKey: "UPC" },
    { text: "title", dataKey: "title" },
    { text: "shelf", dataKey: "shelf" },
    { text: "count", dataKey: "count" },
    { text: "added", dataKey: "added" },
    { text: "ONO", dataKey: "ONO" },
    { text: "status", dataKey: "status" },
    { text: "submitted", dataKey: "submitted" },
    { text: "error", dataKey: "error" },
  ],
  Sub: defaultFields,
  Damage: [
    { text: "action", dataKey: "action" },
    { text: "UPC", dataKey: "UPC" },
    { text: "title", dataKey: "title" },
    { text: "damage shelf", dataKey: "damage shelf" },
    { text: "count", dataKey: "count" },
    { text: "total", dataKey: "total" },
    { text: "status", dataKey: "status" },
    { text: "submitted", dataKey: "submitted" },
    { text: "error", dataKey: "error" },
  ],
  Resolve: defaultFields,
  House: defaultFields,
  Mark: defaultFields,
  "Update ONO": [
    { text: "action", dataKey: "action" },
    { text: "UPC", dataKey: "UPC" },
    { text: "title", dataKey: "title" },
    { text: "shelf", dataKey: "shelf" },
    { text: "count", dataKey: "count" },
    { text: "total", dataKey: "total" },
    { text: "ONO", dataKey: "ONO" },
    { text: "status", dataKey: "status" },
    { text: "submitted", dataKey: "submitted" },
    { text: "error", dataKey: "error" },
  ],
};
