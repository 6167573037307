import React from 'react';
import { Segment, Table } from 'semantic-ui-react';

var uniqid = require('uniqid');

// header is a single row
// body can be multiple rows
// footer is a single row
export const SemanticTable = ({ header, body, footer, color, inverted }) => {
  
  return (
    <Segment padded color={color} inverted={inverted} className="grid-box">
      <Table color={color} inverted={inverted}>
        <Table.Header>
          <Table.Row key={uniqid()}>
            {header}
            <Table.HeaderCell key={uniqid()} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {body}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row key={uniqid()}>
            {footer}
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default SemanticTable;