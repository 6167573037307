import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "./types";

export const addNotification = (notification) => {
  return {
    type: ADD_NOTIFICATION,
    payload: notification,
  };
};

export const removeNotification = (id) => {
  return {
    type: REMOVE_NOTIFICATION,
    payload: id,
  };
};
