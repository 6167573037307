import React from "react";
import { Modal, ModalHeader, Button, Form } from "semantic-ui-react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function AppendForm({ columnNames, closeModal, submitForm }) {
  //if date do not show form
  let showForm = true;
  if (columnNames[0].name.includes("date")) {
    console.log("date");
    showForm = false;
  }
  return (
    <Modal defaultOpen onClose={closeModal}>
      <ModalHeader>Append</ModalHeader>
      <Modal.Content>
        <Modal.Description>
          {inputFields(columnNames, submitForm, showForm)}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

const appendSchema = (schemaObj) => Yup.object().shape(schemaObj);

const inputFields = (columnNames, submitForm, showForm) => {
  let fieldNames = {};
  const schema = {};

  //extract object for field name and schema
  if (showForm) {
    columnNames.forEach((col) => {
      fieldNames["add_to_" + col.name] = "";
      fieldNames["remove_from_" + col.name] = "";

      schema["add_to_" + col.name] =
        col.type === "string"
          ? Yup.string()
          : Yup.number().typeError("A number is required");
      schema["remove_from_" + col.name] =
        col.type === "string"
          ? Yup.string()
          : Yup.number().typeError("A number is required");
    });
  } else {
    fieldNames = columnNames[0].name;
  }

  return (
    <div>
      {showForm ? (
        <Formik
          initialValues={fieldNames}
          validationSchema={appendSchema(schema)}
          onSubmit={(values) => {
            const columnData = getComlumnData(values);
            submitForm(false, columnData);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              {Object.keys(fieldNames).map((col) => {
                return (
                  <Form.Field key={col}>
                    <label htmlFor={col}>{formatLabel(col)}</label>
                    <Field name={col} />
                    <ErrorMessage name={col}>
                      {(msg) => <div className="text-error">{msg}</div>}
                    </ErrorMessage>
                  </Form.Field>
                );
              })}
              <Button primary type="submit">
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <h3>Do you want to overwrite the {formatLabel(fieldNames)}</h3>
          <Button primary onClick={() => submitForm(true)}>
            Yes
          </Button>
        </>
      )}
    </div>
  );
};

const formatLabel = (name) => {
  let words = name.split("_");
  for (let i = 0; i < words.length; i++) {
    if (words[i] === "amz") words[i] = "amazon";
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }

  return words.join(" ");
};

const getComlumnData = (formValues) => {
  let newColumnObj = {};
  Object.keys(formValues).forEach((key) => {
    let words = key.split("_");
    if (words[0] === "add") {
      const col = words.slice(2).join("_");
      newColumnObj[col] = formValues[key];
    } else {
      newColumnObj[key] = formValues[key];
    }
  });
  return newColumnObj;
};
export default AppendForm;
