/**
 * Converts a date into YOM-friendly formatting.
 * @param {Date} date The date to format.
 * @returns {string} formatted date string.
 */
const formatDate = (date) => {
  const dateFormatter = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hourCycle: "h12",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const formattedDate = dateFormatter.format(date);

  return formattedDate.replace(/,/g, "");
};

/**
 * Render function that formats dates for datatable columns.
 * @param {number} timestamp A UNIX timestamp.
 * @returns {string} formatted date string
 */
export const renderDate = (timestamp) => {
  if (!timestamp) return null;
  // unix_timestamp stored in db
  const date = new Date(timestamp * 1000);
  return formatDate(date);
};
