import React, { useState } from "react";
import dvdlocator from "../../../apis/dvdlocator";
import { Formik, ErrorMessage } from "formik";
import { Form, Button, Checkbox, Dropdown } from "semantic-ui-react";
import * as Yup from "yup";
import { sanitizeObjectStrings } from "../../utilities/sanitizeObjectStrings";
import { toStandardCase } from "../../utilities/caseConverters";
import { createHouseRecords, splitBulkHouseInput } from "./bulkParsing";
import { getTitle } from "../titleHandling";

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

const HouseInputs = ({ addProduct }) => {
  const [displaySupplierTextInput, setDisplaySupplierTextInput] =
    useState(false);

  const bulkAdd = async (values) => {
    const inp = prompt("Input House Directive: ");
    if (!inp) return;

    const eolCharacter = inp.includes("\n") ? "\n" : " ";
    const splitValues = splitBulkHouseInput(inp, eolCharacter);

    if (splitValues.length === 0 || splitValues.length % 3 !== 0)
      return alert(
        "Invalid Input. Each row must have a price, upc, and quantity."
      );
    const records = createHouseRecords(splitValues);

    const data = {
      UPC: [],
      details: [],
    };

    for (const record of records) {
      const [price, upc, qty] = record;
      data.UPC.push(upc.trim());
      data["details"].push({
        ...values,
        price: price,
        UPC: upc.trim(),
        amount: qty,
      });
    }

    await handleSubmit(data);
  };

  const validationSchema = Yup.object({
    UPC: Yup.string().required("Please enter a UPC."),
    orderNumber: Yup.string().required("Please enter an order number."),
    orderNumberOther: Yup.string().when("orderNumber", {
      is: "Other",
      then: Yup.string().required("Please enter an order number."),
      otherwise: Yup.string(),
    }),
    supplier: Yup.string().required("Please enter a supplier."),
    supplierOrderNumber: Yup.string().required(
      "Please enter a supplier order number."
    ),
    orderedBy: Yup.string().required("Please enter ordered by."),
    price: Yup.number()
      .moreThan(-1, "Cannot be negative value")
      .required("Please enter a price."),
    amount: Yup.number()
      .moreThan(-1, "Cannot be negative value.")
      .required("Please enter an amount."),
  });
  const handleSubmit = async (values, actions) => {
    var upcTitle = "NEEDS TITLE NEW";
    await dvdlocator
      .post("/product-titles", values)
      .then((response) => response.data)
      .then((data) => {
        if ("details" in values) {
          const records = values.details;
          for (let record = 0; record < records.length; record++) {
            if (records[record].UPC in data) {
              const title = data[records[record].UPC];
              if (title) {
                records[record].title = getTitle(title);
              } else records[record].title = upcTitle;
            } else records[record].title = upcTitle;

            submitData(records[record], actions);
          }
        } else {
          if (values.UPC in data) {
            const title = data[values.UPC];
            if (title) {
              values.title = getTitle(title);
            } else values.title = "";
          } else values.title = upcTitle;

          submitData(values, actions);
        }
      });
  };
  const submitData = (values, actions) => {
    addProduct(values);

    // Exit early when bulk adding
    if (!actions) return;
    actions.setFieldValue("UPC", "", false);
    actions.setSubmitting(false);
  };

  const dropdownOptions = [
    {
      key: "Walmart",
      text: "Walmart",
      value: "Walmart",
    },
    {
      key: "Best Buy",
      text: "Best Buy",
      value: "Best Buy",
    },
    {
      key: "Target",
      text: "Target",
      value: "Target",
    },
    {
      key: "Amazon",
      text: "Amazon",
      value: "Amazon",
    },
    {
      key: "Other",
      text: "Other",
      value: "Other",
    },
  ];

  /**
   * Checks if fields required for bulk add are valid.
   * @param {object} errors Formik error object.
   */
  const bulkAddFieldsAreValid = (errors) => {
    let valid = true;
    const fields = [
      "orderNumber",
      "orderNumberOther",
      "supplier",
      "supplierOrderNumber",
      "orderedBy",
    ];
    fields.forEach((field) => {
      if (errors[field]) valid = false;
    });
    return valid;
  };

  return (
    <Formik
      initialValues={{
        UPC: "",
        orderNumber: "",
        orderNumberOther: "",
        supplier: "",
        supplierOrderNumber: "",
        orderedBy: "",
        override: false,
        price: "",
        amount: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        if (values.orderNumber === "Other") {
          values.orderNumber = toStandardCase(values.orderNumberOther);
        }
        values = sanitizeObjectStrings(values);

        await handleSubmit(values, actions);
      }}
    >
      {(formikProps) => (
        <Form widths="equal" onSubmit={formikProps.handleSubmit}>
          {/* Inputs */}
          <Form.Group>
            <Form.Field>
              <ErrorMessage name="UPC" component={RedErrorText} />
              <label className="ui label">UPC</label>
              <input
                name="UPC"
                placeholder="UPC"
                onChange={formikProps.handleChange}
                value={formikProps.values["UPC"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="orderNumber" component={RedErrorText} />
              <label className="ui label">YOM Order Number</label>
              <Dropdown
                name="orderNumber"
                placeholder="Order Number"
                onChange={(e, data) => {
                  // displaying or hiding text input as needed
                  if (data.value === "Other") setDisplaySupplierTextInput(true);
                  else setDisplaySupplierTextInput(false);

                  formikProps.setFieldTouched("orderNumber", true, false);
                  formikProps.setFieldValue("orderNumber", data.value);
                }}
                value={formikProps.values["orderNumber"]}
                fluid
                selection
                options={dropdownOptions}
              />

              {/* Text Input for other suppliers */}
              <ErrorMessage name="orderNumberOther" component={RedErrorText} />

              {displaySupplierTextInput && (
                <input
                  autoFocus
                  style={{ marginTop: "8px" }}
                  name="orderNumberOther"
                  placeholder="Order Number"
                  onChange={formikProps.handleChange}
                  value={formikProps.values["orderNumberOther"]}
                />
              )}
              {/* End Text Input for other suppliers */}
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="supplier" component={RedErrorText} />
              <label className="ui label">Supplier</label>
              <input
                name="supplier"
                placeholder="Supplier"
                onChange={formikProps.handleChange}
                value={formikProps.values["supplier"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage
                name="supplierOrderNumber"
                component={RedErrorText}
              />
              <label className="ui label">Supplier Order Number</label>
              <input
                name="supplierOrderNumber"
                placeholder="Supplier Order Number"
                onChange={formikProps.handleChange}
                value={formikProps.values["supplierOrderNumber"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="orderedBy" component={RedErrorText} />
              <label className="ui label">Ordered By</label>
              <input
                name="orderedBy"
                placeholder="Ordered By"
                onChange={formikProps.handleChange}
                value={formikProps.values["orderedBy"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="price" component={RedErrorText} />
              <label className="ui label">Price</label>
              <input
                name="price"
                placeholder="Price"
                onChange={formikProps.handleChange}
                value={formikProps.values["price"]}
              />
            </Form.Field>
            <Form.Field>
              <ErrorMessage name="amount" component={RedErrorText} />
              <label className="ui label">Amount</label>
              <input
                name="amount"
                placeholder="Amount"
                onChange={formikProps.handleChange}
                value={formikProps.values["amount"]}
              />
            </Form.Field>
          </Form.Group>
          {/* End Inputs */}

          {/* Override Checkbox */}
          <Form.Group>
            <div style={{ marginLeft: "8px" }}>
              <Checkbox
                checked={formikProps.values["override"]}
                type="checkbox"
                name="override"
                onChange={() =>
                  formikProps.setFieldValue(
                    "override",
                    !formikProps.values["override"]
                  )
                }
                label="override"
              />
            </div>
          </Form.Group>
          {/* End Override Checkbox */}

          {/* Buttons */}
          <Form.Group inline>
            <Button type="submit" inverted color="blue">
              Push to Queue
            </Button>
            <Button
              type="button"
              onClick={() => {
                formikProps.validateForm().then(async (errors) => {
                  let orderNumber = formikProps.values["orderNumber"];
                  let usingOtherOrderNumber = false;

                  // Wiping error object for orderNumber
                  // depending on which version we are validating
                  if (orderNumber === "Other") {
                    usingOtherOrderNumber = true;
                    formikProps.setFieldTouched("orderNumberOther");
                    errors["orderNumber"] = null;
                  } else {
                    formikProps.setFieldTouched("orderNumber");
                    errors["orderNumberOther"] = null;
                  }

                  // Dealing with generic validation logic
                  formikProps.setFieldTouched("supplier");
                  formikProps.setFieldTouched("supplierOrderNumber");
                  formikProps.setFieldTouched("orderedBy");
                  formikProps.setFieldTouched("UPC", false);
                  formikProps.setFieldTouched("price", false);
                  formikProps.setFieldTouched("amount", false);

                  // If we fail any required validation
                  if (!bulkAddFieldsAreValid(errors)) return;

                  let values = sanitizeObjectStrings(formikProps.values);

                  if (usingOtherOrderNumber)
                    values.orderNumber = toStandardCase(
                      values.orderNumberOther
                    );

                  await bulkAdd(values);
                });
              }}
            >
              Bulk Push
            </Button>
          </Form.Group>
          {/* End Buttons */}
        </Form>
      )}
    </Formik>
  );
};

export default HouseInputs;
