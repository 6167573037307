function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const snakeCaseToTitleCase = (str) => {
  return str
    .split("_")
    .map((text) => capitalizeFirstLetter(text))
    .join(" ");
};

/**
 * Capitalizes each word in a string.
 * @param {string} str
 * @returns {string} str
 */
export const toStandardCase = (str) => {
  return str
    .split(" ")
    .map((text) => capitalizeFirstLetter(text))
    .join(" ");
};
