import React from "react";
import AddInputs from "./add/AddInputs";
import DamageInputs from "./DamageInputs";
import HouseInputs from "./HouseInputs";
import MarkInputs from "./MarkInputs";
import ResolveInputs from "./ResolveInputs";
import SubInputs from "./SubInputs";
import UpdateONOInputs from "./UpdateONO";
import SupplierDataInput from "./SupplierData";

const InputGroups = ({ title, addProduct, addProducts }) => {
  switch (title) {
    case "Resolve":
      return <ResolveInputs addProduct={addProduct} />;
    case "Add":
      return <AddInputs addProduct={addProduct} />;
    case "Sub":
      return <SubInputs addProduct={addProduct} />;
    case "Damage":
      return <DamageInputs addProduct={addProduct} />;
    case "House":
      return <HouseInputs addProduct={addProduct} />;
    case "Mark":
      return <MarkInputs addProduct={addProduct} addProducts={addProducts} />;
    case "Update ONO":
      return <UpdateONOInputs addProduct={addProduct} />;
    case "Supplier Data":
      return <SupplierDataInput addProduct={addProduct} />;
    default:
      return null;
  }
};

export default InputGroups;
