export function createQueryParamsFromOptions(searchOptions) {
  let queryParams = "?";

  Object.entries(searchOptions).forEach((entry) => {
    const [key, value] = entry;
    queryParams = queryParams.concat(`${key}=${value},`);
  });

  queryParams = queryParams.substring(0, queryParams.length - 1); // removing last comma
  return queryParams;
}
